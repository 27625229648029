import { ReactElement, PropsWithChildren } from 'react'
import { useToggle } from 'react-use'

import type { ThemeType, TooltipPlacement, Value, ToggleFn } from 'types/common'
import type { TooltipProps } from 'components/common/Tooltip'

import ControllableDropdown from './ControllableDropdown'

export type DropdownOption<T = Value> = {
  key?: string
  value?: T
  label?: string | ReactElement
  disabled?: boolean
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void | Promise<void>
  dataTestid?: string
}

export type GroupedDropdownOption<T = Value> = {
  label: string
  options: DropdownOption<T>[]
}

export type DropdownOptions = DropdownOption[] | GroupedDropdownOption[]

export type CustomDropdownElementProps = {
  isVisible?: boolean
  toggleListVisible: ToggleFn
}

export type DropdownProps = PropsWithChildren<
  Partial<{
    selectedValue: string
    size: number
    className: string
    theme: ThemeType
    icon: string
    placement: TooltipPlacement
    options: DropdownOptions
    trigger: 'click' | 'hover'
    onChange: (v?: Value) => void
    visibilityChangeCallback: (isVisible: boolean) => void
    containerClassName: string
    overlayClassName: string
    toggleComponent: (props: CustomDropdownElementProps) => ReactElement
    /**
     * Use this prop to avoid internal React.cloneElement call inside 'rc-tooltip'.
     * It maybe helpful when you want to use some animations on the target element.
     * https://github.com/react-component/trigger/blob/master/src/TriggerWrapper.tsx#L26-L30
     */
    directlyRenderToggle: boolean
    testId: string
    isDisabled: boolean
    title: string
    iconClassName: string
    align: TooltipProps['align']
    dropdownComponent: (props: CustomDropdownElementProps) => ReactElement
  }>
>

const Dropdown = (props: DropdownProps): ReactElement => {
  const [isVisible, toggleListVisible] = useToggle(false)

  return (
    <ControllableDropdown
      {...props}
      isVisible={isVisible}
      toggleListVisible={toggleListVisible}
    />
  )
}

export default Dropdown
