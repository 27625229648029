import Dexie from 'dexie'
import _ from 'lodash'
import log from 'helpers/log'

/**
 * Get the database object by name
 * @param {String} dbIdentifier database name
 *
 * @return {Object} Dexie instance
 */
export const getDbByName = (dbIdentifier: string): Dexie => {
  return new Dexie(dbIdentifier)
}

/**
 * Delete all data from the given database
 * @param {String} dbIdentifier database name
 */
export const deleteDatabaseByName = async (
  dbIdentifier: string
): Promise<void> => {
  const db = getDbByName(dbIdentifier)
  await db.delete()
}

/**
 * Delete all databases at current host.
 *
 * @return {Array} deleted database names
 */
export const deleteAllDatabaseAtCurrentHost = async (): Promise<string[]> => {
  const names = await Dexie.getDatabaseNames()
  if (_.isEmpty(names)) return []

  const result = await Promise.all(
    names.map(async name => {
      await deleteDatabaseByName(name)
      return name
    })
  )
  log.info('Legacy database cleared')

  return result
}
