import styled from '@emotion/styled'
import { css } from '@emotion/react'

import {
  INPUT_LARGE_DEFAULT_Y_PADDING,
  INPUT_REGULAR_DEFAULT_Y_PADDING,
} from 'constants/styles'

export const PADDING_AFTER_ICONS = 10

export const calculateInputPadding = (
  iconsContainerWidth?: number,
  isLarge?: boolean
): number => {
  const yPadding = isLarge
    ? INPUT_LARGE_DEFAULT_Y_PADDING
    : INPUT_REGULAR_DEFAULT_Y_PADDING

  return iconsContainerWidth
    ? iconsContainerWidth + PADDING_AFTER_ICONS + yPadding
    : yPadding
}

export const StyledInput = styled.input<{
  paddingLeft: number
  paddingRight: number
  isStepper?: boolean
  inline?: boolean
  isLarge?: boolean
}>`
  ${({ isLarge, paddingLeft, paddingRight }) =>
    isLarge
      ? css`
          padding: ${INPUT_LARGE_DEFAULT_Y_PADDING}px ${paddingRight}px
            ${INPUT_LARGE_DEFAULT_Y_PADDING}px ${paddingLeft}px;
          font-size: 16px;
          line-height: 1.3;
        `
      : css`
          padding: ${INPUT_REGULAR_DEFAULT_Y_PADDING}px ${paddingRight}px
            ${INPUT_REGULAR_DEFAULT_Y_PADDING}px ${paddingLeft}px;
          font-size: 13px;
          line-height: 18px;
        `};

  text-align: left;
  margin-right: 0;

  ${({ inline, isLarge }) => {
    if (inline) return `width: ${isLarge ? 75 : 60}px`
    return ''
  }};

  ${({ isStepper }) =>
    isStepper
      ? css`
          border-radius: 0;
          text-align: center;
        `
      : ''};

  /** Hiding arrows */
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`

export const IconsContainer = styled.div<{
  right?: number
  left?: number
  isLarge?: boolean
}>`
  position: absolute;
  top: ${({ isLarge }) => (isLarge ? 16 : 10)}px;
  ${({ right }) => (right ? `right: ${right}px` : '')};
  ${({ left }) => (left ? `left: ${left}px` : '')};
  display: flex;
  align-items: center;
  gap: 10px;
`

export const AddonLabel = styled.span`
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.secondary};
`

export const ReadonlyContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.dark};
`
