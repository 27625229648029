import {
  useConfigStateValue,
  initialBrandingState,
} from 'contexts/ConfigContext'
import type { Branding } from 'types/common'

const useBranding = (): Branding => {
  const { branding } = useConfigStateValue() || {}
  return branding || initialBrandingState
}

export default useBranding
