// libraries
import { useCallback } from 'react'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import to from 'await-to-js'

// utils
import { useFetchList, useProcessList } from 'components/common/List'
import { useCreateEntity } from 'hooks'
import { createFormList, getAllFormLists } from 'services/api/form'
import { showCrudResponseMessage } from 'helpers/message'

import type { Payload, UseListProps, UseListState } from 'types/common'
import type { FormList, FormLists } from 'types/formBuilder'

// constants
import { MESSAGE_STATUS } from 'constants/message'

// components
import { ErrorMessage, Loading } from 'components/common'
import { FormGalleryView } from 'routers/pages/FormGallery/constant'
import { CardActions } from 'components/common/List/Card/CardOptions'

import useItemActions from './useItemActions'
import FormListItem from './FormListItem'
import { PAGE_OPTIONS } from './constant'

const { entityLabel } = PAGE_OPTIONS

const useList = ({
  listConditions,
  filters,
  selectedFormListId,
  setSelectedFormListId,
}: UseListProps<FormList> & {
  selectedView: FormGalleryView
  selectedFormListId?: string
  setSelectedFormListId: (v?: string) => void
}): UseListState<FormList> => {
  const { toggleCreateEntityModal, renderCreateEntityModal } = useCreateEntity()

  const fetchListState = useFetchList({
    listFn: getAllFormLists,
    filters,
  })

  const { list, setList, listState, onChange } = fetchListState

  const { error, loading } = listState

  const processedList = useProcessList({
    list,
    conditions: listConditions,
  })

  const onFormListSubmit = useCallback(
    async (params: Payload) => {
      const formListId = uuidv4()

      // owner not supported yet
      const payload = _.omit(params, 'owner')

      const [err, newFormList] = await to(createFormList(formListId, payload))

      showCrudResponseMessage({
        entity: entityLabel,
        subject: { name: 'New' },
        status: MESSAGE_STATUS.created,
        error: _.isEmpty(newFormList) || err ? err : undefined,
      })

      if (_.isEmpty(newFormList)) return

      setList(
        (oldLists: FormLists): FormLists => [
          newFormList as FormList,
          ...oldLists,
        ]
      )
      setSelectedFormListId(formListId)
    },
    [setList, setSelectedFormListId]
  )

  const itemActions = useItemActions({
    onChange,
    setSelectedFormListId,
  }) as CardActions<FormList>

  const renderContent = () => {
    return (
      <>
        {error ? (
          <ErrorMessage error={error} />
        ) : loading ? (
          <Loading />
        ) : (
          <ul className='p-0'>
            {_.map(processedList, (item: FormList) => {
              return (
                <FormListItem
                  key={item.id}
                  item={item}
                  itemActions={itemActions}
                  isActive={selectedFormListId === item.id}
                  setSelectedFormListId={setSelectedFormListId}
                />
              )
            })}
          </ul>
        )}
        {renderCreateEntityModal({
          entity: entityLabel,
          onSubmit: onFormListSubmit,
          // https://sensorup.atlassian.net/browse/SPR-7572
          // 'description'
          fields: ['name', 'description'],
        })}
      </>
    )
  }

  return {
    ...fetchListState,
    renderContent,
    toggleCreateEntityModal,
    list: processedList,
  }
}

export default useList
