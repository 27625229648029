// libraries
import { ReactElement, useCallback, useMemo, useRef } from 'react'
import styled from '@emotion/styled'
import { useToggle, useClickAway } from 'react-use'
import _ from 'lodash'

// utils
import { displayTime } from 'helpers/utils'
import {
  getSnappedDatetimeStr,
  overrideTimeForGivenDate,
} from 'helpers/datetime'

// constants
import {
  DATE_UNIT_TYPES,
  DEFAULT_DATE_TIME_FORMAT,
  MONTH_DAY_YEAR_FORMAT,
} from 'constants/datetime'
import { DATE_TIME_COMPARISON_EDITOR_PRECISION } from 'constants/workflow'

// components
import { IconButton, VerticalDivider } from 'components/common'
import AbsoluteTimePicker from 'components/common/DateTimePicker/AbsoluteTimePicker'
import { CustomHeaderWrapper } from 'components/common/DateTime/common'
import useTimePicker from 'components/common/DateTime/common/useTimePicker'

// types
import type { UtcISOString } from 'types/datetime'
import { DateTimeType } from '../DateTimePicker'

// styles
import scss from './index.module.scss'

type DatePickerDateTabProps = {
  isActive: boolean
}

const DatePickerDateTab = styled.div<DatePickerDateTabProps>`
  border-bottom: ${props =>
    props.isActive ? `3px solid ${props.theme.primary}` : `none`};
`

const StyledDatePicker = styled.div`
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 2;
  background: #ffffff;
  box-shadow: 0 3px 6px #00000029;
  border: 1px solid #e2e3e4;
  border-radius: 3px;
  opacity: 1;

  .customHeaderActiveTab {
    border-bottom: 3px solid ${props => props.theme.primary};
  }

  .customHeaderNonActiveTab {
    border-bottom: none;
  }
`

export type DateTimeRangeValue = {
  startDate?: UtcISOString
  endDate?: UtcISOString
  type?: DateTimeType
}

type DateRangeProps = {
  startDate: string | undefined
  endDate: string | undefined
  timezone?: string
  isDisabled: boolean
  onChange: ({ startDate, endDate, type }: DateTimeRangeValue) => void
  className?: string
  getMaxDate?: (minDate: UtcISOString) => void
  inputText?: string
  showTimeSelect?: boolean
  selectedTimeType: DateTimeType
  disabledKeyboardNavigation?: boolean
  groupClassName?: string
  isClearable?: boolean
}

export const getDisplayTime = (
  datetime: string,
  timezone?: string,
  defaultToDate = true
): string => {
  return datetime
    ? displayTime({
        datetime,
        timezone,
        addFromNow: false,
        displayLabel: false,
        timeFormat: defaultToDate
          ? MONTH_DAY_YEAR_FORMAT
          : DEFAULT_DATE_TIME_FORMAT,
      })
    : ''
}

const DateRange = ({
  startDate,
  endDate,
  timezone,
  isDisabled = false,
  onChange,
  className = 'position-relative',
  getMaxDate,
  inputText,
  showTimeSelect = false,
  selectedTimeType,
  disabledKeyboardNavigation = false,
  groupClassName,
  isClearable = false,
}: DateRangeProps): ReactElement => {
  const [isPickerVisible, togglePickerVisible] = useToggle(false)

  const handleChangeDateRange = useCallback(
    (
      dateRange: [UtcISOString, UtcISOString],
      type,
      closePicker = true
    ): void => {
      const [start, end] = dateRange

      const newStart = start
        ? getSnappedDatetimeStr({
            baseDatetime: start,
            unit: DATE_UNIT_TYPES.days,
            timezone,
          })
        : null
      const newEnd = end
        ? getSnappedDatetimeStr({
            baseDatetime: end,
            unit: DATE_UNIT_TYPES.days,
            timezone,
            isStart: false,
          })
        : null

      if (closePicker && newStart && newEnd) {
        togglePickerVisible(false)
      }

      onChange({
        startDate: newStart,
        endDate: newEnd,
        type,
      })
    },
    [onChange, timezone, togglePickerVisible]
  )

  const { renderTimePicker, isDate, type } = useTimePicker({
    className: scss.timePicker,
    utcDateTime: startDate,
    selectedTimeType,
    onChange: newStartDate => {
      onChange({
        startDate: newStartDate,
        endDate: endDate
          ? overrideTimeForGivenDate(newStartDate, endDate)
          : undefined,
      })
    },
    isDisabled,
    onClear: () =>
      handleChangeDateRange(
        [startDate, endDate],
        DATE_TIME_COMPARISON_EDITOR_PRECISION.DAY,
        false
      ),
  })

  const [selectedDateRangeHeader, selectedDateRangeInput] = useMemo(() => {
    const start = startDate ? getDisplayTime(startDate, timezone) : undefined
    const startInput = showTimeSelect
      ? startDate
        ? getDisplayTime(startDate, timezone, isDate)
        : undefined
      : start

    const end = endDate ? getDisplayTime(endDate, timezone) : undefined
    const endInput = showTimeSelect
      ? endDate
        ? getDisplayTime(endDate, timezone, isDate)
        : undefined
      : end

    return [
      { start, end },
      { start: startInput, end: endInput },
    ]
  }, [endDate, isDate, showTimeSelect, startDate, timezone])

  const { start: selectedDateRangeStart, end: selectedDateRangeEnd } =
    selectedDateRangeHeader || {}

  const { start: selectedDateRangeStartInput, end: selectedDateRangeEndInput } =
    selectedDateRangeInput || {}

  const displayClearButton =
    isClearable &&
    // If at least 1 date selected
    (!!selectedDateRangeStartInput || !!selectedDateRangeEndInput)

  const dateTimePickerRef = useRef(null)

  useClickAway(dateTimePickerRef, () => {
    togglePickerVisible(false)
  })

  const renderCustomHeader = useCallback(
    ({
      date,
      changeMonth,
      decreaseMonth,
      increaseMonth,
    }: {
      date: Date
      changeMonth: () => void
      decreaseMonth: () => void
      increaseMonth: () => void
    }): ReactElement => {
      return (
        <>
          <div className={scss.header}>
            <div className={scss.tabs}>
              <DatePickerDateTab
                isActive={
                  !!(
                    (selectedDateRangeStart && selectedDateRangeEnd) ||
                    (!selectedDateRangeStart && !selectedDateRangeEnd)
                  )
                }
              >
                {selectedDateRangeStart || `Start Date`}
              </DatePickerDateTab>
              <div className={scss.hr}>-</div>
              <DatePickerDateTab
                isActive={!!(selectedDateRangeStart && !selectedDateRangeEnd)}
              >
                {selectedDateRangeEnd || `End Date`}
              </DatePickerDateTab>
            </div>
          </div>
          <CustomHeaderWrapper
            date={date}
            changeMonth={changeMonth}
            decreaseMonth={decreaseMonth}
            increaseMonth={increaseMonth}
          />
        </>
      )
    },

    [selectedDateRangeEnd, selectedDateRangeStart]
  )

  const displayTimeRange = useMemo(() => {
    return !selectedDateRangeStartInput &&
      !selectedDateRangeEndInput &&
      inputText
      ? `${inputText}`
      : `${selectedDateRangeStartInput || 'Start date'} - ${
          selectedDateRangeEndInput || 'End date'
        }`
  }, [inputText, selectedDateRangeEndInput, selectedDateRangeStartInput])

  const maxDate = useMemo(() => {
    return _.isFunction(getMaxDate) ? getMaxDate(startDate) : undefined
  }, [getMaxDate, startDate])

  const onClear = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      // To prevent a dropdown from opening
      e.stopPropagation()
      onChange({
        startDate: undefined,
        endDate: undefined,
      })
    },
    [onChange]
  )

  return (
    <>
      <div
        className={`d-flex align-items-center ${scss.group} ${groupClassName}`}
        onClick={() => {
          togglePickerVisible(true)
        }}
      >
        {!isClearable && <IconButton icon='RiCalendarTodoLine' size={20} />}
        <div className={`flex-grow-1 ${scss.input}`}>{displayTimeRange}</div>
        {isClearable && (
          <>
            {displayClearButton && (
              <>
                <IconButton icon='MdClose' size={20} onClick={onClear} />
                <VerticalDivider height={30} className='me-1' />
              </>
            )}
            <IconButton icon='RiCalendarTodoLine' size={20} />
          </>
        )}
      </div>

      {isPickerVisible && !isDisabled && (
        <div ref={dateTimePickerRef} className={className}>
          <StyledDatePicker>
            <AbsoluteTimePicker
              renderCustomHeader={renderCustomHeader}
              inline
              timezone={timezone}
              selectsRange
              startDate={selectedDateRangeStart}
              endDate={selectedDateRangeEnd}
              onChange={value => handleChangeDateRange(value, type)}
              {...(maxDate && { maxDate })}
              disabledKeyboardNavigation={disabledKeyboardNavigation}
            />
            {showTimeSelect && renderTimePicker()}
          </StyledDatePicker>
        </div>
      )}
    </>
  )
}

export default DateRange
