import {
  ReactElement,
  useCallback,
  useMemo,
  CSSProperties,
  useState,
} from 'react'
import { components, OptionProps } from 'react-select'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { Dropdown, MultiSelect, Button } from 'components/common'
import { SelectOption } from 'components/common/MultiSelect'
import { TOOLTIP_PLACEMENT } from 'constants/settings'
import type { ColumnOptions } from 'components/common/DataTable/useDataTableColumns'
import * as Icons from 'components/icons'

import {
  BUTTON_VARIANTS,
  BUTTON_ICON_POSITIONS,
} from 'components/common/Button'

import SelectAllDropdownHeader from './SelectAllDropdownHeader'
import scss from './index.module.scss'

const DROPDOWN_ALIGN_CONFIG = {
  offset: [0, -10],
}

const StyledOptionLabel = styled.span<{ isSelected: boolean }>`
  font-weight: ${({ isSelected }) => (isSelected ? '600' : '500')};
`

const CustomOption = (props: OptionProps<SelectOption>) => {
  const theme = useTheme()
  const { getStyles, label, isSelected, innerRef, innerProps } = props
  const Icon = Icons[isSelected ? 'MdCheckBox' : 'MdCheckBoxOutlineBlank']

  return (
    <div
      ref={innerRef}
      style={getStyles('option', props) as CSSProperties}
      className={`d-flex align-items-center `}
      {...innerProps}
    >
      <Icon
        className='me-2'
        size={18}
        color={isSelected ? theme.primary : theme.secondary}
      />
      <StyledOptionLabel isSelected={isSelected}>{label}</StyledOptionLabel>
    </div>
  )
}

const TableColumnToggler = ({
  className,
  columns,
  visibleColumns,
  setVisibleColumns,
}: {
  className?: string
  columns: ColumnOptions[]
  visibleColumns: ColumnOptions[]
  setVisibleColumns: (selectedColumns: ColumnOptions[]) => void
}): ReactElement => {
  const [isVisible, setIsVisible] = useState(false)
  const options = useMemo<SelectOption[]>(
    () =>
      columns.reduce<SelectOption[]>(
        (acc, { field, header, hidden }) =>
          hidden
            ? acc
            : [
                ...acc,
                {
                  value: field as string,
                  label: header as string,
                },
              ],
        []
      ),
    [columns]
  )

  const selectValue = useMemo<string[]>(
    () => visibleColumns.map(({ field }) => field as string),
    [visibleColumns]
  )

  const renderToggle = useCallback(
    () => (
      <Button
        className='text-secondary'
        variant={BUTTON_VARIANTS.link}
        icon='ColumnToggle'
        iconPosition={BUTTON_ICON_POSITIONS.left}
      >
        Columns
      </Button>
    ),
    []
  )

  return (
    <Dropdown
      containerClassName={className}
      placement={TOOLTIP_PLACEMENT.bottom}
      trigger='click'
      options={options}
      align={DROPDOWN_ALIGN_CONFIG}
      toggleComponent={renderToggle}
      visibilityChangeCallback={newIsVisible => setIsVisible(newIsVisible)}
    >
      <MultiSelect
        value={selectValue}
        className={scss.dropdown}
        onChange={selectedValues => {
          // Keep the columns order
          const newVisibleColumns = columns.filter(columnData =>
            (selectedValues as SelectOption[]).some(
              option => option.value === columnData.field
            )
          )
          setVisibleColumns(newVisibleColumns)
        }}
        options={options}
        // Re-render menu on visibility change to reset dropdown state (eg scroll)
        menuIsOpen={isVisible}
        removeControl
        dropdownOnly
        isSearchable={false}
        isClearable={false}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        tabSelectsValue={false}
        components={{
          Menu: SelectAllDropdownHeader as typeof components.Menu,
          Option: CustomOption as typeof components.Option,
        }}
      />
    </Dropdown>
  )
}

export default TableColumnToggler
