// libraries
import keymirror from 'keymirror'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

// utils
import { switchcase } from 'helpers/utils'
import type { ThemeType } from 'types/common'

// scss
import { THEMES } from 'constants/colour'

export const MAP_LAYERS_CONTROL_PLACEMENT = keymirror({
  bottom: null,
  topRight: null,
})

export type MapLayersControlPlacement =
  keyof typeof MAP_LAYERS_CONTROL_PLACEMENT

const getPanelPosition = ({
  placement,
  position,
}: {
  placement: MapLayersControlPlacement
  position: {
    x: number
    y: number
    windowHeight: number
  }
}) => {
  const { x, y, windowHeight } = position
  const shared = { maxHeight: windowHeight - 70 }
  return switchcase({
    [MAP_LAYERS_CONTROL_PLACEMENT.bottom]: {
      ...shared,
      top: y + 40,
      left: x,
    },
    [MAP_LAYERS_CONTROL_PLACEMENT.topRight]: {
      ...shared,
      top: y,
      left: x + 60,
    },
  })({ top: y, left: x })(placement)
}

export const StyledPanel = styled.div<{
  placement: MapLayersControlPlacement
  position: {
    x: number
    y: number
    windowHeight: number
  }
  isLightTheme?: boolean
}>(({ placement, position, isLightTheme }) => ({
  ...getPanelPosition({
    placement,
    position,
  }),
  overflowY: 'scroll',
  color: isLightTheme ? '#141414' : '#fff',
  backgroundColor: isLightTheme ? '#FFFFFF' : '#1B3243',
  zIndex: 10,
}))

export const StyledMapStyleBg = styled.div<{ isLightTheme?: boolean }>`
  background-color: ${({ isLightTheme }) =>
    isLightTheme ? '#FAFAFA' : '#1B3243'};
`

export const PanelTriggerButton = styled.button<{
  mapTheme: ThemeType
  active: boolean
}>(({ mapTheme, theme, active }) => {
  const isLightTheme = mapTheme === THEMES.light
  const iconDefaultColor = isLightTheme ? '#141414' : '#FFFFFF'
  const iconActiveColor = isLightTheme ? theme.primary : '#1791EA'

  return css`
    display: flex;
    padding: 7px 16px;
    border-radius: 14px;
    box-shadow: 0px 4px 16px #08366840;
    background-color: ${isLightTheme ? '#FFFFFF' : '#031D30'};

    svg {
      color: ${active ? iconActiveColor : iconDefaultColor};
    }

    &:hover svg {
      color: ${iconActiveColor};
    }
  `
})

export const StyledDivider = styled.hr<{ isLightTheme?: boolean }>`
  margin: 0;
  color: ${({ isLightTheme }) => (isLightTheme ? '#EAEAEA' : 'transparent')};
  opacity: 1;
`
