// libraries
import _ from 'lodash'

// constants
import {
  DEFAULT_ASSET_LAYER_TYPE,
  DEFAULT_ASSET_PROFILE_VIEW_CONFIGS_ID,
} from 'constants/assets'
import { OPERATION_OPTIONS } from 'constants/common'
import { PROPERTY_VARIABLE_FORMATS } from 'constants/filter'
import { DEFAULT_MAP_LAYER_NAME } from 'constants/map'

// utils
import { getDeckLayerClass } from 'components/map/layers/deckLayers'
import {
  getInvisibleProperties,
  getAllProfileProperties,
} from 'helpers/layerProfile'
import {
  convertArrayToObject,
  capitalizeFirstLetter,
  sanitizeString,
  updateList,
} from 'helpers/utils'

import type {
  AssetProfile,
  ViewConfiguration,
  AssetProfileViewConfigs,
  AssetProfileMapConfig,
} from 'types/asset'
import type { MapLayer, MapLayerData } from 'types/map'
import type {
  Payload,
  InternalPropertiesMetadata,
  PropertyMetadata,
  PropertiesMetadata,
} from 'types/common'
import type { Issue } from 'types/issue'
import { LAYER_VIS_CONFIG_KEYS } from 'components/map/layers/deckLayers/layerFactory'
import type { Timezone } from 'types/datetime'

export const getAssetProfileData = (
  assetsData: Payload,
  profileId: string
): MapLayerData => {
  return _.get(assetsData, [profileId, 'data'], [])
}

export const updateAssetIssuesByProfileId = (
  oldAssetsIssues: Issue[],
  profileId: string,
  newIssue: Issue
): Payload => {
  const oldIssues = _.get(oldAssetsIssues, [profileId], [])
  const newIssues = updateList(
    oldIssues,
    OPERATION_OPTIONS.update,
    newIssue,
    true
  )
  return {
    ...oldAssetsIssues,
    [profileId]: newIssues,
  }
}

const getDefaultAssetViewConfig = (
  viewConfigurations: ViewConfiguration[] | undefined
) => {
  return _.get(
    _.keyBy(viewConfigurations, 'id'),
    DEFAULT_ASSET_PROFILE_VIEW_CONFIGS_ID,
    {}
  ) as ViewConfiguration
}

export const getAssetViewConfigs = (
  viewConfigurations: ViewConfiguration[] | undefined
): {
  profileConfigs: AssetProfileViewConfigs
  mapConfigs: MapLayer
} => {
  const { layouts, map } = getDefaultAssetViewConfig(viewConfigurations)
  const profileConfigs = _.keyBy(layouts, 'mediaType')
  return {
    profileConfigs,
    mapConfigs: map || {},
  }
}

export const generateAssetDeckLayer = ({
  mapLayer,
  layerData,
  profileHandler,
  timezone,
  currentZoom,
}: {
  mapLayer: MapLayer
  layerData: MapLayerData
  profileHandler: () => void
  timezone: Timezone
  currentZoom: number
}): [] => {
  const newLayer = getDeckLayerClass({ mapLayer, currentZoom })
  return newLayer.renderLayer({
    layerData,
    timezone,
    profileHandler,
  })
}

export const getAssetLayer = (
  layer: MapLayer,
  mapConfigs: AssetProfileMapConfig
): MapLayer => {
  const { style, type } = mapConfigs

  const newStyle = style?.[type]
    ? {
        ...layer.style,
        [type]: {
          ...style[type],
          [LAYER_VIS_CONFIG_KEYS.enableClustering]: _.get(
            layer,
            ['style', type, LAYER_VIS_CONFIG_KEYS.enableClustering],
            style[type][LAYER_VIS_CONFIG_KEYS.enableClustering]
          ),
        },
      }
    : layer.style

  return {
    ...layer,
    style: newStyle,
    type: mapConfigs.type || DEFAULT_ASSET_LAYER_TYPE,
  }
}

export const getAssetLayerConfig = (
  layer: MapLayer,
  assetProfiles: AssetProfile[]
): MapLayer => {
  const { profile: { assetProfileId } = {} } = layer

  const viewConfigurations = _.get(
    _.find(assetProfiles, { value: assetProfileId }),
    'viewConfigurations'
  )

  const { mapConfigs } = getAssetViewConfigs(viewConfigurations)

  return getAssetLayer(layer, mapConfigs)
}

export const getAssetProfilePropertiesList = ({
  propertiesMetadata,
  identifier,
  properties = [],
}: {
  propertiesMetadata: PropertiesMetadata
  identifier: string
  properties?: string[]
}): PropertyMetadata[] => {
  const propertyOptionsObj = (convertArrayToObject(
    propertiesMetadata,
    identifier
  ) || {}) as {
    [key: string]: {
      name: string
    }
  }

  const visibleProperties = _(properties)
    .filter(property => !!propertyOptionsObj[property])
    .map(property => ({ ...propertyOptionsObj[property], isVisible: true }))
    .value()

  const invisibleProperties = getInvisibleProperties({
    propertyOptionsObj,
    sortedPickedProperties: properties,
  })

  return getAllProfileProperties(visibleProperties, invisibleProperties)
}

export const getImagesProperties = ({
  propertiesMetadata,
}: {
  propertiesMetadata: PropertiesMetadata
}): PropertiesMetadata =>
  _.filter(
    propertiesMetadata,
    ({ format }) => format === PROPERTY_VARIABLE_FORMATS.image
  )

export const getPropertyDisplayNameFromMetadata = ({
  propertiesMetadataKeyByName,
  propertyName,
}: {
  propertiesMetadataKeyByName: InternalPropertiesMetadata
  propertyName: string
}): string => {
  const { displayName } = _.get(propertiesMetadataKeyByName, propertyName) || {}
  return `${sanitizeString(displayName) || capitalizeFirstLetter(propertyName)}`
}

const getAssetLayerDefaultName = (assetProfileId?: string): string =>
  `${_.upperFirst(assetProfileId)} Asset`

export const getAssetLayerName = ({
  name,
  assetsProfilesOptions,
  assetProfileId,
  assetProfileLabel,
  newAssetProfileId,
}: {
  name: string
  assetsProfilesOptions: (
    | { value: string; label: string }
    | { value: string; label: null }
  )[]
  assetProfileId?: string
  assetProfileLabel: string | null
  newAssetProfileId: string
}): string => {
  return name === DEFAULT_MAP_LAYER_NAME ||
    name === getAssetLayerDefaultName(assetProfileId) ||
    _.find(assetsProfilesOptions, { label: name })
    ? assetProfileLabel || getAssetLayerDefaultName(newAssetProfileId)
    : name
}
