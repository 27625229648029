import { useState, useEffect, useCallback, ReactElement } from 'react'
import _ from 'lodash'

import type { SliderProps } from 'components/common/RangeSlider'

import { useRangeSliderStyle } from 'hooks'
import { NumericInput } from 'components/common'
import TooltipSlider from 'components/common/RangeSlider/TooltipSlider'

export const Range = ({
  rangeValue, // current positions of handles (used for point layer 'radius based on property')
  defaultRangeValue, // default value for range, initial positions of handles. (used for point layer 'radius based on property')
  onChange,
  withInput,
  className,
  isLarge,
  ...rest
}: SliderProps): ReactElement => {
  const styleOverride = useRangeSliderStyle('range', isLarge)

  const [max, setMax] = useState(() =>
    Math.max(rangeValue[1], defaultRangeValue[1])
  )

  const handlerMax = useCallback(
    value => {
      const newRange = [rangeValue[0], value]
      setMax(value)
      onChange(newRange)
    },
    [onChange, rangeValue]
  )

  useEffect(() => {
    const newMax = Math.max(rangeValue[1], max)
    setMax(newMax)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeValue])

  return (
    <div className={`row g-3 ${className}`}>
      <div className={withInput ? 'col-8' : 'col-12'}>
        <TooltipSlider
          {...styleOverride}
          {..._.omit(rest, ['value'])}
          allowCross={false}
          min={defaultRangeValue[0]}
          max={max}
          defaultValue={rangeValue}
          onAfterChange={onChange}
          range
        />
      </div>
      {withInput && (
        <div className='col-4'>
          <NumericInput
            value={max}
            className='form-control rangeSliderInput'
            onChange={handlerMax}
            step={1}
            testId='range-number'
          />
        </div>
      )}
    </div>
  )
}

Range.defaultProps = {
  rangeValue: [],
  defaultRangeValue: [],
  className: '',
  withInput: true,
  markers: {},
}

export default Range
