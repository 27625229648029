// libraries
import { ReactElement } from 'react'
import moment from 'moment-timezone'

// components
import { IconButton } from 'components/common'

import scss from './index.module.scss'

type CustomHeaderWrapperType = {
  date: Date
  changeMonth: (e: MouseEvent) => void
  decreaseMonth: (e: MouseEvent) => void
  increaseMonth: (e: MouseEvent) => void
  prevMonthButtonDisabled: boolean
  nextMonthButtonDisabled: boolean
}

const CustomHeaderWrapper = ({
  date,
  // changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}: CustomHeaderWrapperType): ReactElement => {
  return (
    <div className={scss.headerSection}>
      <div className={scss.headerLabel}>
        <div
          className={`${scss.label}`}
          data-testid='display-datepicker-monthText'
        >
          {moment(date).format('MMMM YYYY')}
        </div>
      </div>
      <div>
        <IconButton
          data-testid='display-datepicker-increaseMonth'
          icon='AiOutlineLeft'
          size={16}
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
          style={{
            padding: 0,
            color: '#222529',
            marginRight: 8,
          }}
        />
        <IconButton
          data-testid='display-datepicker-decreaseMonth'
          icon='AiOutlineRight'
          size={16}
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
          style={{ padding: 0, color: '#222529' }}
        />
      </div>
    </div>
  )
}

export default CustomHeaderWrapper
