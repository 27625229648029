import _ from 'lodash'

import { MUTATION_RESPONSE_ERRORS_FIELDS } from 'constants/graphql'
import {
  getQuery,
  getMutationQuery,
  getArgs,
  getVariables,
  getEdges,
  getPageInfo,
  getConnectionData,
  SuGraphqlError,
  getMutationErrorMessage,
  convertVariablesToArgs,
} from 'helpers/graphql'
import log, { reportErrors } from 'helpers/log'

import type { Payload } from 'types/common'
import type { MutationError, PageInfo } from 'types/graphql'
import type { Entity } from 'types/entity'
import type { QueryParams, Fields, SelectedFields } from 'types/services'

import GraphqlApi from './graphql'

type GetFieldsFn = ({ omitFields, pickFields }: SelectedFields) => Payload

const DEFAULT_IDENTIFIER = 'id'

const DEFAULT_IDENTIFIER_FORMAT = 'ID!'

const getFnName = ({
  queryDomain,
  queryName,
  separator = '.',
}: {
  queryDomain?: string
  queryName?: string | null
  separator?: string
}) => _.compact([queryDomain, queryName]).join(separator)

type QueryProp<T> = {
  queryParams?: QueryParams<T>
} & SelectedFields

type GetQueryFn<T> = ({
  queryParams,
  omitFields,
  pickFields,
}: QueryProp<T>) => Payload

type GetQueryByIdFn = ({ omitFields, pickFields }: SelectedFields) => Payload

export type RelayStyleData<T> = {
  data: T[]
  error?: string
  query?: string
  pageInfo: PageInfo
}

export type ListRelayStyleData<T> = {
  queryDomain: string
  fnName?: string
  onBatch?: (data: T[]) => void
  getQueryFn: GetQueryFn<T>
  enableLoadMore?: boolean
  queryParams?: QueryParams<unknown>
  initPageInfo?: PageInfo
  abortController?: AbortController
  queryDisplayName?: string
} & SelectedFields

export const listRelayStyleData = async <T>({
  queryDomain,
  fnName,
  onBatch,
  getQueryFn,
  enableLoadMore = true,
  queryParams = {},
  initPageInfo,
  abortController,
  omitFields,
  pickFields,
  queryDisplayName,
}: ListRelayStyleData<T>): Promise<RelayStyleData<T>> => {
  let pageInfo = (initPageInfo || {}) as PageInfo
  let response
  let data = [] as T[]
  let error
  let query
  do {
    const { endCursor } = pageInfo

    const queryParamsWithPageInfo = {
      ...queryParams,
      ...(endCursor && { after: endCursor }),
    }

    query = getQuery(
      getQueryFn({
        queryParams: queryParamsWithPageInfo,
        omitFields,
        pickFields,
      }),
      queryDisplayName || fnName
    )

    response = await GraphqlApi.fetch({
      query,
      queryDomain,
      fnName,
      abortController,
      variables: queryParamsWithPageInfo,
    })
    error = error || response.error
    const responseData = _.get(response, fnName) || response
    const batchedData = getConnectionData<T>(responseData)
    if (onBatch) {
      onBatch(batchedData)
    }
    data = _.concat(data, batchedData)
    log.info(`[${queryDomain}]: Received ${data.length} lines of data`)
    pageInfo = responseData.pageInfo || {}
  } while (enableLoadMore && pageInfo.hasNextPage)

  if (pageInfo.hasNextPage && _.isEmpty(data)) {
    log.warn(`[${queryDomain}] The current page has no data`)
  }

  return { data, error, pageInfo, query }
}

export const getResponseData = ({
  response,
  path,
  ignoreError = false,
  ...extra
}: {
  response: { error: string }
  path: string | Fields
  ignoreError?: boolean
}): { errors?: MutationError[] } => {
  const { error } = response
  if (error && !ignoreError) {
    reportErrors(new SuGraphqlError({ error, ...extra }), extra)
    throw new Error(` ${error}`)
  }
  return _.get(response, path)
}

export const deserializeEntityData = (data: Entity): Entity => {
  if (!data) return {} as Entity

  return data
}

const DEFAULT_GET_ENTITY_QUERY_NAME = 'byId'

const DEFAULT_LIST_ENTITIES_QUERY_NAME = 'all'

type GetGraphqlQuery = {
  getFieldsFn: GetFieldsFn
  queryDomain?: string
  queryName?: string | null
  payload?: Payload
  variables?: Payload<string>
}

const getQueryWithName = (
  query: Payload,
  queryName?: GetGraphqlQuery['queryName']
) =>
  queryName
    ? {
        [queryName]: query,
      }
    : query

export const getGraphqlQuery =
  ({
    queryDomain,
    getFieldsFn,
    payload,
    variables,
    queryName = DEFAULT_GET_ENTITY_QUERY_NAME,
  }: GetGraphqlQuery) =>
  ({ omitFields, pickFields }: SelectedFields): Record<string, Payload> => {
    const queryParams = variables ? convertVariablesToArgs(variables) : payload
    const queryWithName = getQueryWithName(
      {
        ...getArgs(queryParams),
        ...getFieldsFn({ omitFields, pickFields }),
      },
      queryName
    )
    return {
      ...getVariables(variables),
      ...getQueryWithName(queryWithName, queryDomain),
    }
  }

export const getEntityQuery =
  ({
    identifier = DEFAULT_IDENTIFIER,
    identifierFormat = DEFAULT_IDENTIFIER_FORMAT,
    ...rest
  }: Omit<GetGraphqlQuery, 'payload'> & {
    identifier?: string
    identifierFormat?: string
  }) =>
  ({ omitFields, pickFields }: Partial<SelectedFields> = {}): Record<
    string,
    Payload
  > => {
    return getGraphqlQuery({
      ...rest,
      variables: identifier ? { [identifier]: identifierFormat } : undefined,
    })({ omitFields, pickFields })
  }

export const getEntitiesQuery =
  <T>({
    queryDomain,
    getFieldsFn,
    queryName = DEFAULT_LIST_ENTITIES_QUERY_NAME,
    enablePaging = true,
  }: Pick<GetGraphqlQuery, 'queryDomain' | 'getFieldsFn' | 'queryName'> & {
    enablePaging?: boolean
  }) =>
  ({ queryParams, omitFields, pickFields }: QueryProp<T>): Payload => {
    const queryWithName = getQueryWithName(
      {
        ...getArgs(queryParams),
        ...getEdges(getFieldsFn({ omitFields, pickFields })),
        ...(enablePaging && getPageInfo()),
      },
      queryName
    )
    return getQueryWithName(queryWithName, queryDomain)
  }

type PostProcessFn<T> = (data: T) => T

export const getGraphql =
  <T>({
    queryDomain,
    getQueryFn,
    omitFields,
    pickFields,
    queryDisplayName,
    postProcessFn,
    fnName,
    queryName,
    ignoreError,
  }: {
    queryDomain: string
    getQueryFn: GetQueryFn<T>
    queryDisplayName?: string
    postProcessFn?: PostProcessFn<T>
    fnName?: string
    queryName?: string | null
    ignoreError?: boolean
  } & SelectedFields) =>
  async (variables?: QueryParams<T>): Promise<T> => {
    const functionName =
      fnName ||
      getFnName({
        queryDomain,
        queryName,
      })

    const query = getQuery(
      getQueryFn({ ...variables, omitFields, pickFields }),
      queryDisplayName || _.camelCase(`get_${functionName}`)
    )
    const response = await GraphqlApi.fetch({
      query,
      queryDomain,
      variables,
      ignoreError,
      fnName: functionName,
    })
    const data = (getResponseData({
      response,
      path: functionName,
      query,
      variables,
    }) || {}) as T
    return postProcessFn ? postProcessFn(data) : data
  }

export const getEntityGraphql =
  <T>({
    queryDomain,
    getQueryFn,
    omitFields,
    pickFields,
    queryDisplayName,
    postProcessFn = deserializeEntityData,
    identifier = DEFAULT_IDENTIFIER,
    queryName = DEFAULT_GET_ENTITY_QUERY_NAME,
  }: {
    queryDomain: string
    getQueryFn: GetQueryByIdFn
    queryDisplayName?: string
    postProcessFn?: PostProcessFn<T>
    queryName?: string | null
    identifier?: string
  } & SelectedFields) =>
  async (id?: string): Promise<T> => {
    return getGraphql({
      queryDomain,
      omitFields,
      pickFields,
      queryDisplayName,
      postProcessFn,
      queryName,
      getQueryFn: () => getQueryFn({ omitFields, pickFields }),
    })({ [identifier]: id })
  }

export const listEntitiesGraphql =
  <T>({
    queryDomain,
    getQueryFn,
    queryDisplayName,
    defaultOmitFields,
    defaultPickFields,
    isSingleEntityPostProcessFn = true,
    postProcessFn = deserializeEntityData,
    queryName = DEFAULT_LIST_ENTITIES_QUERY_NAME,
    enableLoadMore,
    defaultQueryParams,
  }: {
    defaultOmitFields?: Fields
    defaultPickFields?: Fields
    isSingleEntityPostProcessFn?: boolean
    postProcessFn?: PostProcessFn<T>
    queryName?: string | null
    defaultQueryParams?: ListRelayStyleData<T>['queryParams']
  } & ListRelayStyleData<T>) =>
  async ({
    omitFields,
    pickFields,
    queryParams,
    ...rest
  }: Omit<
    ListRelayStyleData<T>,
    'getQueryFn' | 'queryDomain' | 'fnName'
  > = {}): Promise<RelayStyleData<T>> => {
    const fnName = getFnName({ queryDomain, queryName })

    const response = await listRelayStyleData<T>({
      ...rest,
      enableLoadMore,
      queryDomain,
      fnName,
      getQueryFn,
      queryDisplayName,
      pickFields: pickFields || defaultPickFields,
      omitFields: omitFields || defaultOmitFields,
      queryParams: { ...defaultQueryParams, ...queryParams },
    })

    const { query } = response
    const data = (getResponseData({
      response,
      path: 'data',
      fnName,
      query,
    }) || {}) as T[]

    return postProcessFn
      ? {
          ...response,
          data: isSingleEntityPostProcessFn
            ? _.map(data, postProcessFn)
            : postProcessFn(data, response),
        }
      : response
  }

export const getResponseError = ({
  isDelete = false,
  data,
  systemError,
  userError,
  noDataError,
  keepUserErrorMessage = false,
}: {
  isDelete?: boolean
  data?: unknown
  systemError?: string
  userError?: string
  noDataError?: string
  keepUserErrorMessage?: boolean
}): string | undefined => {
  const error = systemError || userError || noDataError
  if (error) {
    log.error('Server error: ', error)
  }
  const overrideErrorMessage =
    !systemError && keepUserErrorMessage && userError
      ? userError
      : 'Something went wrong.'

  if (isDelete) {
    if (error) {
      return overrideErrorMessage
    }
  } else if (error || _.isNil(data)) {
    return overrideErrorMessage
  }
  return undefined
}

export type MutateEntity = {
  queryDomain: string
  fnName: string
  mutationName?: string
  variableFormat?: string
  responseFields?: Payload
  argsKey?: string | null
  identifier?: string
  responsePath?: Fields
  withIdentifier?: boolean
  ignoreError?: boolean
  isDelete?: boolean
  postProcessFn?: ((data: Payload) => Payload) | null
  keepUserErrorMessage?: boolean
}

export const mutateEntity =
  <T = unknown>({
    queryDomain,
    fnName,
    mutationName,
    variableFormat,
    responseFields,
    argsKey = 'input',
    identifier = DEFAULT_IDENTIFIER,
    responsePath = [],
    withIdentifier = true,
    ignoreError = false,
    isDelete = false,
    postProcessFn = deserializeEntityData,
    keepUserErrorMessage = false,
  }: MutateEntity) =>
  async (
    id?: string | null,
    args?: QueryParams
  ): Promise<T | { data: T; error?: string }> => {
    const fields = { ...responseFields, ...MUTATION_RESPONSE_ERRORS_FIELDS }
    const argsPayload = withIdentifier ? { [identifier]: id, ...args } : args
    const query = getMutationQuery({
      fields,
      variableFormat,
      args: argsPayload,
      variableKey: argsKey,
      mutationName: mutationName || fnName,
    })

    const variables = argsKey ? { [argsKey]: argsPayload } : argsPayload

    const response = await GraphqlApi.fetch({
      query,
      queryDomain,
      fnName,
      variables,
      ignoreError,
    })

    const data =
      getResponseData({
        response,
        ignoreError,
        query,
        path: [fnName, ...responsePath],
      }) || {}
    const { error, code } = response

    const { errors } = _.get(response, [fnName])
    const invalidData = _.isNil(data)

    const responseError = getResponseError({
      isDelete,
      data,
      systemError: error,
      userError: getMutationErrorMessage(errors),
      noDataError: invalidData ? 'No data returned' : '',
      keepUserErrorMessage,
    })

    const deserializedData = (postProcessFn ? postProcessFn(data) : data) as T

    if (ignoreError && !invalidData) {
      return { error: responseError, data: deserializedData }
    }

    if (responseError) {
      throw new SuGraphqlError({
        code,
        error: responseError,
      })
    }

    return deserializedData
  }
