// libraries
import { useCallback, useMemo } from 'react'
import _ from 'lodash'

// constants
import { PROPERTY_VARIABLE_TYPES } from 'constants/filter'
import {
  GALLERY_LIST_FILTER_TYPES,
  ENTITY_SHARED_STATUS,
  ENTITY_ACTIVE_STATUS,
} from 'constants/common'
import { USER_PREFERENCES } from 'constants/settings'

// utils
import { useAuthStateValue } from 'contexts'
import { useCurrentUser } from 'hooks'
import { getUserOption } from 'helpers/user'
import { getUserOptionLabel } from 'components/common/UsersPicker'
import { getCommonFilterSpecs } from 'helpers/filter'

import type { Payload } from 'types/common'
import type { User } from 'types/user'
import type { Filters, FilterSpec, OnFiltersChange } from 'types/filter'

const getCurrentGroupUserListOptions =
  ({ usersList, userGroups }: { usersList: User[]; userGroups: string[] }) =>
  ({ filters }: { filters: Record<string, string[]> }) => {
    const selectedGroups = filters[GALLERY_LIST_FILTER_TYPES.group]
    const validGroups = _.isEmpty(selectedGroups)
      ? userGroups
      : selectedGroups.filter(group => _.includes(userGroups, group))
    return _(usersList)
      .filter(({ group }) => _.includes(validGroups, group))
      .map(getUserOption)
      .sortBy(['label'])
      .value()
  }

const onGroupPostChange =
  (usersList: User[]) =>
  ({
    key,
    selectedValues,
    filters,
  }: {
    key: string
    selectedValues: string[]
    filters: Payload<string[]>
  }) => {
    const usersFilterKey = GALLERY_LIST_FILTER_TYPES.username
    if (!_.has(filters, usersFilterKey)) {
      return { ...filters, [key]: selectedValues }
    }

    const currentSelectedUsers = filters[usersFilterKey]
    let newSelectedUsers = currentSelectedUsers

    if (!_.isEmpty(selectedValues)) {
      const usersKeyByUsername = _.keyBy(usersList, 'username')
      newSelectedUsers = _.filter(currentSelectedUsers, username =>
        _.includes(selectedValues, usersKeyByUsername[username]?.group)
      )
    }

    return {
      ...filters,
      [key]: selectedValues,
      [usersFilterKey]: newSelectedUsers,
    }
  }

export type UsePageFiltersBuilderProps = {
  entity: string
  setFilterValues?: React.Dispatch<Filters>
  customizedFiltersKeys?: string[]
  customFiltersSpecs?: FilterSpec[]
  filterSpecsOverride?: Record<string, unknown>
}

const usePageFiltersBuilder = ({
  entity,
  setFilterValues,
  customizedFiltersKeys,
  customFiltersSpecs,
  filterSpecsOverride,
}: UsePageFiltersBuilderProps): {
  onFiltersChange: OnFiltersChange
  filtersSpecs: FilterSpec[]
} => {
  const { currentUser, userGroups, userGroupsOptions, usersList } =
    useAuthStateValue()
  const { filters = {} } = currentUser.preferences
  const { updateCurrentUserPreference } = useCurrentUser()

  const onFiltersChange = useCallback(
    async (newFilterValues: Payload<string[]>) => {
      if (setFilterValues) setFilterValues(newFilterValues)
      const payload = {
        preference: USER_PREFERENCES.filters,
        value: {
          ...filters,
          [entity]: newFilterValues,
        },
      }
      updateCurrentUserPreference(payload, USER_PREFERENCES.filters)
    },
    [setFilterValues, filters, entity, updateCurrentUserPreference]
  )

  const filtersSpecs = useMemo(
    () =>
      _.map(
        {
          [GALLERY_LIST_FILTER_TYPES.group]: {
            options: userGroupsOptions,
            onPostChange: onGroupPostChange(usersList),
            icon: 'MdGroup',
          },
          [GALLERY_LIST_FILTER_TYPES.username]: {
            label: 'author',
            getOptions: getCurrentGroupUserListOptions({
              usersList,
              userGroups,
            }),
            formatOptionLabel: getUserOptionLabel(),
            icon: 'HiOutlineUserCircle',
          },
          [GALLERY_LIST_FILTER_TYPES.isPrivate]: {
            label: 'status',
            options: [
              { value: true, label: ENTITY_SHARED_STATUS.private },
              { value: false, label: ENTITY_SHARED_STATUS.shared },
            ],
            icon: 'MdLock',
          },
          [GALLERY_LIST_FILTER_TYPES.active]: {
            label: 'status',
            options: [
              { value: true, label: ENTITY_ACTIVE_STATUS.active },
              { value: false, label: ENTITY_ACTIVE_STATUS.inactive },
            ],
            icon: 'MdLock',
          },
          [GALLERY_LIST_FILTER_TYPES.isFavorite]: {
            label: 'favorite',
            size: 18,
            type: PROPERTY_VARIABLE_TYPES.boolean,
            icon: 'MdStar',
          },
        },
        (specs, key) =>
          getCommonFilterSpecs(key, {
            ...specs,
            ..._.get(filterSpecsOverride, key),
          })
      ),
    [filterSpecsOverride, userGroups, userGroupsOptions, usersList]
  )

  const pickedFiltersSpecs = useMemo(
    () =>
      customFiltersSpecs ||
      _.filter(filtersSpecs as FilterSpec[], ({ key }) =>
        _.includes(customizedFiltersKeys, key)
      ),
    [customFiltersSpecs, customizedFiltersKeys, filtersSpecs]
  )

  return {
    onFiltersChange,
    filtersSpecs: pickedFiltersSpecs,
  }
}

export default usePageFiltersBuilder
