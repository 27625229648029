// libraries
import { ReactElement } from 'react'

// constants
import { PAGE_HEADER_TAB_POSITION } from 'constants/common'
import { FORM_GALLERY_TABS } from 'routers/pages/FormGallery/constant'

// utils
import { useListConditions } from 'hooks'
import type { FormGalleryPassProps } from 'routers/pages/FormGallery'

// components
import { GalleryPageHeader } from 'components/common'
import useList from './useList'
import { PAGE_OPTIONS } from './constant'

const { customFiltersSpecs, pageName, entity, entityLabel } = PAGE_OPTIONS

const Forms = ({
  selectedView,
  setSelectedView,
}: FormGalleryPassProps): ReactElement => {
  const [listConditions, setListConditions] = useListConditions({
    defaultListConditions: PAGE_OPTIONS.listConditions,
    entity,
  })

  const {
    list,
    listState,
    renderContent,
    toggleCreateEntityModal,
    columns,
    visibleColumns,
    setVisibleColumns,
  } = useList({
    listConditions,
    setListConditions,
    selectedView,
  })

  return (
    <>
      <GalleryPageHeader
        entity={entity}
        pageName={pageName}
        customFiltersSpecs={customFiltersSpecs}
        counts={list?.length}
        isLoading={listState.loading}
        actions={[
          {
            label: `New ${entityLabel}`,
            value: `create-new-&{entityLabel}`,
            onClick: () => toggleCreateEntityModal(true),
          },
        ]}
        toolboxOptions={PAGE_OPTIONS.toolboxOptions}
        conditions={listConditions}
        onConditionsChange={setListConditions}
        columns={columns}
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
        tabsProps={{
          tabs: FORM_GALLERY_TABS,
          tabsPosition: PAGE_HEADER_TAB_POSITION.left,
          onTabSelected: setSelectedView,
          selectedTab: selectedView,
        }}
      />
      <div className='galleryContainer'>{renderContent()}</div>
    </>
  )
}

export default Forms
