import { ReactElement } from 'react'
import { WidgetProps } from '@rjsf/utils'

const DateTimeWidget = ({
  value,
  registry,
  onChange,
  uiSchema,
}: WidgetProps): ReactElement => {
  return <></>
}

export default DateTimeWidget
