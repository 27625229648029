import keymirror from 'keymirror'
import { PROPERTY_VARIABLE_TYPES } from 'constants/filter'
import * as echarts from 'echarts/core'

export const NONE_WIDGET_DATA_AFFECTING_PROPS = [
  'colour',
  'overridePropertyTitle',
]

export const WIDGET_TYPES = keymirror({
  list: null,
  line: null,
  bar: null,
  numeric: null,
  gauge: null,
  scatter: null,
  table: null,
  text: null,
  image: null,
})

export const DOWNLOADABLE_WIDGETS_TYPES = [
  WIDGET_TYPES.line,
  WIDGET_TYPES.bar,
  WIDGET_TYPES.scatter,
  WIDGET_TYPES.gauge,
]

export const NOT_MERGE_WIDGET_TYPES = [WIDGET_TYPES.gauge, WIDGET_TYPES.numeric]

// https://www.echartsjs.com/en/option.html#xAxis.type
export const ECHARTS_AXIS_TYPE = keymirror({
  value: null,
  category: null,
  time: null,
})

export const WIDGET_TABS = [
  { key: WIDGET_TYPES.line, componentName: 'LineWidgetTab' },
  { key: WIDGET_TYPES.bar, componentName: 'LineWidgetTab' },
  {
    key: WIDGET_TYPES.scatter,
    componentName: 'ScatterWidgetTab',
    mustContain: PROPERTY_VARIABLE_TYPES.number,
  },
  { key: WIDGET_TYPES.numeric, componentName: 'NumericWidgetTab' },
  { key: WIDGET_TYPES.gauge, componentName: 'GaugeWidgetTab' },
]

export const DEFAULT_HIDE_VALUES_OUTSIDE_OF_VALUE_RANGE = false

export const DEFAULT_WIDGET_COLOUR = [21, 146, 230, 255]

export const DEFAULT_REALTIME_RANGE = 5

export const DEFAULT_WIDGET_SCATTER_SYMBOL = 5

export const DEFAULT_WIDGET_SCATTER_SYMBOL_RANGE = [1, 20]

export const DEFAULT_WIDGET_COLOUR_PALETTE = [
  '#dd6b66',
  '#759aa0',
  '#e69d87',
  '#8dc1a9',
  '#ea7e53',
  '#eedd78',
  '#73a373',
  '#73b9bc',
  '#7289ab',
  '#91ca8c',
  '#f49f42',
]

export const DEFAULT_CHART_PRIMARY_COLOUR = '#1791EA'

export const DEFAULT_CHART_Y_AXIS_RANGE = [0, 100] // yAxis

export const DEFAULT_WIDGET_NAME = 'Untitled Widget'

export const DEFAULT_WIDGET_SIZE_SMALL = { height: '116px', width: '100%' }

export const DEFAULT_WIDGET_SIZE_LARGE = { height: '100%', width: '100%' }

export const BAR_CHART_TYPES = keymirror({
  features: null,
  group: null,
})

export const LINE_CHART_TYPES = keymirror({
  group: null,
  features: null,
})

export const DEFAULT_CHART_REFRESH_RATE_MS = 100

export const DEFAULT_CHART_GAUGE_TITLE_SIZE = 20

export const DEFAULT_CHART_EXPANDED_GAUGE_TITLE_SIZE = 44

export const DEFAULT_CHART_GAUGE_SUBTITLE_SIZE = 12

export const DEFAULT_CHART_EXPANDED_GAUGE_SUBTITLE_SIZE = 18

export const DEFAULT_CHART_GAUGE_WIDTH = 28

export const DEFAULT_CHART_EXPANDED_GAUGE_WIDTH = 60

export const DEFAULT_CHART_GAUGE_AXIS_LABEL_SIZE = 12

export const DEFAULT_CHART_EXPANDED_GAUGE_AXIS_LABEL_SIZE = 18

export const DEFAULT_DARK_THEME_CHART_SECONDARY_DARK_COLOUR =
  'rgba(255, 255, 255, 0.2)'

export const DEFAULT_LIGHT_THEME_CHART_SECONDARY_DARK_COLOUR =
  'rgba(0, 0, 0, 0.2)'

export const DEFAULT_DARK_THEME_CHART_SECONDARY_COLOUR =
  'rgba(255, 255, 255, 0.05)'

export const DEFAULT_LIGHT_THEME_CHART_SECONDARY_COLOUR = 'rgba(0, 0, 0, 0.05)'

export const DEFAULT_DARK_THEME_CHART_TEXT_COLOUR = 'rgba(255, 255, 255, 0.3)'

export const DEFAULT_LIGHT_THEME_CHART_TEXT_COLOUR = 'rgba(0, 0, 0, 0.3)'

export const DEFAULT_MIN_CHART_GRID_OPTIONS = {
  top: '10',
  left: '10',
  right: '15',
  bottom: '10',
  containLabel: true,
}

export const DEFAULT_MAX_CHART_GRID_OPTIONS = {
  top: '60',
  left: '10',
  right: '20',
  bottom: '15',
  containLabel: true,
}

export const DEFAULT_CHART_DATA_ZOOM = [
  {
    show: true,
    height: 12,
    xAxisIndex: [0],
    bottom: '8%',
    start: 10,
    end: 90,
    handleIcon:
      'path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z',
    handleSize: '110%',
    handleStyle: {
      color: '#d3dee5',
    },
    textStyle: {
      color: '#fff',
    },
    borderColor: '#90979c',
  },
  {
    type: 'inside',
    show: true,
    height: 15,
    start: 1,
    end: 35,
  },
]

export const DEFAULT_CHART_TOOLBOX_OPTIONS = {
  top: 10,
  right: 30,
  show: true,
  feature: {
    saveAsImage: {
      title: 'export',
      name: 'su-explorer-widget',
      backgroundColor: 'rgba(5, 27, 50, 0.9)',
      icon: 'path://M8 16.5v.5c1.691-2.578 3.6-3.953 6-4v3c0 .551.511 1 1.143 1 .364 0 .675-.158.883-.391 1.933-2.029 5.974-6.109 5.974-6.109s-4.041-4.082-5.975-6.137c-.208-.205-.518-.363-.882-.363-.632 0-1.143.447-1.143 1v3c-4.66 0-6 4.871-6 8.5zM5 21h14c.553 0 1-.448 1-1v-6.046c-.664.676-1.364 1.393-2 2.047v2.999h-12v-12h7v-2h-8c-.553 0-1 .448-1 1v14c0 .552.447 1 1 1z',
      iconStyle: {
        color: '#fff',
      },
      emphasis: {
        iconStyle: {
          color: '#fff',
          opacity: 0.8,
        },
      },
    },
  },
}

export const DEFAULT_LINE_CHART_AREA_STYLE = {
  // Top to bottom gradient. Darkest at the top. Completely transparent at the bottom.
  color: new echarts.graphic.LinearGradient(
    0,
    0,
    0,
    1,
    [
      {
        offset: 0,
        color: DEFAULT_DARK_THEME_CHART_SECONDARY_DARK_COLOUR,
      },
      {
        offset: 1,
        color: 'rgba(255,255,255,0)',
      },
    ],
    false
  ),
}

export const DEFAULT_CHART_TOOLTIP_OPTIONS = {
  backgroundColor: '#051B32',
  borderColor: '#fff',
  textStyle: {
    fontSize: 13,
    color: '#fff',
  },
}

export const DEFAULT_MAX_BARS_NUM = 50
