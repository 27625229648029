import styled from '@emotion/styled'
import { css } from '@emotion/react'
import _ from 'lodash'

// constants
import { BLADE_SIZES, BLADE_POSITION, BLADE_VARIANT } from 'constants/common'

// types
import type { BladeSize, BladePosition, BladeVariant } from 'types/common'

// utils
import { switchcase } from 'helpers/utils'

const OVERLAY_Z_INDEX = 1040

const getBladeWidth = (size: BladeSize): string =>
  switchcase({
    [BLADE_SIZES.small]: '260px',
    [BLADE_SIZES.default]: '320px',
    [BLADE_SIZES.medium]: '400px',
    [BLADE_SIZES.large]: '500px',
    [BLADE_SIZES.modal]: '90vw',
  })('320px')(size)

const pickBorderRadius = ({
  variant,
  position,
}: {
  variant: BladeVariant
  position: BladePosition
}): string => {
  switch (variant) {
    // Do not display rounded corners from the docked side
    case BLADE_VARIANT.docked: {
      switch (position) {
        case BLADE_POSITION.left:
          return '0 10px 10px 0'
        case BLADE_POSITION.right:
          return '10px 0 0 10px'
        default:
          return '10px'
      }
    }
    // In the floating blade all corners will be rounded
    case BLADE_VARIANT.floating:
    default:
      return '10px'
  }
}

const getVariantStyles = ({
  variant,
  position,
  customHeight,
  customMargin = '0',
}: {
  variant: BladeVariant
  position: BladePosition
  customHeight?: string
  customMargin?: string
}) => {
  const panelMargin = 16
  const staticStyle = css`
    height: ${customHeight || '100%'};
    margin: ${customMargin};
  `

  switch (variant) {
    case BLADE_VARIANT.docked:
      return staticStyle

    case BLADE_VARIANT.floating:
    default:
      return customHeight
        ? staticStyle
        : // By default the floating blade is positioned absolutely
          css`
            position: absolute;
            ${position}: ${panelMargin}px;
            top: ${panelMargin}px;
            bottom: ${panelMargin}px;
          `
  }
}

export const StyledBlade = styled.div<{
  size: BladeSize
  position: BladePosition
  variant: BladeVariant
  customHeight?: string
  customMargin?: string
  zIndex?: number
}>`
  width: ${({ size }) => getBladeWidth(size)};
  border-radius: ${({ variant, position }) =>
    pickBorderRadius({ variant, position })};

  ${({ size }) =>
    size === BLADE_SIZES.modal
      ? css`
          min-width: 400px;
          /* Panel z-index should be greater than overlay's */
          z-index: ${OVERLAY_Z_INDEX + 10};
        `
      : ''}

  ${({ zIndex }) => (_.isNumber(zIndex) ? `z-index: ${zIndex};` : '')}

  ${({ variant, position, customHeight, customMargin }) =>
    getVariantStyles({ variant, position, customHeight, customMargin })}
`

export const StyledBladeOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #031d30;
  opacity: 0.73;
  z-index: ${OVERLAY_Z_INDEX};
`
