import _ from 'lodash'
import type { Point } from 'geojson'

// constants
import { SITE_FEATURE_TYPES, SITE_FEATURE_CHILDREN_TYPES } from 'constants/site'

import type { Payload, Option, Dictionary } from 'types/common'
import type {
  Site,
  SiteFormValues,
  SiteUserPropertiesArray,
  SiteFeatureGeoJSON,
  SiteFeature,
  SiteFeatureType,
} from 'types/sites'
import { BACKEND_ENTITY_TYPES } from 'constants/common'

const getTitle = (node: SiteFeatureGeoJSON): string => {
  return node?.properties?.name
}

const getPluralSiteType = (type: string): string => `${type}s`

const getNodeFields = (
  node: SiteFeatureGeoJSON,
  fields = ['id', 'properties', 'featureType', 'geometry', 'type']
): Partial<SiteFeatureGeoJSON> => {
  return _.pick(node, fields)
}

const getTreeNode = ({
  node,
  children,
  expanded,
  fields,
}: {
  node: SiteFeatureGeoJSON
  children: SiteFeatureGeoJSON[]
  expanded: boolean
  fields: string[]
}) => {
  return {
    ...(expanded ? { expanded } : {}),
    ...getNodeFields(node, fields),
    title: getTitle(node),
    children,
  }
}

const getChildrenNode =
  ({
    getChildrenTree,
    childrenType,
    expanded,
    fields,
  }: {
    childrenType: SiteFeatureType
    expanded: boolean
    fields: string[]
  }) =>
  ({ node }: { node: SiteFeatureGeoJSON }) => {
    const childrenTypes = SITE_FEATURE_CHILDREN_TYPES[childrenType]
    const children = _.flatMap(childrenTypes, featureType => {
      return getChildrenTree(node, featureType, expanded, fields)
    })
    return getTreeNode({ node, children, expanded, fields })
  }

const getEdges = (feature: SiteFeatureGeoJSON, childrenType: SiteFeatureType) =>
  _.compact(_.get(feature, [getPluralSiteType(childrenType), 'edges']))

const getChildrenTree = (
  feature: SiteFeatureGeoJSON,
  childrenType: SiteFeatureType,
  expanded: boolean,
  fields: string[]
) => {
  const edges = getEdges(feature, childrenType)
  return _.map(
    edges,
    getChildrenNode({ getChildrenTree, childrenType, expanded, fields })
  )
}

export const getSiteDataToTreeData = ({
  site,
  expanded,
  fields,
}: {
  site: SiteFeature
  expanded: boolean
  fields: string[]
}): [] => {
  const siteTree = getChildrenNode({
    getChildrenTree,
    childrenType: SITE_FEATURE_TYPES.site,
    expanded,
    fields,
  })({ node: site })
  return [siteTree]
}

export const getFeaturesFromSite = (site: Site): SiteFeatureGeoJSON[] => {
  const getChildrenFeature =
    ({
      getChildrenFeatures,
      childrenType,
    }: {
      childrenType: SiteFeatureType
    }) =>
    ({ node }: { node: SiteFeatureGeoJSON }): SiteFeature[] => {
      const childrenTypes = SITE_FEATURE_CHILDREN_TYPES[childrenType]
      const children = _.flatMap(childrenTypes, featureType => {
        return getChildrenFeatures(node, featureType)
      })
      return [getNodeFields(node), ..._.flatten(children)]
    }

  const getChildrenFeatures = (
    feature: SiteFeatureGeoJSON,
    childrenType: SiteFeatureType
  ): SiteFeatureGeoJSON[] => {
    const edges = getEdges(feature, childrenType)
    return _.map(
      edges,
      getChildrenFeature({ getChildrenFeatures, childrenType })
    )
  }

  return getChildrenFeature({
    getChildrenFeatures,
    childrenType: SITE_FEATURE_TYPES.site,
  })({ node: site })
}

export const canDropSiteNode = ({
  node,
  nextParent,
}: {
  node: SiteFeature
  nextParent: SiteFeature
}): boolean => {
  if (!nextParent) return false

  const { featureType: currentFeatureType } = node
  const { featureType: nextParentFeatureType } = nextParent
  const childrenTypes = SITE_FEATURE_CHILDREN_TYPES[nextParentFeatureType]
  return _.includes(childrenTypes, currentFeatureType)
}

export const userPropertiesArrayToObject = (
  userProperties: SiteUserPropertiesArray
): Payload<string> => {
  return _.reduce(
    _.compact(userProperties),
    (result, { key, value }) => {
      return { ...result, [key]: value || '' }
    },
    {}
  )
}

export const userPropertiesObjectToArray = (
  userProperties: SiteFormValues['userProperties']
): { key: number; value: Option<string> }[] =>
  _(userProperties)
    .map((value, key) => {
      return { key, value }
    })
    .sortBy()
    .value()

export const transformUserProperties = (values: {
  userProperties: SiteUserPropertiesArray
}): Payload<string> => {
  return userPropertiesArrayToObject(values.userProperties)
}

export const isSiteFeature =
  (type?: SiteFeatureType) =>
  (featureType?: string): boolean =>
    !!(type && featureType === SITE_FEATURE_TYPES[type])

export const isSite = isSiteFeature(SITE_FEATURE_TYPES.site)

export const isSiteLocation = isSiteFeature(SITE_FEATURE_TYPES.location)

export const getChildrenFeatures = (
  parentId: string,
  groupedFeatures: Dictionary<SiteFeatureGeoJSON[]>
): SiteFeatureGeoJSON[] => {
  const children = groupedFeatures[parentId]
  return _.reduce(
    children,
    (acc, feature) => {
      const result = getChildrenFeatures(feature.id as string, groupedFeatures)
      return [...acc, ...result]
    },
    children || []
  )
}

export const groupeFeaturesByParentId = (
  features: SiteFeatureGeoJSON[]
): Dictionary<SiteFeatureGeoJSON[]> =>
  _.groupBy(features, 'properties.parentId')

export const deleteFeatureAndChildren = (
  id: string,
  features: SiteFeatureGeoJSON[]
): SiteFeatureGeoJSON[] => {
  if (!id) return features

  const childrenFeatures = getChildrenFeatures(
    id,
    groupeFeaturesByParentId(features)
  )
  const featureIdsToBeDelete = [..._.map(childrenFeatures, 'id'), id]
  return _.reject(features, feature =>
    featureIdsToBeDelete.includes(feature.id)
  )
}

export const shouldUpdateFeatureElevation = (
  feature: SiteFeatureGeoJSON<Point>,
  levelFeature: SiteFeatureGeoJSON
): boolean => {
  const {
    properties: { floorHeight: levelFloorHeight = 0, height: levelHeight = 0 },
  } = levelFeature
  const { featureType, geometry } = feature
  const elevation =
    geometry && geometry.coordinates ? geometry.coordinates[2] : 0
  if (featureType === SITE_FEATURE_TYPES.beacon) {
    const levelCeilingHeight =
      levelHeight > 0 ? levelFloorHeight + levelHeight : Infinity
    return !_.inRange(elevation, levelFloorHeight, levelCeilingHeight)
  }
  return true
}

type Search = {
  searchQuery?: string
  selectedSearchFields?: string[]
}

export const shouldUpdateSearch = (
  oldSearch: Search,
  newSearch: Search
): boolean => {
  const {
    searchQuery: oldSearchQuery = '',
    selectedSearchFields: oldSearchFields = [],
  } = oldSearch
  const { searchQuery: newSearchQuery, selectedSearchFields: newSearchFields } =
    newSearch

  if (oldSearchQuery === newSearchQuery) {
    if (oldSearchQuery === '') {
      return false
    }

    if (_.isEqual(oldSearchFields, newSearchFields)) {
      return false
    }
    if (_.isEmpty(oldSearchFields) && !_.isEmpty(newSearchFields)) {
      return true
    }
    if (!_.isEmpty(oldSearchFields) && _.isEmpty(newSearchFields)) {
      return true
    }
  }
  return true
}

export const updateSiteTypeForCASL = (site: Site): Site & { type: 'site' } => ({
  ...site,
  type: BACKEND_ENTITY_TYPES.site,
})
