import { ReactElement, useMemo } from 'react'
import { THEMES } from 'constants/colour'

import type { ThemeType } from 'types/common'

import scss from './index.module.scss'

const EmptyList = ({
  content = 'data',
  render,
  theme = THEMES.dark,
}: {
  content: string
  render?: string | ReactElement
  theme: ThemeType
}): ReactElement => {
  const isLightTheme = useMemo(() => theme === THEMES.light, [theme])

  return (
    <div
      className={scss.noData}
      style={{ color: isLightTheme ? 'black' : 'white' }}
    >
      {render || (
        <>
          <h5>Currently you have no {content}. </h5>
          <p>{content} will show up here.</p>
        </>
      )}
    </div>
  )
}

export default EmptyList
