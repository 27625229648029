import _ from 'lodash'
import isURL from 'validator/lib/isURL'
import crossfilter from 'crossfilter2'

// constants
import { GEOMETRY_TYPES } from 'constants/map'
import {
  DATASET_TYPES,
  DATASET_SOURCE,
  TIME_PROPERTY_KEY,
  USE_LOCAL_CACHE_KEY,
  LIVE_PLUS_DATASET_TYPES,
  DMS_DATA_TYPES,
} from 'constants/unipipe'
import {
  PROPERTY_NAME,
  DEFAULT_IDENTITY_PROPERTY,
  DEFAULT_TIME_PROPERTY,
  PROPERTY_TIME,
} from 'constants/common'

// utils
import {
  getObjectHash,
  getConnectedStringFromArray,
  getErrorMessages,
  transformProperties,
} from 'helpers/utils'
import { showWarn } from 'helpers/message'
import log, { reportMessage } from 'helpers/log'
import {
  isDateTimeRangeValid,
  isTimeValidWithIsoFormat,
} from 'helpers/datetime'

import type { Feature } from 'geojson'
import type {
  Payload,
  SpecParams,
  DatasetMetadata,
  DatasetServices,
  PropertiesMetadata,
  SpecificationParameters,
  DatasetOptions,
  DatasetIdentifier,
  DatasetServicesIdentifier,
} from 'types/common'
import type {
  UpSpecification,
  UpSpecificationTimeliness,
  DatasetId,
  CatalogId,
} from 'types/unipipe'
import type { MapLayer, MapLayerData, GeojsonData } from 'types/map'
import type { PickedDatasetsMetadata } from 'contexts/selectors/unipipe'

const getPropertyValue = (data: GeojsonData, propertyName: string) =>
  _.get(data, ['properties', propertyName])

export const getIdentityPropertyValue = (
  data: GeojsonData,
  identityProperty = DEFAULT_IDENTITY_PROPERTY
): string => getPropertyValue(data, identityProperty)

export const getTimePropertyValue = (
  data: GeojsonData,
  timeProperty = DEFAULT_TIME_PROPERTY
): string => getPropertyValue(data, timeProperty)

export const getDataIdentityAndTimePropertyValue = (
  data: GeojsonData,
  identityProperty?: string,
  timeProperty?: string
): { name: string; time: string } => {
  return {
    name: getIdentityPropertyValue(data, identityProperty),
    time: getTimePropertyValue(data, timeProperty),
  }
}

export const getIdentityProperty = ({
  pickedDatasetsMetadata,
  dataset,
}: {
  pickedDatasetsMetadata: PickedDatasetsMetadata
  dataset: DatasetId
}): string => {
  const { identityProperty = DEFAULT_IDENTITY_PROPERTY } =
    pickedDatasetsMetadata[dataset] || {}

  return identityProperty
}

/**
 * Generate hashes from the given specification parameters object
 * @param {Object} specParams: specification parameters object
 *
 * @return {String} hash value of the given specParams object if the given input is not empty; otherwise, returns empty string
 */
export const getSpecParamsHash = (specParams?: SpecParams): string =>
  getObjectHash(specParams)

export const getLayerSpecParams = (layer: MapLayer): SpecParams => {
  const { specParams = {}, dateTimeRange: { start, end } = {} } = layer
  return start && end
    ? { startTime: start, endTime: end, ...specParams }
    : specParams
}

export const getDatasetsByTimeliness =
  (timeliness: UpSpecificationTimeliness) =>
  (datasets: DatasetMetadata[]): DatasetMetadata[] =>
    _.filter(datasets, { timeliness })

export const getDatasetsByDataSource =
  (source: string) =>
  (datasets: DatasetMetadata[]): DatasetMetadata[] =>
    _.filter(datasets, { source })

export const isLiveDataset = (
  timeliness?: UpSpecificationTimeliness
): boolean => _.includes(LIVE_PLUS_DATASET_TYPES, timeliness)

export const isFeatureDataset = (
  timeliness?: UpSpecificationTimeliness
): boolean => timeliness === DATASET_TYPES.feature

export const isHistoricalDataset = (
  timeliness?: UpSpecificationTimeliness
): boolean => timeliness === DATASET_TYPES.historical

export const isWorkflowEligibleLiveDataset = (
  dataset?: DatasetMetadata
): boolean => dataset?.source === DATASET_SOURCE.mqtt

export const isAssetProfileEligibleDataset = (
  dataset?: DatasetMetadata
): boolean => !_.isNil(dataset?.assetProfile)

export const isWorkflowEligibleNonLiveDataset = (
  dataset?: DatasetMetadata
): boolean => isFeatureDataset(dataset?.timeliness)

export const isRequiredKey = (necessity: string | boolean): boolean =>
  necessity === 'required' || necessity === true

export const shouldSpecificationParametersRequireTimeRange = ({
  startTime,
  endTime,
}: {
  startTime?: string
  endTime?: string
} = {}): boolean =>
  isRequiredKey(_.get(startTime, 'necessity')) &&
  isRequiredKey(_.get(endTime, 'necessity'))

export const getMapEligibleDatasets = (
  datasets: DatasetMetadata[]
): DatasetMetadata[] => {
  return _(datasets)
    .filter(({ endpoint }) => !!(endpoint && isURL(endpoint)))
    .filter(({ refreshRate }) => !refreshRate || refreshRate < 3600000)
    .value()
}

export const getMapEligibleLiveDatasets = (
  datasets: DatasetMetadata[]
): DatasetMetadata[] =>
  _.filter(datasets, ({ timeliness }) => isLiveDataset(timeliness))

export const getWorkflowEligibleDatasets = (
  datasets: DatasetMetadata[]
): DatasetMetadata[] =>
  _.filter(
    datasets,
    d => isWorkflowEligibleNonLiveDataset(d) || isWorkflowEligibleLiveDataset(d)
  )

export const getWorkflowEligibleLiveDataset = getDatasetsByDataSource(
  DATASET_SOURCE.mqtt
)

export const isRulesEngineEligibleDataset = (
  dataset: DatasetMetadata,
  rulesEngineEligibleDatasets: DatasetMetadata[]
): boolean => Boolean(_.find(rulesEngineEligibleDatasets, ['value', dataset]))

export const getDatasetName = ({
  label,
  value,
}: {
  label: string
  value: string
}): string => label || value

export const getDatasetIdentifier = (
  catalogId: CatalogId,
  dataset: DatasetId
): DatasetIdentifier => `${catalogId}-${dataset}`

/**
 * Get the dataset service name based on the dataset name and the hash value of the specification parameters
 * @prop {String} dataset dataset name
 * @prop {Object} specParams specification parameters key-value pairs
 *
 * @return {String} dataset service name
 */
export const getDatasetServiceIdentifier = (
  layer?: MapLayer
): DatasetServicesIdentifier | undefined => {
  if (!layer) return undefined

  const { dataset, catalogId } = layer
  const specParams = getLayerSpecParams(layer)
  const specParamsHash = getSpecParamsHash(specParams)
  const datasetIdentifier = getDatasetIdentifier(catalogId, dataset)
  return specParamsHash
    ? `${datasetIdentifier}-${specParamsHash}`
    : datasetIdentifier
}

export const getDatasetPropertyMetadata = (
  layer: MapLayer,
  datasetServices: DatasetServices
): PropertiesMetadata => {
  const datasetServiceIdentifier = getDatasetServiceIdentifier(layer)
  return _.get(datasetServices[datasetServiceIdentifier], 'metadata.properties')
}

export const convertPropertiesListToFeatures = (
  propertiesList: Payload[],
  keys?: string[]
): Payload[] =>
  propertiesList
    ? propertiesList.map(properties => ({
        properties: _.isEmpty(keys) ? properties : _.pick(properties, keys),
      }))
    : []

// TODO: remove this after coming up with a better way to handle with the long-lasting live data.
// Some live datasets will send GeoJSON message every 30 seconds with the following content: {"type":"Feature","geometry":{"type":"Point","coordinates":[0,0]},"properties":{"name":"[heartbeat]","time":"2020-02-27T22:50:00.007Z"}}
// This mechanism will keep the connection between the front-end and the back-end. Otherwise, the back-end will return the 502 or similar 500 errors, and the front-end will lose the connection for accessing the live data with low update frequency (e.g., 10 mins+).
export const isHeartBeatData = (data: Feature): boolean =>
  _.get(data, PROPERTY_NAME) === '[heartbeat]'

export const isInvalidOrActiveLiveDataSetService = ({
  loading,
  metadata: { timeliness },
}: {
  loading: boolean
  metadata: DatasetMetadata
}): boolean => {
  return !loading || isLiveDataset(timeliness)
}

export const getValidSpecificationParameters = (
  specificationParameters: SpecificationParameters
): SpecificationParameters =>
  _.omit(specificationParameters, [USE_LOCAL_CACHE_KEY])

export const isEmptySpecificationParameters = (
  specificationParameters: SpecificationParameters
): boolean =>
  _.isEmpty(getValidSpecificationParameters(specificationParameters))

/**
 * Get the dataset option by the given dataset name and catalog id
 * @param {Array} datasetOptions
 * @param {String} name dataset name
 * @param {String} catalogId: catalog Id
 *
 * @return {Object} matched dataset option, else empty object {}.
 */
export const getDatasetOptionByDatasetNameAndCatalogId = (
  datasetOptions: DatasetOptions,
  name: string,
  catalogId: CatalogId
): DatasetMetadata | undefined =>
  _.find(datasetOptions, {
    ...(catalogId && { catalogId }),
    value: name,
  })

/**
 * Returns validation information for the specParams
 * @param {Object} specParams layer dataset specParams
 * @param {Object} specificationParameters specification parameters schema
 *
 * @return {Object} errors
 */
export const validateSpecParams = (
  specParams: SpecParams,
  specificationParameters: SpecificationParameters
): {
  missingRequiredKeys: string[]
  invalidValues: string[]
} => {
  if (isEmptySpecificationParameters(specificationParameters)) {
    return { missingRequiredKeys: [], invalidValues: [] }
  }

  const missingRequiredKeys = _.reduce(
    specificationParameters,
    (results, { necessity, format }, key) => {
      const value = _.get(specParams, key)
      const hasRequiredKey = isRequiredKey(necessity)
        ? format === TIME_PROPERTY_KEY
          ? isTimeValidWithIsoFormat(value)
          : !!value
        : true
      if (!hasRequiredKey) {
        results.push(key)
      }
      return results
    },
    [] as string[]
  )

  const invalidValues = []
  const { startTime, endTime } = specParams || {}
  if (startTime && endTime && !isDateTimeRangeValid(startTime, endTime)) {
    invalidValues.push('time range')
  }

  return { missingRequiredKeys, invalidValues }
}

/**
 * Check the given specParams is valid or not
 * @param {Object} specParams layer dataset specParams
 * @param {Object} specificationParameters specification parameters schema
 *
 * @return {Boolean} true if the given specParams is valid; false otherwise
 */
export const isValidSpecParams = (
  specParams: SpecParams,
  specificationParameters: SpecificationParameters
): boolean => {
  const { missingRequiredKeys, invalidValues } = validateSpecParams(
    specParams,
    specificationParameters
  )

  return _.isEmpty(missingRequiredKeys) && _.isEmpty(invalidValues)
}

/**
 * Get error reasons why the given specParams is not valid
 * @param {Object} specParams layer dataset specParams
 * @param {Object} specificationParameters specification parameters schema
 *
 * @return {String} validation error message
 */
export const getSpecParamValidationMessages = (
  specParams: SpecParams,
  specificationParameters: SpecificationParameters
): string => {
  const { missingRequiredKeys, invalidValues } = validateSpecParams(
    specParams,
    specificationParameters
  )

  return getConnectedStringFromArray([
    getErrorMessages(missingRequiredKeys, 'required'),
    getErrorMessages(invalidValues, 'not valid'),
  ])
}

/**
 * Generate dataset requests of each layer
 * @param {Array} layers: layers to iterate
 * @param {Array} datasetOptions: datasets catalog
 *
 * @return {Object} dataset requests object
 * {specParamsHash,specParams,catalogItem}
 */
export const getDatasetsSpecificationRequests = (
  layers: MapLayer[],
  datasetOptions: DatasetOptions,
  hasNewLayer?: boolean
): {
  [key: string]: { catalogItem: DatasetMetadata; specParams: SpecParams }
} => {
  if (_.isEmpty(datasetOptions)) return {}

  const errorLayers: string[] = []

  const requests = layers.reduce((acc, layer) => {
    const { name, dataset: datasetName, catalogId } = layer
    if (!datasetName) return acc

    const catalogItem = getDatasetOptionByDatasetNameAndCatalogId(
      datasetOptions,
      datasetName,
      catalogId
    )
    if (!catalogItem) {
      reportMessage(
        `Dataset<${datasetName}> from catalog<${catalogId}> not found`,
        'warn',
        { layer, catalogId, datasetOptions }
      )
      errorLayers.push(`layer - ${name}`)
      return acc
    }
    const { specificationParameters } = catalogItem
    const specParams = getLayerSpecParams(layer)
    if (!isValidSpecParams(specParams, specificationParameters)) {
      if (!hasNewLayer) {
        log.warn(`[Layer:${name}]`, {
          [datasetName]: getSpecParamValidationMessages(
            specParams,
            specificationParameters
          ),
        })
        reportMessage(`[Layer:${name}] specParams is not valid`, 'warn', {
          specParams,
          layer,
        })
      }

      return acc
    }

    const datasetServiceIdentifier = getDatasetServiceIdentifier(layer) || ''
    return acc[datasetServiceIdentifier]
      ? acc
      : {
          ...acc,
          [datasetServiceIdentifier]: {
            catalogItem,
            specParams,
          },
        }
  }, {} as Record<string, { catalogItem: DatasetMetadata; specParams: SpecParams }>)

  if (!_.isEmpty(errorLayers)) {
    const uniqLayers = _.uniq(errorLayers)
    const error = `Datasets from ${getConnectedStringFromArray(
      uniqLayers
    )} have to be updated.`
    showWarn(error, { autoClose: false, toastId: 'invalid-dataset-layers' })
  }
  return requests
}

const getDatasetSpecification = (
  datasetObj: UpSpecification
): {
  baseSpecification: Payload
  specificationParameters: SpecificationParameters
} => {
  const {
    baseSpecification,
    specificationParameters = {},
    timeliness = DATASET_TYPES.live,
  } = datasetObj
  const { specParams = {} } = baseSpecification || {}

  return isLiveDataset(timeliness)
    ? { baseSpecification, specificationParameters }
    : {
        baseSpecification: {
          ...baseSpecification,
          specParams,
        },
        specificationParameters,
      }
}

export const getDatasetOption = (
  catalogItem: UpSpecification
): DatasetMetadata => {
  const {
    dataset,
    displayName,
    properties,
    hints,
    baseSpecification,
    timeliness,
    ...rest
  } = catalogItem

  const { dataSource: { source = '' } = {} } = baseSpecification || {}

  const {
    geometryType = GEOMETRY_TYPES.Point,
    timeliness: hintsTimeliness = DATASET_TYPES.live,
    refreshRate,
  } = hints || {}

  return {
    ...rest,
    ...getDatasetSpecification(catalogItem),
    value: dataset,
    label: displayName,
    properties: transformProperties(properties),
    geometryType,
    timeliness: timeliness || hintsTimeliness,
    source,
    refreshRate,
    hints,
    dataset,
  }
}

export const getCatalogItem = (
  catalog: { [key: string]: DatasetMetadata },
  datasetId: DatasetId,
  catalogId: CatalogId
): DatasetMetadata => {
  return _.get(catalog, `${getDatasetIdentifier(catalogId, datasetId)}`)
}

/**
 * Fetch data from the context for the given dataset service based on data type
 * @param {String} datasetServiceIdentifier: required dataset service name
 * @param {Object} datasetServices: global dataset services context state
 * @param {String} type: data source type. Could be one of [DATASET_TYPES.historical,DATASET_TYPES.feature,DATASET_TYPES.live]
 *
 * @return {Array} data for the given dataset
 */
export const getDatasetServiceData = (
  datasetServiceIdentifier: string,
  datasetServices: DatasetServices,
  type: 'historical' | 'feature' | 'live'
): MapLayerData | undefined => {
  if (_.isEmpty(datasetServices) || !datasetServiceIdentifier) return undefined

  const dataType = _.includes(
    [DATASET_TYPES.historical, DATASET_TYPES.feature],
    type
  )
    ? DMS_DATA_TYPES.geojsonRows
    : DMS_DATA_TYPES.lastKnownLocations

  return _.get(datasetServices, [datasetServiceIdentifier, dataType])
}

/**
 * Get the live data by the feature  identity property value
 * @param {String} datasetName : dataset name
 * @param {Object} datasetServices : unipipe dataset services state
 * @param {String} identityPropertyValue: feature identity property value
 *
 * @return {Array} live data of the given feature
 */
export const getLatestDataByFeatureIdentityProperty = (
  datasetName: string,
  datasetServices: DatasetServices,
  identityPropertyValue: string
): GeojsonData | undefined => {
  if (!datasetName) return undefined

  const { metadata } = datasetServices[datasetName] || {}

  const { identityProperty } = metadata || {}
  const datasetData = getDatasetServiceData(
    datasetName,
    datasetServices,
    DATASET_TYPES.live
  )
  return _.find(
    datasetData,
    d => getIdentityPropertyValue(d, identityProperty) === identityPropertyValue
  )
}

export const getLastKnownLocations = (
  geojsonRows: MapLayerData,
  identityProperty = DEFAULT_IDENTITY_PROPERTY
): MapLayerData => {
  const data = crossfilter(geojsonRows)
  const geojsonRowsByName = data.dimension(d =>
    getIdentityPropertyValue(d, identityProperty)
  )

  const reduceLast = (p: unknown, v: GeojsonData) =>
    getTimePropertyValue(p as GeojsonData) > getTimePropertyValue(v) ? p : v

  return _(
    geojsonRowsByName
      .group()
      .reduce(reduceLast, _.noop, () => ({}))
      .all()
  )
    .map('value')
    .orderBy([PROPERTY_TIME], ['desc'])
    .value() as MapLayerData
}
