import type { OnListItemChange } from 'components/common/List/hooks/useListItemActions'
import type { CardActions } from 'components/common/List/Card/CardOptions'
import type { FormList } from 'types/formBuilder'

const useItemActions = ({
  onChange,
  setSelectedFormListId,
}: {
  onChange: OnListItemChange<FormList>
  setSelectedFormListId: (v: string) => void
}): CardActions<FormList> => {
  const onView = ({ id }: FormList) => {
    setSelectedFormListId(id)
  }

  return {
    onChange,
    onView,
    onEdit: onView,
  }
}

export default useItemActions
