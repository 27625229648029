// libraries
import _ from 'lodash'
import { toast } from 'react-toastify'

// constants
import {
  SET_UNIPIPE_CATALOG,
  UPDATE_UNIPIPE_CATALOG_ENTRY,
  SET_UNIPIPE_DATA_INIT,
  SET_UNIPIPE_DATA_SUCCESS,
  SET_UNIPIPE_DATA_MESSAGE,
  ABORT_UNIPIPE_DATASETS_SERVICE,
  EMPTY_UNIPIPE_DATASETS,
  SET_UNIPIPE_GROUPS,
} from 'contexts/actions/unipipe'
import { DEFAULT_IDENTITY_PROPERTY } from 'constants/common'

// utils
import { getFormattedUtcNow } from 'helpers/datetime'
import {
  isLiveDataset,
  isInvalidOrActiveLiveDataSetService,
  getLastKnownLocations,
  getDatasetIdentifier,
} from 'helpers/unipipe'

export type UnipipeState = {
  loading: false,
  catalog: unknown,
  toasts: [],
  datasetServices: unknown,
  activeDatasetServiceIdentifies: [],
  groups: [],
}

export const unipipeState = {
  /**
   * {Boolean} - true as long as one of the dataset services is still active. false otherwise
   */
  loading: false,
  /**
   * {Object} - datasets catalog list
   */
  catalog: {},
  /**
   * {Array} - dataset service related-notifications list
   */
  toasts: [],
  /**
   * {Object} - the latest toast notification
   */
  latestToast: undefined,
  /**
   * {Object} - dataset service object which includes the service instance, active status, dataset metadata, specification, all received geojson data and the last known data of each feature
   */
  datasetServices: {},
  /**
   * {Array} - active dataset service names list
   */
  activeDatasetServiceIdentifies: [],
  /**
   * {Array} -  dataset groups
   */
  groups: [],
}

/**
 * Check whether there is an active dataset service except the given datasetServiceIdentifier
 * @prop {String} datasetServiceIdentifier
 * @prop {Boolean} isDatasetLoading
 * @prop {Object} datasetServices
 *
 * @return {Boolean} true as long as one of non-live dataset services is still running;false otherwise
 */
const areNonLiveDatasetsLoading = ({
  datasetServices,
  isDatasetLoading = false,
  datasetServiceIdentifier = '',
}) =>
  isDatasetLoading ||
  !_.isEmpty(
    _(datasetServices)
      .omit(datasetServiceIdentifier)
      .reject(isInvalidOrActiveLiveDataSetService)
      .value()
  )

const getToastState = ({ toasts, render, datasetName, type }) => {
  const latestToast = {
    render,
    datasetName,
    type,
    timestamp: getFormattedUtcNow(),
  }
  return {
    toasts: [...toasts, latestToast],
    latestToast,
  }
}

const getServiceDataSuccessToastState = (
  serviceTimeliness,
  isDatasetLoading,
  toastInfo
) => {
  return isLiveDataset(serviceTimeliness)
    ? isDatasetLoading
      ? // for live dataset, display the notification only when the first batch is loaded
        toastInfo
      : {}
    : // for historical and feature dataset,display the notification only when all data for this dataset is loaded
    isDatasetLoading
    ? {}
    : toastInfo
}

const unipipeReducer = (state, action) => {
  const { datasetName, datasetServiceIdentifier, type } = action
  switch (type) {
    case SET_UNIPIPE_CATALOG:
      return {
        ...state,
        catalog: action.catalog,
      }

    case UPDATE_UNIPIPE_CATALOG_ENTRY: {
      const { catalogId, dataset } = action.catalogEntry

      const datasetIdentifier = getDatasetIdentifier(catalogId, dataset)
      const catalogItem = { ...action.catalogEntry, hasFetchedBefore: true }

      return {
        ...state,
        catalog: {
          ...state.catalog,
          [datasetIdentifier]: catalogItem,
        },
      }
    }

    case SET_UNIPIPE_DATA_INIT: {
      const toastState = getToastState({
        toasts: state.toasts,
        render: `Fetching ${datasetName} data...`,
        datasetName,
        type: toast.TYPE.INFO,
      })

      const { timeliness } = action.catalogItem

      return {
        ...state,
        ...(isLiveDataset(timeliness) && toastState),
        loading: true,
        activeDatasetServiceIdentifies: _.uniq([
          ...state.activeDatasetServiceIdentifies,
          datasetServiceIdentifier,
        ]),
        datasetServices: {
          ...state.datasetServices,
          [datasetServiceIdentifier]: {
            datasetServiceIdentifier,
            datasetName,
            service: action.service,
            worker: action.worker,
            metadata: action.catalogItem,
            specification: action.specification,
            geojsonRows: action.geojsonRows || [],
            lastKnownLocations: action.lastKnownLocations || [],
            loading: true,
            loadCanceled: false,
          },
        },
      }
    }

    case SET_UNIPIPE_DATA_SUCCESS: {
      const oldDatasetService = state.datasetServices[datasetServiceIdentifier]
      if (!oldDatasetService || !oldDatasetService.service) return state

      const isDatasetLoading = action.isServiceLoading

      const {
        metadata: { identityProperty = DEFAULT_IDENTITY_PROPERTY },
      } = oldDatasetService

      const geojsonRows = [
        ...(oldDatasetService.geojsonRows || []),
        ...action.geojsonRows,
      ]
      const { timeliness } = oldDatasetService.metadata
      const lastKnownLocations = isLiveDataset(timeliness)
        ? getLastKnownLocations(
            [
              ...(oldDatasetService.lastKnownLocations || []),
              ...action.geojsonRows,
            ],
            identityProperty
          )
        : undefined

      const loading = areNonLiveDatasetsLoading({
        datasetServiceIdentifier,
        isDatasetLoading,
        datasetServices: state.datasetServices,
      })

      const toastState = loading
        ? undefined
        : getServiceDataSuccessToastState(
            timeliness,
            isDatasetLoading,
            getToastState({
              toasts: state.toasts,
              render: `Map data successfully loaded`,
              datasetName,
              type: toast.TYPE.SUCCESS,
            })
          )

      return {
        ...state,
        ...toastState,
        loading,
        datasetServices: {
          ...state.datasetServices,
          [datasetServiceIdentifier]: {
            ...oldDatasetService,
            ...(lastKnownLocations && { lastKnownLocations }),
            loading: isDatasetLoading,
            geojsonRows,
          },
        },
      }
    }

    case SET_UNIPIPE_DATA_MESSAGE: {
      const oldDatasetService = state.datasetServices[datasetServiceIdentifier]
      if (!oldDatasetService) return state

      const toastState = getToastState({
        toasts: state.toasts,
        render: `[${datasetName}]${action.message}`,
        datasetName,
        type: action.messageType,
      })
      const isErrorMessage = action.messageType === toast.TYPE.ERROR

      const loading = areNonLiveDatasetsLoading({
        datasetServiceIdentifier,
        isDatasetLoading: false,
        datasetServices: state.datasetServices,
      })

      return {
        ...state,
        ...toastState,
        loading,
        ...(isErrorMessage
          ? {
              // remove the dataset request service
              datasetServices: {
                ...state.datasetServices,
                [datasetServiceIdentifier]: {
                  ...oldDatasetService,
                  loading: false,
                },
              },
              // remove the dataset name from the active dataset services list
              activeDatasetServiceIdentifies: _.without(
                state.activeDatasetServiceIdentifies,
                datasetServiceIdentifier
              ),
            }
          : {}),
      }
    }

    case ABORT_UNIPIPE_DATASETS_SERVICE: {
      const loading = _.has(action, 'loading')
        ? action.loading
        : areNonLiveDatasetsLoading({
            datasetServices: action.datasetServices,
          })

      const toastState = getToastState({
        toasts: state.toasts,
        render: `Request canceled by the user.`,
        type: toast.TYPE.INFO,
      })

      return {
        ...state,
        ..._.omit(action, ['type']),
        ...toastState,
        loading,
      }
    }

    case EMPTY_UNIPIPE_DATASETS:
      return {
        ...state,
        latestToast: undefined,
        datasetServices: {},
        activeDatasetServiceIdentifies: [],
      }

    case SET_UNIPIPE_GROUPS:
      return {
        ...state,
        groups: action.groups,
      }

    default:
      return state
  }
}

export default unipipeReducer
