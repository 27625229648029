// libraries
import { ReactElement, useState } from 'react'

// utils
import { switchcaseF } from 'helpers/utils'

// constants
import { FORM_GALLERY_VIEWS, FormGalleryView } from './constant'

// components
import { Forms, FormLists } from './tabs'

export type FormGalleryPassProps = {
  selectedView: FormGalleryView
  setSelectedView: (v: FormGalleryView) => void
}

const FormGallery = (): ReactElement => {
  const [selectedView, setSelectedView] = useState<FormGalleryView>(
    FORM_GALLERY_VIEWS.form
  )

  const props = {
    selectedView,
    setSelectedView,
  } as FormGalleryPassProps

  return switchcaseF({
    [FORM_GALLERY_VIEWS.form]: () => <Forms {...props} />,
    [FORM_GALLERY_VIEWS.formList]: () => <FormLists {...props} />,
  })(() => <Forms {...props} />)(selectedView)
}

export default FormGallery
