import { css } from '@emotion/react'

export const INPUT_LARGE_DEFAULT_Y_PADDING = 14
export const INPUT_LARGE_DEFAULT_X_PADDING = 14

export const INPUT_REGULAR_DEFAULT_Y_PADDING = 9
export const INPUT_REGULAR_DEFAULT_X_PADDING = 12

export const REGULAR_INPUT_MIXIN = css`
  padding: ${INPUT_REGULAR_DEFAULT_Y_PADDING}px
    ${INPUT_REGULAR_DEFAULT_X_PADDING}px;
  font-size: 14px;
  line-height: 18px;
`
