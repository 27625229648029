import React from 'react'
import PropTypes from 'prop-types'

import scss from './index.module.scss'

const MainContentLayout = ({ children }) => (
  <main role='main' className={scss.content} id='main'>
    {children}
  </main>
)

MainContentLayout.propTypes = {
  children: PropTypes.node,
}

MainContentLayout.defaultProps = {
  children: undefined,
}

export default MainContentLayout
