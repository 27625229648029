// libraries
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from 'react'
import _ from 'lodash'
import {
  DataTable as ReactDataTable,
  DataTableProps as ReactDataTableProps,
  DataTableRowGroupHeaderTemplateOptions,
  DataTableRowToggleParams,
  DataTableSelectParams,
} from 'primereact/datatable'
import { Column, ColumnSortParams } from 'primereact/column'

// constants
import { THEMES } from 'constants/colour'
import { TOOLTIP_PLACEMENT } from 'constants/settings'
import { BADGE_TYPES } from 'constants/common'

// utils
import {
  sortListByProperty,
  stopEventDefaultAndPropagation,
} from 'helpers/utils'

// components
import { Badge, CardOptions, IconButton } from 'components/common'
import Button, { BUTTON_VARIANTS, BUTTON_SIZES } from 'components/common/Button'
import EmptyColumnsList from 'components/common/List/EmptyColumnsList'

import type { ThemeType, Item, ListConditions } from 'types/common'
import type { SelectedItemsActions } from 'components/common/List/hooks/useBulkUpdateList'
import type { CardActions } from 'components/common/List/Card/CardOptions'
import type {
  ColumnOptions,
  SetVisibleColumns,
} from 'components/common/DataTable/useDataTableColumns'

import 'primereact/resources/primereact.min.css'
import 'primereact/resources/themes/lara-light-indigo/theme.css'
import 'primeicons/primeicons.css'
import { SelectRowCheckBox, StyledDataTable } from './styles'
import { GroupHeaderTemplate } from './CellTemplates'
import { INFINITE_SCROLL_THRESHOLD } from './constants'

export interface DataTableProps
  extends Omit<ReactDataTableProps, 'sortOrder' | 'list' | 'onRowSelect'> {
  list: Item[]
  loading?: boolean
  columns: ColumnOptions[]
  allAvailableColumns: ColumnOptions[]
  setVisibleColumns: SetVisibleColumns
  sortField?: string
  sortOrder?: boolean
  isMultiSort?: boolean
  theme?: ThemeType
  primaryFields?: string[]
  enableBulkEdit?: boolean
  itemActions?: CardActions
  selectedIdsSet?: Set<string>
  selectedItemsActions?: SelectedItemsActions
  enableActions?: boolean
  renderSelectAllCheckbox?: (displayLabel: boolean) => void
  tableGroupedBy?: string
  fetchMore?: () => Promise<unknown[]>
  isLoadingMore?: boolean
  hasNextPage?: boolean
  dataKey?: string
  isVirtualScroll?: boolean
  setListConditions?: Dispatch<SetStateAction<ListConditions>>
  currentActiveItem?: Item
}

const LoadingMoreSpinner = () => (
  <div className='d-flex justify-content-center align-items-center'>
    <Button
      variant={BUTTON_VARIANTS.link}
      size={BUTTON_SIZES.large}
      isLoading
    />
  </div>
)

const BodyActionsTemplate =
  (itemActions: DataTableProps['itemActions']) => (rowData: Item) => {
    return (
      !_.isEmpty(itemActions) && (
        <CardOptions
          {...itemActions}
          subject={rowData}
          className='actionMenu'
          placement={TOOLTIP_PLACEMENT.bottomRight}
        />
      )
    )
  }

const BodySelectTemplate =
  (selectedItemsActions: SelectedItemsActions) => (rowData: Item) => {
    const { id } = rowData
    return (
      <SelectRowCheckBox>
        <IconButton
          icon={
            selectedItemsActions.has(id)
              ? 'MdCheckBox'
              : 'MdCheckBoxOutlineBlank'
          }
          onClick={e => {
            selectedItemsActions.toggle(id)
            stopEventDefaultAndPropagation(e)
          }}
          size={24}
        />
      </SelectRowCheckBox>
    )
  }

const sortFunction = (event: ColumnSortParams) => {
  const { data, field, order } = event
  return sortListByProperty({
    list: data,
    sortField: field,
    ascOrder: order === 1,
  })
}

const DataTable = ({
  list,
  columns = [],
  allAvailableColumns,
  setVisibleColumns,
  sortField,
  sortOrder,
  isMultiSort = false,
  primaryFields,
  itemActions = {},
  selectedIdsSet,
  selectedItemsActions,
  theme = THEMES.light,
  enableBulkEdit = false,
  enableActions = true,
  tableGroupedBy,
  fetchMore,
  isLoadingMore,
  hasNextPage,
  dataKey = 'id',
  isVirtualScroll = true,
  setListConditions,
  currentActiveItem,
}: DataTableProps): ReactElement => {
  const isLightTheme = useMemo(() => theme === THEMES.light, [theme])
  const { onSelect, onView, onEdit } = itemActions || {}

  const [expandedRows, setExpandedRows] = useState<Item[]>([])

  useEffect(() => {
    if (tableGroupedBy && !_.isEmpty(expandedRows)) {
      // when tableGroupedBy changed, clear out the current expanded rows
      setExpandedRows([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableGroupedBy])

  const validColumnsSpecs = useMemo(() => {
    const commonProps = {
      headerStyle: { width: '3rem', textAlign: 'center' },
      bodyStyle: { textAlign: 'center', overflow: 'visible' },
      field: undefined,
      sortable: false,
      resizeable: false,
    }

    const selectColumnStyle = {
      justifyContent: 'center',
      maxWidth: '75px',
    }

    const selectColumn = enableBulkEdit
      ? {
          ...commonProps,
          header: !_.isEmpty(selectedIdsSet) && (
            <IconButton
              icon='MdCheckBox'
              onClick={() => {
                selectedItemsActions.reset()
              }}
              size={24}
            />
          ),
          body: BodySelectTemplate(selectedItemsActions),
          headerStyle: {
            ...selectColumnStyle,
            height: '60px',
          },
          style: selectColumnStyle,
        }
      : undefined

    const actionColumn =
      enableActions && !_.isEmpty(_.omit(itemActions, 'onChange'))
        ? {
            ...commonProps,
            header: '',
            body: BodyActionsTemplate(itemActions),
          }
        : undefined

    return _.compact([selectColumn, ...columns, actionColumn])
  }, [
    columns,
    enableActions,
    enableBulkEdit,
    itemActions,
    selectedIdsSet,
    selectedItemsActions,
  ])

  const renderColumns = useCallback(() => {
    return _.map(validColumnsSpecs, col => {
      const { header, field, bodyStyle } = col
      return (
        <Column
          key={header as React.Key}
          {...col}
          // !group by can't work together with sort
          {...(tableGroupedBy
            ? { sortable: false }
            : {
                sortField: field,
                sortFunction,
              })}
          bodyStyle={
            _.includes(primaryFields, header)
              ? { fontWeight: 'bold' }
              : bodyStyle
          }
        />
      )
    })
  }, [validColumnsSpecs, primaryFields, tableGroupedBy])

  const onRowSelect = useCallback(
    (e: DataTableSelectParams) => {
      const select = onSelect || onView || onEdit
      if (!_.isFunction(select)) return
      // The onRowSelect event is being triggered when the Modal is on
      // for example, table -> row action -> transfer modal
      // couldn't find a better way to stop that
      // compare the target to allow the click event for the table td
      if (e.originalEvent.target.nodeName !== 'TD') return

      select(e.data)
    },
    [onEdit, onSelect, onView]
  )

  const dataGroupedBy = useMemo(() => {
    return tableGroupedBy
      ? _(list).groupBy(tableGroupedBy).mapValues(_.size).value()
      : {}
  }, [list, tableGroupedBy])

  const rowGroupHeaderTemplate = useCallback(
    (
      data: Item,
      groupHeaderOptions: DataTableRowGroupHeaderTemplateOptions
    ) => {
      // It's important to set this boolean to 'true'
      // For the reference: https://github.com/primefaces/primereact/commit/161cdccc1a779c17e3c4e94d6f029b31fbba6971
      groupHeaderOptions.customRendering = true
      const options = { field: tableGroupedBy }
      const template = _.find(allAvailableColumns, options)?.body

      const content = template ? (
        template(data, options)
      ) : (
        <span>{_.get(data, tableGroupedBy || '')}</span>
      )

      const total = dataGroupedBy[_.get(data, tableGroupedBy)]

      return (
        <GroupHeaderTemplate
          data={data}
          allColumnsCount={validColumnsSpecs.length}
          expandedRows={expandedRows}
          setExpandedRows={setExpandedRows}
        >
          {content}
          <div>
            <Badge
              content={total}
              type={BADGE_TYPES.infoGrey}
              className='ms-2'
            />
          </div>
        </GroupHeaderTemplate>
      )
    },
    [
      tableGroupedBy,
      allAvailableColumns,
      dataGroupedBy,
      validColumnsSpecs.length,
      expandedRows,
    ]
  )

  const expandableProps = {
    // !key is important otherwise the data will have wrong groups when switching the tableGroupedBy property
    key: tableGroupedBy,
    rowGroupMode: 'subheader',
    groupRowsBy: tableGroupedBy,
    // perhaps a bug from the library
    // !sortField has to be same as the groupRowsBy
    // otherwise there will be duplicate groups
    sortField: tableGroupedBy,
    expandableRowGroups: true,
    expandedRows,
    rowGroupHeaderTemplate,
    onRowToggle: (e: DataTableRowToggleParams) =>
      setExpandedRows(e.data as Item[]),
    virtualScrollerOptions: undefined,
  }

  const onInfiniteScroll = useCallback(
    (e: React.UIEvent<HTMLElement, UIEvent>) => {
      const { scrollHeight, scrollTop, offsetHeight } =
        e.target as HTMLDivElement
      const pxLeftToBottom = scrollHeight - scrollTop - offsetHeight
      const shouldLoadMore =
        hasNextPage && pxLeftToBottom <= INFINITE_SCROLL_THRESHOLD

      if (shouldLoadMore && !isLoadingMore && fetchMore) {
        fetchMore()
      }
    },
    [fetchMore, isLoadingMore, hasNextPage]
  )

  const isColumnsListEmpty =
    // if there are no columns
    validColumnsSpecs.length === 0 ||
    // or there is only 1 columns – checkboxes
    (validColumnsSpecs.length === 1 && !validColumnsSpecs[0].field)

  if (isColumnsListEmpty) {
    return (
      <EmptyColumnsList
        allAvailableColumns={allAvailableColumns}
        setVisibleColumns={setVisibleColumns}
      />
    )
  }

  return (
    <>
      <StyledDataTable isLightTheme={isLightTheme} className='w-100 h-100'>
        <ReactDataTable
          value={list}
          sortOrder={sortOrder ? 1 : -1}
          {...(isMultiSort
            ? { sortMode: 'multiple' }
            : { sortMode: 'single', sortField })}
          scrollable
          scrollHeight='flex'
          {...(isVirtualScroll && {
            virtualScrollerOptions: {
              itemSize: 50,
              onScroll: onInfiniteScroll,
            },
            responsiveLayout: 'scroll',
          })}
          dataKey={dataKey}
          {...(_.isFunction(onRowSelect) && {
            rowHover: true,
            onRowSelect,
            selectionMode: 'single',
            selection: currentActiveItem,
          })}
          emptyMessage='No items'
          resizableColumns
          showGridlines
          {...(setListConditions && {
            // The 'onSort' prop switches a sorting to a 'controlled' mode
            onSort: eventData => {
              // Save the selected sorting options
              setListConditions(prevState => ({
                ...prevState,
                sortField: eventData.sortField,
                ascOrder: eventData.sortOrder === 1,
              }))
            },
          })}
          // !!https://github.com/primefaces/primereact/issues/3470
          // when columns are reordered, duplicate columns are added
          // reorderableColumns
          {...(tableGroupedBy && { ...expandableProps })}
          footer={isLoadingMore ? <LoadingMoreSpinner /> : null}
        >
          {renderColumns()}
        </ReactDataTable>
      </StyledDataTable>
    </>
  )
}

export default DataTable
