import { useState, ReactElement, useEffect, useCallback, useMemo } from 'react'
import _ from 'lodash'

// utils
import { useTimezone } from 'hooks'
import { isValidISODatetimeString, getFutureUtcDate } from 'helpers/datetime'
import { displayTime } from 'helpers/utils'

// constants
import {
  DATE_FORMAT,
  DATE_UNIT_TYPES,
  QUICK_DATETIME_PICKER_OPTIONS,
} from 'constants/datetime'
import { PAGE_FILTER_PLACEHOLDER } from 'constants/common'

// components
import { MultiSelect } from 'components/common'
import { MdKeyboardArrowDown } from 'components/icons'
import { DateRangePicker } from 'components/common/DateTime'

// types
import type { UtcISOString, Timezone } from 'types/datetime'

import { SelectDropdown } from '../FilterForm'
import { CustomOption } from '../FilterDropdown'
import useFiltersDropdown from '../hooks/useFiltersDropdown'
import scss from '../index.module.scss'

const getMaxDate = (minDate: UtcISOString) =>
  getFutureUtcDate({
    interval: 31,
    intervalUnit: DATE_UNIT_TYPES.days,
    baseDatetime: minDate,
  })?.toISOString()

const getDisplayTime = (datetime: UtcISOString, timezone: Timezone) =>
  datetime
    ? displayTime({
        datetime,
        timezone,
        addFromNow: false,
        timeFormat: DATE_FORMAT,
      })
    : PAGE_FILTER_PLACEHOLDER

const Control = () => <></>
const FilterTime = ({
  value,
  onChange,
  title = 'Time',
  vertical = false,
  formatOptionLabel,
}: {
  key: string
  value: [string, string]
  onChange: (v?: string[]) => void
  title: string
  vertical: boolean
  formatOptionLabel?: (v: unknown) => string
}): ReactElement => {
  const { isOpen, toggleOpen, ref } = useFiltersDropdown(value)

  const [timeValue, setTimeValue] = useState(value)
  const [startTime, endTime] = timeValue || []

  const { timezone } = useTimezone()

  const isTimeRelative = useMemo(
    () => !isValidISODatetimeString(startTime),
    [startTime]
  )

  useEffect(() => {
    setTimeValue(value)
  }, [value])

  const badgeContent = useMemo(() => {
    const start = isTimeRelative
      ? _.find(QUICK_DATETIME_PICKER_OPTIONS, { value: startTime })?.label
      : getDisplayTime(startTime, timezone)
    const end = isTimeRelative ? '' : ` -> ${getDisplayTime(endTime, timezone)}`
    return `${start || ''}${end}`
  }, [endTime, isTimeRelative, startTime, timezone])

  const onTimeChange = useCallback(
    newTimeValue => {
      setTimeValue(newTimeValue)
      onChange(newTimeValue)
    },
    [onChange]
  )

  return (
    <div
      className={`d-flex align-items-center flex-wrap ${scss.anchor} `}
      style={{
        marginRight: vertical ? '0' : '10px',
      }}
    >
      <SelectDropdown
        isOpen={isOpen}
        onClose={toggleOpen}
        ref={ref}
        target={
          <div
            className={`d-flex align-items-center ${scss.button} `}
            type='button'
            onClick={toggleOpen}
          >
            <span>{title}: </span>
            <span className={scss.buttonSelected}>{badgeContent}</span>
            <MdKeyboardArrowDown className='ms-2' size={16} />
          </div>
        }
      >
        <div className={scss.dropdown}>
          <DateRangePicker
            startDate={isTimeRelative ? undefined : startTime}
            endDate={isTimeRelative ? undefined : endTime}
            onChange={v => {
              const { startDate, endDate } = v || {}
              const newTimeValue = [startDate, endDate]
              onTimeChange(newTimeValue)
            }}
            timezone={timezone}
            className={scss.timePicker}
            getMaxDate={getMaxDate}
            inputText='Custom Range'
          />
          <MultiSelect
            autoFocus
            isSearchable={false}
            components={{ Option: CustomOption(formatOptionLabel), Control }}
            backspaceRemovesValue={false}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            menuIsOpen
            isClearable={false}
            useOptionValueOnly
            onChange={(option: string) => {
              onTimeChange([option])
            }}
            options={QUICK_DATETIME_PICKER_OPTIONS}
            tabSelectsValue={false}
            value={isTimeRelative ? startTime : undefined}
            isMulti={false}
          />
        </div>
      </SelectDropdown>
    </div>
  )
}

export default FilterTime
