// libraries
import { toast } from 'react-toastify'

// constants
import { MESSAGE_TYPES } from 'constants/message'

// utils
import { reportErrors, reportMessage } from 'helpers/log'

import type { Payload } from 'types/common'
import type { Entity } from 'types/entity'

export { toast }

export const showToast =
  (type: string = toast.TYPE.INFO, defaultOptions = {}) =>
  (newMessage: string, options: Payload = {}): string => {
    return (
      toast[type] && toast[type](newMessage, { ...defaultOptions, ...options })
    )
  }

export const showInfo = showToast(toast.TYPE.INFO)

export const showWarn = showToast(toast.TYPE.WARNING)

export const showSuccess = showToast(toast.TYPE.SUCCESS)

export const showError = showToast(toast.TYPE.ERROR, {
  autoClose: false,
})

export const updateExistingToast = (
  id: string,
  newMessage: string,
  options: Payload = {}
): void => {
  toast.update(id, {
    ...options,
    render: newMessage,
  })
}

type ShowEntityCrudMessage<T> = {
  entity?: string
  status: string
  type?: string
  error?: Error | null
  toastId?: string
  subject?: T
  autoClose?: number
  message?: string
}

export const showEntityCrudMessage = <T extends Partial<Entity>>({
  type = MESSAGE_TYPES.info,
  entity,
  status,
  error,
  toastId,
  subject,
  message,
  autoClose = 1000,
}: ShowEntityCrudMessage<T>): string => {
  let id = toastId
  const { name, type: entityType } = (subject || {}) as Entity
  const messagePrefix = `${name || ''} ${entity || entityType} ${status} `
  const successMessage = `${messagePrefix} successfully `
  const errorMessage = `${messagePrefix} failed. ${error?.message}`
  const isError = type === MESSAGE_TYPES.error
  const displayMessage = isError ? errorMessage : message || successMessage
  if (id) {
    updateExistingToast(id, displayMessage, { type, autoClose })
  } else {
    id = showInfo(displayMessage, { type })
  }

  if (error) {
    reportErrors(error)
  }

  return id
}

export const getToastId = ({
  status,
  entity,
  onlyToastOnErrors,
}: {
  status: string
  entity: string
  onlyToastOnErrors?: boolean
}): string | undefined =>
  onlyToastOnErrors
    ? undefined
    : showEntityCrudMessage({
        status,
        entity,
        type: MESSAGE_TYPES.info,
      })

export const showCrudResponseMessage = <T extends Partial<Entity>>({
  error,
  onlyToastOnErrors = false,
  ...rest
}: ShowEntityCrudMessage<T> & { onlyToastOnErrors?: boolean }): void => {
  const commonProps = {
    ...rest,
    error,
  }
  if (error) {
    showEntityCrudMessage<T>({
      ...commonProps,
      type: MESSAGE_TYPES.error,
    })
    return
  }

  if (!onlyToastOnErrors) {
    showEntityCrudMessage<T>({
      ...commonProps,
      type: MESSAGE_TYPES.success,
    })
  }
}

export const clearAllMessages = (): void => toast.dismiss()

export const displayAndLogErrorMessage = (newMessage: string): void => {
  showError(newMessage)
  reportMessage(newMessage)
}
