// libraries
import { ReactElement, ChangeEvent, FocusEventHandler } from 'react'
import { WidgetProps } from '@rjsf/utils'

// components
import WidgetWrapper from 'components/common/JsonForm/WidgetWrapper'

import TextWidgetAddonIcon from './TextWidgetAddonIcon'
import { StyledInput } from './style'

const TextWidget = ({
  id,
  name,
  className,
  placeholder,
  required,
  autofocus,
  readonly,
  disabled,
  type,
  label,
  value,
  onChange,
  onBlur,
  onFocus,
  options,
  schema,
  uiSchema,
  registry,
  rawErrors,
  formContext: { isPreview },
}: WidgetProps): ReactElement => {
  const handleChange = ({
    target: { value: newValue },
  }: ChangeEvent<HTMLInputElement>) => {
    onChange(newValue === '' ? options.emptyValue : newValue)
  }

  const handleBlur: FocusEventHandler<HTMLInputElement> = ({
    target: { value: newValue },
  }) => onBlur(id, newValue)

  const handleFocus: FocusEventHandler<HTMLInputElement> = ({
    target: { value: newValue },
  }) => onFocus(id, newValue)

  const { schemaUtils } = registry
  const displayLabel =
    schemaUtils.getDisplayLabel(schema, uiSchema) && (!!label || !!schema.title)

  const inputType =
    (type || schema.type) === 'string' ? 'text' : `${type || schema.type}`

  return (
    <WidgetWrapper
      label={displayLabel ? label || schema.title : undefined}
      description={schema.description}
      required={required}
      name={name}
      rawErrors={rawErrors}
      isLarge={isPreview}
    >
      <StyledInput
        id={id}
        name={name}
        value={value || value === 0 ? value : ''}
        type={inputType}
        className={`form-control ${className}`}
        isLarge={isPreview}
        placeholder={placeholder || schema.placeholder}
        readOnly={readonly}
        required={required}
        disabled={disabled}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autofocus}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      <TextWidgetAddonIcon schema={schema} uiSchema={uiSchema} />
    </WidgetWrapper>
  )
}

export default TextWidget
