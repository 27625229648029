// libraries
import { useMemo, lazy } from 'react'

// utils
import { useRoutesAbility } from 'hooks'
import { lazyWithRetry } from 'routers/utils'
import { isDevEnvironment } from 'helpers/utils'

import type { NavLinkSpec, SuRoute } from 'types/route'

// constants
import { URLS } from 'constants/route'
import { HOME_PAGE_URL } from 'constants/common'
import {
  devRoutes,
  getUserRoutes,
  publicRoutes,
  siteRoutes,
} from 'routers/routes/useRoutes'

import { APP_URLS } from './constant'

const MethaneDashboard = lazy(() =>
  lazyWithRetry(() => import('app/MissionControlMethaneSolution/Dashboard'))
)

const isDevEnv = isDevEnvironment()

export const methaneRoutes = [
  {
    path: APP_URLS.METHANE_DASHBOARD,
    component: MethaneDashboard,
  },
]

export const useRoutes = (): {
  routes: SuRoute[]
  navLinkSpecs: NavLinkSpec[]
} => {
  const { canManageUser } = useRoutesAbility()

  const routes = useMemo(() => {
    return [
      {
        path: HOME_PAGE_URL,
        component: MethaneDashboard,
      },
      ...methaneRoutes,
      ...getUserRoutes(canManageUser),
      ...siteRoutes,
      ...publicRoutes,
      ...(isDevEnv ? devRoutes : []),
    ]
  }, [canManageUser])

  const navLinkSpecs = useMemo(
    () => [
      {
        name: 'Methane',
        path: APP_URLS.METHANE_DASHBOARD,
        icon: 'FaRoute',
      },
      {
        name: 'Sites',
        path: URLS.SITES,
        icon: 'BiBuildings',
      },
      {
        name: 'Users',
        path: URLS.USERS,
        icon: 'FaUsersCog',
        enabled: canManageUser,
      },
      {
        name: 'UI',
        path: URLS.DEV_UI_PLAYGROUND,
        icon: 'AiFillAppstore',
        enabled: isDevEnv,
      },
    ],
    [canManageUser]
  )

  return { routes, navLinkSpecs }
}
