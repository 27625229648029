import keymirror from 'keymirror'

export const APP_URLS = {
  METHANE_DASHBOARD: '/dashboard',
}

export const DASHBOARD_TABS = keymirror({ emissions: null })

export const EMISSIONS_RATE_PROPERTY_NAME = 'emissions_rate'

export const TOTAL_CO2E_NAME = 'cv_tco2_e'
