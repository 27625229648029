import { useState, CSSProperties } from 'react'
import { useMount } from 'react-use'
import { useTheme } from '@emotion/react'

import {
  SLIDER_HANDLE_DIAMETER_LARGE,
  SLIDER_HANDLE_DIAMETER_REGULAR,
  SLIDER_RAIL_COLOR,
} from 'constants/ui'

const useRangeSliderStyle = (
  type: string,
  isLarge?: boolean
): CSSProperties => {
  const theme = useTheme()

  const [style, setStyle] = useState()

  useMount(() => {
    const size = isLarge
      ? SLIDER_HANDLE_DIAMETER_LARGE
      : SLIDER_HANDLE_DIAMETER_REGULAR

    const commonStyle = {
      handleStyle: {
        height: size,
        width: size,
        marginTop: `-${isLarge ? 14 : 8}px`,
        borderWidth: isLarge ? 3 : 2,
      },
      railStyle: {
        backgroundColor: SLIDER_RAIL_COLOR,
        height: 2,
        borderRadius: 0,
      },
    }
    const bgColour = {
      backgroundColor: theme.primary,
      height: 2,
      borderRadius: 0,
    }
    const styleOverride = {
      ...commonStyle,
      trackStyle: type === 'slider' ? bgColour : [bgColour, bgColour],
    }
    setStyle(styleOverride)
  })

  return style
}

export default useRangeSliderStyle
