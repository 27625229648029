import _ from 'lodash'
import { APPLICATION_NAME, ENTITIES } from 'constants/common'

// utils
import { AUDITS_FIELDS } from 'constants/graphql'
import { getArgs, getQueryFields } from 'helpers/graphql'
import {
  getGraphql,
  getEntityGraphql,
  listEntitiesGraphql,
  mutateEntity,
  getEntityQuery,
  getEntitiesQuery,
  MutateEntity,
} from 'services/api/utils'

import type { User, UserConsent } from 'types/user'

const APPLICATION_PAYLOAD = { application: APPLICATION_NAME }

const domain = ENTITIES.user
const queryDomain = `${domain}s`

const USER_CONSENT_FIELDS = {
  subject: true,
  version: true,
}

const getUserFields = getQueryFields({
  id: true,
  username: true,
  enabled: true,
  group: true,
  type: true,
  role: true,
  email: true,
  phone: true,
  timezone: true,
  emailVerified: true,
  caslRules: true,
  familyName: true,
  givenName: true,
  name: true,
  initials: true,
  avatarUrl: true,
  preferences: {
    preference: true,
    value: true,
  },
  hasConsented: {
    __aliasFor: 'hasConsent',
    ...getArgs(APPLICATION_PAYLOAD),
  },
  consents: USER_CONSENT_FIELDS,
  mfaRequired: true,
  mfaPreferred: true,
  devices: {
    deviceKey: true,
    name: true,
    remembered: true,
    createdAt: true,
    modifiedAt: true,
    lastSignedInAt: true,
    lastIpAddress: true,
  },
  hasLoginCredentials: true,
  ...AUDITS_FIELDS,
})

const getUserByIdQuery = getEntityQuery({
  queryDomain,
  getFieldsFn: getUserFields,
})

const getUsersQuery = getEntitiesQuery({
  queryDomain,
  getFieldsFn: getUserFields,
})

export const deserializeUser = (
  user: Omit<User, 'preferences'> & {
    preferences: { preference: string; value: unknown }[]
  }
): User => {
  const { preferences, ...rest } = user || {}

  return {
    ...rest,
    preferences: _.reduce(
      preferences,
      (acc, { preference, value }) => {
        return { ...acc, [preference]: value }
      },
      {}
    ),
  }
}

const mutateUser = (props: Omit<MutateEntity, 'queryDomain'>) =>
  mutateEntity<User>({
    queryDomain,
    responsePath: [domain],
    responseFields: {
      [domain]: getUserFields(),
    },
    identifier: 'username',
    postProcessFn: deserializeUser,
    ...props,
  })

export const getUser = getEntityGraphql({
  queryDomain,
  getQueryFn: getUserByIdQuery,
  queryDisplayName: 'GetUserById',
  postProcessFn: deserializeUser,
  pickFields: 'avatarUrl',
})

export const getCurrentUser = getGraphql({
  getQueryFn: getEntityQuery({
    identifier: null,
    queryName: 'me',
    getFieldsFn: getUserFields,
  }),
  fnName: 'me',
  queryDisplayName: 'getCurrentUser',
  ignoreError: true,
  postProcessFn: deserializeUser,
})

export const updateCurrentUser = mutateUser({
  fnName: 'updateUser',
  variableFormat: 'UpdateUserInput!',
})

const DEFAULT_NON_CURRENT_USER_OMIT_FIELDS = ['caslRules', 'devices']

export const listUsers = listEntitiesGraphql<User>({
  queryDomain,
  getQueryFn: getUsersQuery,
  queryDisplayName: 'GetAllUsers',
  defaultOmitFields: DEFAULT_NON_CURRENT_USER_OMIT_FIELDS,
  postProcessFn: deserializeUser,
})

export const createUser = mutateUser({
  fnName: 'createUser',
  responseFields: {
    [domain]: getUserFields({ omitFields: ['hasConsent', 'consents'] }),
  },
  variableFormat: 'CreateUserInput!',
})

export const updateUser = mutateUser({
  fnName: 'updateUser',
  variableFormat: 'UpdateUserInput!',
  responseFields: {
    [domain]: getUserFields({
      omitFields: DEFAULT_NON_CURRENT_USER_OMIT_FIELDS,
    }),
  },
})

export const updateUserPreference = mutateUser({
  fnName: 'updateUserPreference',
  variableFormat: 'UpdateUserPreferenceInput!',
})

export const deleteUser = mutateUser({
  fnName: 'deleteUser',
  argsKey: null,
  responseFields: {
    [domain]: getUserFields({ pickFields: ['username'] }),
  },
  isDelete: true,
  postProcessFn: null,
})

export const getCurrentUserConsentDocuments = (): Promise<UserConsent[]> => {
  const variables = APPLICATION_PAYLOAD
  const fnName = 'userConsentDocuments'
  return getGraphql({
    getQueryFn: () => ({
      [fnName]: {
        subject: true,
        contentUrl: true,
        version: true,
        description: true,
        needMeConsent: getArgs(variables),
      },
    }),
    fnName,
    queryDisplayName: 'GetCurrentUserConsentDocuments',
  })(variables)
}

export const confirmCurrentUserConsent = async (
  subjects: string[]
): Promise<{ error?: string; user: User | null }> => {
  const args = { ...APPLICATION_PAYLOAD, subjects }
  return mutateEntity({
    queryDomain,
    fnName: 'updateUserMeConsent',
    withIdentifier: false,
    ignoreError: true,
    responseFields: {
      [domain]: getUserFields(),
    },
    variableFormat: 'UpdateUserConsentInput!',
  })(null, args)
}
