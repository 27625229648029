// libraries
import { Suspense, ReactElement, lazy, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import _ from 'lodash'

// utils
import { usePlugins, useSanitizedLocation } from 'hooks'
import { lazyWithRetry } from 'routers/utils'
import { logEvent, ANALYTICS_EVENTS } from 'helpers/firebase'

// components
import { Loading } from 'components/common'
import ProtectedElement from 'routers/ProtectedElement'

import type { NavLinkSpec, SuRoute } from 'types/route'

const SidebarRoutePlugin = lazy(() =>
  lazyWithRetry(() => import('routers/pages/SidebarRoutePlugin'))
)

type SuRoutesProps = {
  routes: SuRoute[]
  navLinkSpecs: NavLinkSpec[]
}

export const SuRoutes = ({
  routes,
  navLinkSpecs,
}: SuRoutesProps): ReactElement => {
  const { pathname, search: searchParams } = useSanitizedLocation()
  // On route change, send analytics
  useEffect(() => {
    logEvent(ANALYTICS_EVENTS.page_view, { pathname, searchParams })
  }, [pathname, searchParams])

  const pluginState = usePlugins()

  return (
    <Routes>
      {_.map(pluginState.sidebarRoute, (_route, idx) => {
        return (
          <Route
            path={pluginState.sidebarRoute[idx].normalizedRoutePath}
            key={pluginState.sidebarRoute[idx].normalizedRoutePath}
            element={
              <ProtectedElement
                component={SidebarRoutePlugin}
                componentProps={{ idx }}
                layoutOptions={{ navLinkSpecs }}
              />
            }
          />
        )
      })}
      {_.map(routes, ({ path, element, layoutOptions, ...rest }) => {
        return (
          <Route
            key={path}
            path={path}
            element={
              element || (
                <ProtectedElement
                  {...rest}
                  layoutOptions={{ ...(layoutOptions || {}), navLinkSpecs }}
                />
              )
            }
          />
        )
      })}
    </Routes>
  )
}

const SuRoutesContainer = (props: SuRoutesProps): ReactElement => {
  return (
    <Suspense fallback={<Loading />}>
      <BrowserRouter>
        <SuRoutes {...props} />
      </BrowserRouter>
    </Suspense>
  )
}

export default SuRoutesContainer
