// libraries
import {
  useRef,
  useCallback,
  ReactNode,
  ReactElement,
  MutableRefObject,
} from 'react'
import DeckGL from '@deck.gl/react/typed'
import {
  StaticMap,
  _MapContext as MapContext,
  NavigationControl,
  FullscreenControl,
} from 'react-map-gl'
import Geocoder from 'react-map-gl-geocoder'
import mapboxgl from 'mapbox-gl'

// constants
import { MAP_STYLE_TYPES, MAPBOX_LIMITS } from 'constants/map'

// utils
import { useBranding } from 'hooks'
import { reportException } from 'helpers/log'
import { coordinatesGeocoder } from 'helpers/geojson'

import type { Map as MapType, MapLayer, Viewport } from 'types/map'

// style
import './index.scss'

mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved, @typescript-eslint/no-var-requires
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

const mapControlsDefaultStyle = {
  position: 'absolute',
  bottom: '50px',
  left: '25px',
}

export type MapProps = {
  map: MapType
  mapRef?: MutableRefObject<HTMLElement | undefined>
  mapCanvasRef?: MutableRefObject<HTMLElement | undefined>
  layersList?: []
  viewState?: Viewport
  onViewStateChange?: (v: { viewState: Viewport }) => void
  children?: ReactNode
  className?: string
  enableGeocoder?: boolean
  getCursor?: () => void
  getTooltip?: () => void
}

/**
 *
 * @param props.map - map settings
 * @param props.mapRef - ref for deckgl
 * @param props.mapCanvasRef - ref for container
 * @param props.layersList - deckgl layers specification
 * @param props.viewState - viewState
 * @param props.onViewStateChange - on view change
 * @param props.className - container class name
 * @param props.enableGeocoder - enable geocoder or not
 * @param props.getCursor - A custom callback to retrieve the cursor type.
 */

const Map = ({
  map,
  mapRef,
  mapCanvasRef,
  layersList,
  viewState,
  onViewStateChange,
  children,
  className,
  enableGeocoder,
  ...rest
}: MapProps): ReactElement => {
  const { mapboxAccessToken } = useBranding()
  const geocoderContainerRef = useRef()

  const { id: mapId, style } = map || {}

  const staticMapRef = useRef()

  const handleGeocoderViewportChange = useCallback(
    (newViewport: Viewport) => {
      const geocoderDefaultOverrides = { transitionDuration: 1000 }
      if (!onViewStateChange) return undefined

      return onViewStateChange({
        viewState: {
          ...newViewport,
          ...geocoderDefaultOverrides,
        },
      })
    },
    [onViewStateChange]
  )

  const onDeckGlError = useCallback((error: Error, source: MapLayer) => {
    reportException(error, { source })
  }, [])

  return (
    <div className={`map ${className}`} ref={mapCanvasRef}>
      {enableGeocoder && (
        <div
          ref={geocoderContainerRef}
          style={{
            height: 50,
            display: 'flex',
            alignItems: 'center',
            paddingLeft: 4,
            zIndex: 1,
          }}
        />
      )}

      <DeckGL
        {...rest}
        ref={mapRef}
        onViewStateChange={onViewStateChange}
        viewState={{
          ...viewState,
          ...MAPBOX_LIMITS,
        }}
        controller
        layers={layersList}
        glOptions={{ preserveDrawingBuffer: true }}
        ContextProvider={MapContext.Provider}
        onError={onDeckGlError}
      >
        <StaticMap
          key={mapId}
          ref={staticMapRef}
          reuseMaps
          mapStyle={style || MAP_STYLE_TYPES.dark}
          mapboxApiAccessToken={mapboxAccessToken}
          preserveDrawingBuffer
          preventStyleDiffing
        >
          {enableGeocoder && (
            <Geocoder
              mapRef={staticMapRef}
              containerRef={geocoderContainerRef}
              onViewportChange={handleGeocoderViewportChange}
              mapboxApiAccessToken={mapboxAccessToken}
              localGeocoder={coordinatesGeocoder}
              position='top-left'
            />
          )}
        </StaticMap>
        <NavigationControl style={mapControlsDefaultStyle} />
        <FullscreenControl
          style={{ ...mapControlsDefaultStyle, bottom: '10px' }}
          container={document.querySelector('#mapContent')}
        />
        <img
          src='/assets/logo/sensorup-logo-icon-light.svg'
          className='mapLogo'
          alt='SensorUp Logo'
          width='1'
          height='1'
        />
      </DeckGL>
      {children}
    </div>
  )
}

Map.defaultProps = {
  layersList: [],
  viewState: {},
  onViewStateChange: undefined,
  mapRef: undefined,
  mapCanvasRef: undefined,
  enableGeocoder: false,
  children: undefined,
}

export default Map
