import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useStateValue } from 'contexts'
import { useUnmount } from 'react-use'
import isEqual from 'fast-deep-equal'
import { displayTime } from 'helpers/utils'

import { useTimezone } from 'hooks'

const UnipipeToast = () => {
  const {
    selectors: {
      unipipeSelectors: { latestToast },
    },
  } = useStateValue()
  const { timezone } = useTimezone()

  const [toastState, setToastState] = useState()
  const [toastId, setToastId] = useState()

  useUnmount(() => {
    toast.dismiss()
  })

  useEffect(() => {
    if (!isEqual(toastState, latestToast)) {
      setToastState(latestToast)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestToast])

  useEffect(() => {
    if (!toastState) return

    const { render, type, timestamp } = toastState
    if (!render) return

    const time = displayTime({
      datetime: timestamp,
      timezone,
      addFromNow: false,
    })
    if (toastId && toast.isActive(toastId)) {
      toast.update(toastId, latestToast)
    } else {
      setToastId(
        toast(`${time}: ${render}`, {
          ...(type === toast.TYPE.ERROR ? { autoClose: false } : {}),
          type,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toastState])
  return <></>
}

export default UnipipeToast
