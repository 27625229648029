import {
  PropsWithChildren,
  ReactElement,
  useCallback,
  MouseEvent,
  useMemo,
  Fragment,
} from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import _ from 'lodash'

// utility
import { stopEventDefaultAndPropagation } from 'helpers/utils'

import type { ToggleFn, Value } from 'types/common'
import type { DropdownOption, DropdownOptions, GroupedDropdownOption } from '..'

import scss from './index.module.scss'

const LIST_BORDER_RADIUS = 6

const StyledItem = styled.li<{
  isLightTheme?: boolean
  isSelected?: boolean
}>`
  ${({ isSelected, theme }) =>
    isSelected
      ? css`
          color: ${theme.primary};
          font-weight: 700;
        `
      : ''};

  &:first-of-type {
    border-top-left-radius: ${LIST_BORDER_RADIUS}px;
    border-top-right-radius: ${LIST_BORDER_RADIUS}px;
  }
  &:last-child {
    border-bottom-left-radius: ${LIST_BORDER_RADIUS}px;
    border-bottom-right-radius: ${LIST_BORDER_RADIUS}px;
  }
  &:hover {
    background-color: ${props =>
      props.isLightTheme ? props.theme['secondary-light-500'] : '#495057'};
  }
`

export const isGroupedOption = (option: GroupedDropdownOption): boolean =>
  _.isObject(option) && _.has(option, 'label') && _.has(option, 'options')

export const isGroupedOptions = (options: DropdownOptions): boolean =>
  _.some(options, isGroupedOption)

export const transformGroupedOptions = (
  options: DropdownOptions
): DropdownOptions => {
  if (!isGroupedOptions(options)) return options

  const groupedOptions = _.filter(
    options,
    isGroupedOption
  ) as GroupedDropdownOption[]
  const nonGroupedOptions = _.reject(options, isGroupedOption)

  return _.isEmpty(nonGroupedOptions)
    ? groupedOptions
    : _.concat(groupedOptions, [
        { label: 'Untitled', options: nonGroupedOptions },
      ])
}

const OptionList = ({
  options,
  isLightTheme,
  onClickHandler,
  selectedValue,
}: {
  options: DropdownOption[]
  isLightTheme?: boolean
  onClickHandler: (
    event: MouseEvent<HTMLLIElement>,
    value?: Value,
    onClick?: DropdownOption['onClick']
  ) => void
  selectedValue?: string
}): ReactElement => (
  <>
    {_(options)
      .reject('disabled')
      .map(({ label, key, value, onClick, dataTestid }: DropdownOption) => {
        const optionKey = key || `${value}`
        const isSelected = value === selectedValue
        return (
          <StyledItem
            key={optionKey}
            className={scss.item}
            isSelected={isSelected}
            isLightTheme={isLightTheme}
            onClick={event => onClickHandler(event, value, onClick)}
            data-testid={dataTestid || `dropdown-options-${optionKey}`}
          >
            {label || value}
          </StyledItem>
        )
      })
      .value()}
  </>
)

const DropdownBody = ({
  selectedValue,
  className,
  onChange,
  toggleListVisible,
  isLightTheme,
  options: originalOptions,
}: PropsWithChildren<{
  selectedValue?: string
  className?: string
  onChange?: (v?: Value) => void
  toggleListVisible: ToggleFn
  isLightTheme?: boolean
  options: DropdownOptions
}>): ReactElement => {
  const onClickHandler = useCallback(
    (
      event: MouseEvent<HTMLLIElement>,
      value?: Value,
      onClick?: DropdownOption['onClick']
    ) => {
      stopEventDefaultAndPropagation(event)
      if (_.isFunction(onClick)) {
        onClick()
      } else if (onChange) {
        onChange(value)
      }
      toggleListVisible(false)
    },
    [onChange, toggleListVisible]
  )

  const optionListProps = {
    isLightTheme,
    onClickHandler,
    selectedValue,
  }

  const options = useMemo(
    () => transformGroupedOptions(originalOptions),
    [originalOptions]
  )

  return (
    <ul className={`${scss.optionsList} ${className}`}>
      {isGroupedOptions(options) ? (
        <>
          {_.map(options, (group: GroupedDropdownOption) => {
            const { label, options: subGroupOptions } = group
            return (
              <Fragment key={label}>
                <li className={scss.groupTitle}>{label}</li>
                <OptionList {...optionListProps} options={subGroupOptions} />
              </Fragment>
            )
          })}
        </>
      ) : (
        <OptionList {...optionListProps} options={options} />
      )}
    </ul>
  )
}

export default DropdownBody
