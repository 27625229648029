// libraries
import { useState, useMemo, useEffect, useCallback, ReactElement } from 'react'
import _ from 'lodash'
import { FaCircle } from 'react-icons/fa'

// constants
import { DEFAULT_USER, UNASSIGNED_USERNAME } from 'constants/user'
import { DATE_FORMAT, DATE_HOUR_MINUTE_FORMAT } from 'constants/datetime'
import { THEMES } from 'constants/colour'
import { TOOLTIP_PLACEMENT } from 'constants/settings'

// utils
import { useCurrentUser, useTimezone } from 'hooks'
import { displayTime, findOption, sanitizeString } from 'helpers/utils'
import { getIssueStatusOption } from 'helpers/issue'
import { getNowISOString } from 'helpers/datetime'

// components
import { SmallAvatar, IssueAssigneesPicker, Tooltip } from 'components/common'
import { DateTimePicker } from 'components/common/DateTime'

import IssueTextEditor from 'components/issue/IssueItemDetail/IssueTextEditor'
import IssueSeverityPicker from 'components/issue/IssueItemDetail/IssueSeverityPicker'
import { MdChatBubbleOutline } from 'components/icons'
import { CheckboxIcon } from 'svg'

import type { IssueGeojson, AssigneeOption } from 'types/issue'
import type { ThemeType, UtcISOString } from 'types/common'

import { DateTimeValue } from 'components/common/DateTime/DateTimePicker'
import { Timezone } from 'types/datetime'
import scss from './index.module.scss'

const DIV_CONTENT_CLASSNAMES = 'mt-1 d-flex justify-content-between'

type IssueState = {
  severity: string
  assignee: AssigneeOption
  description: string
  title: string
  dueAt: string
}

const getIssueState = (issue: IssueGeojson): IssueState => {
  const { assigneeOption, severityOption } = issue || {}
  const { value: severityValue } = severityOption || {}

  return {
    severity: severityValue,
    assignee: assigneeOption,
    description: _.get(issue, 'properties.description'),
    title: _.get(issue, 'properties.title'),
    dueAt: _.get(issue, 'properties.dueAt'),
  }
}

export const IssueStatusLabel = ({
  status,
  issueType,
  dataCollectionFormComplete,
}: {
  status: string
  issueType?: string
  dataCollectionFormComplete?: boolean
}): ReactElement => {
  const statusOption = useMemo(
    () => getIssueStatusOption(status, issueType, dataCollectionFormComplete),
    [dataCollectionFormComplete, issueType, status]
  )
  const { label, colour } = statusOption || {}
  const style = useMemo(() => {
    return {
      textTransform: 'uppercase',
      color: colour,
    }
  }, [colour])

  return <div style={style}>{label}</div>
}

const IssueDueDatePicker = ({
  dueAt,
  ...rest
}: {
  dueAt: string
  timezone: Timezone
  onChange: ({ value, type }: DateTimeValue) => void
}) => {
  const minDate = useMemo(() => getNowISOString(), [])
  return (
    <>
      <div className={scss.anchor}>
        <DateTimePicker
          {...rest}
          isDisabled={false}
          timeFormat={DATE_HOUR_MINUTE_FORMAT}
          addFromNow={false}
          displayLabel={false}
          selectedTime={dueAt}
          className={scss.timePickerCustom}
          minDate={minDate}
          showTimeSelect={false}
        />
      </div>
    </>
  )
}

const IssueOverview = ({
  issue,
  actions = {},
  expanded = false,
  theme = THEMES.light,
}: {
  issue: IssueGeojson
  actions: { updateIssue: () => void; assignIssueToUser: () => Promise<void> }
  expanded: boolean
  theme: ThemeType
}): ReactElement => {
  const isLightTheme = useMemo(() => theme === THEMES.light, [theme])
  const { updateIssue = _.noop, assignIssueToUser = _.noop } = actions || {}
  const { timezone } = useTimezone()
  const { issueSeverityOptions, issueAssigneesOptions } = useCurrentUser()
  const {
    id: issueId,
    assigneeOption,
    annotations,
    properties: { time, asset, acknowledged, acknowledgedAt, status, pastDue },
    severityOption: { colour: severityColour },
  } = issue

  const [issueState, setIssueState] = useState<IssueState>(() =>
    getIssueState(issue)
  )

  useEffect(() => {
    setIssueState(oldIssue => ({ ...oldIssue, ...getIssueState(issue) }))
  }, [issue])

  const { title, severity, assignee, description, dueAt } = issueState

  const displayedDueAtTime = useMemo(
    () =>
      dueAt
        ? displayTime({
            datetime: dueAt,
            timezone,
            addFromNow: false,
            timeFormat: DATE_FORMAT,
          })
        : 'none',
    [dueAt, timezone]
  )

  const displayedTime = useMemo(
    () => displayTime({ datetime: time, timezone, addFromNow: false }),
    [time, timezone]
  )

  const displayedAcknowledgedAtTime = useMemo(
    () =>
      acknowledgedAt
        ? displayTime({ datetime: acknowledgedAt, timezone, addFromNow: false })
        : 'none',
    [acknowledgedAt, timezone]
  )

  const annotationsCounts = useMemo(() => {
    return _.reject(annotations, { deleted: true }).length
  }, [annotations])

  const onAssigneeChange = useCallback(
    async option => {
      setIssueState(oldIssueState => ({ ...oldIssueState, assignee: option }))
      const error = await assignIssueToUser({
        issueId,
        username: option?.value || '',
      })

      if (error) {
        setIssueState(oldIssueState => ({ ...oldIssueState, assignee }))
      }
    },
    [assignIssueToUser, assignee, issueId, setIssueState]
  )

  const getAvatarSharedProps = useCallback(
    style => {
      if (!acknowledged) return {}

      const checkbox = <CheckboxIcon width={16} height={16} />

      const overlay = (
        <div className={scss.checkbox} style={style}>
          {acknowledgedAt ? (
            <Tooltip
              placement={TOOLTIP_PLACEMENT.bottom}
              trigger={['hover']}
              overlay={
                <span>
                  {`Acknowledged on
                ${displayedAcknowledgedAtTime}`}
                </span>
              }
            >
              {checkbox}
            </Tooltip>
          ) : (
            checkbox
          )}
        </div>
      )

      return { overlay }
    },
    [acknowledged, acknowledgedAt, displayedAcknowledgedAtTime]
  )

  const assigneesPickerProps = useMemo(() => {
    return {
      ...getAvatarSharedProps({ bottom: '-2px' }),
      value: assignee?.value,
      onChange: onAssigneeChange,
    }
  }, [assignee, getAvatarSharedProps, onAssigneeChange])

  const avatarProps = useMemo(() => {
    const user =
      findOption(issueAssigneesOptions, 'username', assigneeOption?.id) ||
      DEFAULT_USER

    return {
      ...getAvatarSharedProps(),
      user,
      useDefaultAvatar: _.isEmpty(assigneeOption),
    }
  }, [assigneeOption, getAvatarSharedProps, issueAssigneesOptions])

  const onIssueChange = useCallback(
    key => value => {
      if (issueState[key] === value) return
      const payload = { [key]: value }

      setIssueState(oldIssueState => ({ ...oldIssueState, ...payload }))
      updateIssue({ ...payload, issueId })
    },
    [issueId, issueState, setIssueState, updateIssue]
  )

  const sanitizedDescription = useMemo(
    () => sanitizeString(description),
    [description]
  )

  const renderIssueCardEdit = useCallback(() => {
    return (
      <>
        <div
          style={{ color: isLightTheme ? '#17283ebe' : '#FFF' }}
          className={`${DIV_CONTENT_CLASSNAMES}`}
        >
          {asset}
        </div>
        <div className={`${scss.title} `}>
          <IssueTextEditor
            onChange={onIssueChange('title')}
            value={title}
            className={scss.titleInput}
          />
        </div>
        <div
          style={{ color: isLightTheme ? 'rgba(0, 0, 0, 0.5)' : '#FFF' }}
          className={`${scss.description}`}
        >
          <IssueTextEditor
            value={sanitizedDescription}
            onChange={onIssueChange('description')}
            placeholder='Add description...'
            className={scss.descriptionInput}
            field='textarea'
          />
        </div>
        <div
          className={`d-flex justify-content-between align-items-center ${scss.row}`}
        >
          <div>
            <span className='boldSmallText me-2'>Last updated: </span>
            <span
              style={{
                color: isLightTheme ? 'rgba(0, 0, 0, 0.5)' : '#FFF',
                fontSize: '14px',
              }}
            >
              {displayedTime}
            </span>
          </div>
          <div data-testid='issue-severity' className={scss.severityEdit}>
            <IssueSeverityPicker
              value={severity}
              options={issueSeverityOptions}
              onChange={option => onIssueChange('severity')(option?.value)}
            />
          </div>
        </div>
        <div className={`d-flex align-items-center ${scss.row}`}>
          <div className='boldSmallText me-2'>Assignee: </div>
          <IssueAssigneesPicker {...assigneesPickerProps} />
        </div>
        <div className={`d-flex  align-items-center ${scss.row}`}>
          <div className='boldSmallText me-2'>Due at: </div>
          <IssueDueDatePicker
            dueAt={dueAt}
            timezone={timezone}
            onChange={({ value }: { value: UtcISOString | null }) =>
              onIssueChange('dueAt')(value)
            }
          />
        </div>
      </>
    )
  }, [
    asset,
    assigneesPickerProps,
    displayedTime,
    dueAt,
    isLightTheme,
    issueSeverityOptions,
    onIssueChange,
    sanitizedDescription,
    severity,
    timezone,
    title,
  ])

  const renderIssueCardView = useCallback(() => {
    return (
      <>
        <div
          style={{ color: isLightTheme ? '#17283ebe' : '#FFF' }}
          className={`${DIV_CONTENT_CLASSNAMES}`}
        >
          <div>
            <FaCircle color={severityColour} size={8} className='me-2' />
            {asset}
          </div>
          <IssueStatusLabel status={status} />
        </div>
        <div className={`${scss.title} ${DIV_CONTENT_CLASSNAMES}`}>
          <div className={`singleLineTruncate ${scss.titleDisplay}`}>
            {title}
          </div>
          <div className='extraSmallText'>
            <span className={`${pastDue ? scss.pastDueDisplay : ''}`}>
              {`${pastDue ? 'Past' : ''} Due Date: `}
            </span>
            {displayedDueAtTime}
          </div>
        </div>
        <div>{sanitizedDescription}</div>
        <div className={`${DIV_CONTENT_CLASSNAMES}`}>
          <div className='d-flex align-items-center'>
            <SmallAvatar {...avatarProps} />
            <span className='ms-1'>
              {assignee?.label || UNASSIGNED_USERNAME}
            </span>
          </div>
          <div>
            {annotationsCounts > 0 && (
              <>
                <MdChatBubbleOutline className='me-1' />
                {annotationsCounts}
              </>
            )}
          </div>
        </div>
      </>
    )
  }, [
    annotationsCounts,
    asset,
    assignee?.label,
    avatarProps,
    displayedDueAtTime,
    isLightTheme,
    pastDue,
    sanitizedDescription,
    severityColour,
    status,
    title,
  ])

  return (
    <div
      style={{ color: isLightTheme ? '#17283e' : '#fff' }}
      className='smallText'
    >
      {expanded ? renderIssueCardEdit() : renderIssueCardView()}
    </div>
  )
}

export default IssueOverview
