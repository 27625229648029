import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useToggle } from 'react-use'
import _ from 'lodash'

// components
import { TextInput, MultiSelect, IconButton } from 'components/common'

import scss from './index.module.scss'

const DEFAULT_ALL_OPTION = { label: 'All', value: 'all' }

const SearchBar = ({
  filter,
  onChange,
  onClear,
  className,
  searchBoxClassName,
  placeholder,
  borderRight,
  changeOnBlur,
  enableSearchFields,
  selectedFields,
  fieldsLoading,
  fieldsOptions,
}) => {
  const [isActive, toggleActive] = useToggle(false)
  const style = useMemo(() => {
    return {
      borderRight: borderRight ? '1px solid #eee' : 'none',
      backgroundColor: isActive ? '#f3f3f3' : 'transparent',
    }
  }, [borderRight, isActive])

  const options = useMemo(() => {
    return _.sortBy([...fieldsOptions, DEFAULT_ALL_OPTION], ['label'])
  }, [fieldsOptions])

  return (
    <div
      className={`${scss.search} ${className} d-flex align-items-center`}
      style={style}
    >
      <IconButton
        aria-label='active-search-icon'
        icon='MdSearch'
        size={16}
        onClick={() => toggleActive(!isActive)}
        data-testid='search-icon'
        className={scss.searchBtn}
      />
      {isActive && (
        <>
          {enableSearchFields && !_.isEmpty(fieldsOptions) && (
            <MultiSelect
              isLoading={fieldsLoading}
              value={_.first(selectedFields) || DEFAULT_ALL_OPTION.value}
              options={options}
              className={scss.select}
              onChange={option => {
                onChange({
                  value: filter,
                  selectedFields:
                    option?.value === DEFAULT_ALL_OPTION.value
                      ? []
                      : [option?.value],
                })
              }}
              isMulti={false}
              placeholder='All'
              bgColour='#f3f3f3'
              isClearable={false}
            />
          )}
          <TextInput
            value={filter}
            onChange={value => {
              onChange({ selectedFields, value })
            }}
            changeOnBlur={changeOnBlur}
            className={`form-control ${scss.searchBox} ${searchBoxClassName}`}
            placeholder={placeholder}
            autoFocus
            testId='search-input'
          />
          {(filter || !_.isEmpty(selectedFields)) && (
            <IconButton
              icon='MdClose'
              className={scss.clearIcon}
              onClick={onClear}
              aria-label='clear-search'
            />
          )}
        </>
      )}
    </div>
  )
}

SearchBar.propTypes = {
  filter: PropTypes.string,
  className: PropTypes.string,
  searchBoxClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  borderRight: PropTypes.bool,
  placeholder: PropTypes.string,
  changeOnBlur: PropTypes.bool,
  enableSearchFields: PropTypes.bool,
  selectedFields: PropTypes.arrayOf(PropTypes.string),
  fieldsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  onClear: PropTypes.func,
  fieldsLoading: PropTypes.bool,
}

SearchBar.defaultProps = {
  filter: '',
  searchBoxClassName: undefined,
  className: undefined,
  borderRight: false,
  placeholder: 'Search ...',
  changeOnBlur: false,
  enableSearchFields: false,
  selectedFields: [],
  fieldsOptions: [],
  onClear: _.noop,
  fieldsLoading: false,
}

export default SearchBar
