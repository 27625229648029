import keymirror from 'keymirror'

export const DEFAULT_SITE_NAME = 'Untitled site'

export const SITE_FEATURE_TYPES = keymirror({
  site: null,
  building: null,
  level: null,
  unit: null,
  pathway: null,
  beacon: null,
  location: null,
})

export const SITE_FEATURE_TYPE_COLOURS = {
  [SITE_FEATURE_TYPES.site]: { colour: '#1791EA', icon: 'FaMapMarkerAlt' },
  [SITE_FEATURE_TYPES.building]: {
    colour: '#7D4F50',
    icon: 'HiOutlineOfficeBuilding',
  },
  [SITE_FEATURE_TYPES.level]: { colour: '#FAD02C', icon: 'Gi3DStairs' },
  [SITE_FEATURE_TYPES.pathway]: { colour: '#43AA8B', icon: 'FaRoute' },
  [SITE_FEATURE_TYPES.unit]: { colour: '#462255', icon: 'SiGoogleclassroom' },
  [SITE_FEATURE_TYPES.beacon]: { colour: '#F3722C', icon: 'MdDeviceHub' },
  [SITE_FEATURE_TYPES.location]: { colour: '#EF476F', icon: 'MdAddLocation' },
}

const COMMON_CHILDREN_TYPES = [
  SITE_FEATURE_TYPES.beacon,
  SITE_FEATURE_TYPES.pathway,
]

export const SITE_FEATURE_CHILDREN_TYPES = {
  [SITE_FEATURE_TYPES.site]: [
    SITE_FEATURE_TYPES.location,
    SITE_FEATURE_TYPES.building,
    ...COMMON_CHILDREN_TYPES,
  ],
  [SITE_FEATURE_TYPES.building]: [
    SITE_FEATURE_TYPES.level,
    ...COMMON_CHILDREN_TYPES,
  ],
  [SITE_FEATURE_TYPES.level]: [
    SITE_FEATURE_TYPES.unit,
    ...COMMON_CHILDREN_TYPES,
  ],
  [SITE_FEATURE_TYPES.location]: [
    SITE_FEATURE_TYPES.location,
    SITE_FEATURE_TYPES.beacon,
  ],
  [SITE_FEATURE_TYPES.unit]: COMMON_CHILDREN_TYPES,
  [SITE_FEATURE_TYPES.pathway]: [SITE_FEATURE_TYPES.beacon],
}

export const DEFAULT_POLYGON_GEOMETRY = {
  geometry: {
    type: 'Polygon',
    coordinates: '',
  },
}

export const DEFAULT_POINT_GEOMETRY = {
  geometry: {
    type: 'Point',
  },
}

export const DEFAULT_LINE_GEOMETRY = {
  geometry: {
    type: 'LineString',
  },
}

export const DEFAULT_NEW_SITE = {
  ...DEFAULT_POLYGON_GEOMETRY,
  properties: {
    name: 'Untitled Site',
    category: 'unspecified',
  },
  active: false,
}

export const DEFAULT_NEW_LOCATION = {
  properties: {
    name: 'Untitled Site',
    category: 'unspecified',
  },
  active: false,
}

export const DEFAULT_SITE_FEATURES = {
  [SITE_FEATURE_TYPES.location]: {
    properties: { category: 'unspecified' },
  },
  [SITE_FEATURE_TYPES.building]: {
    ...DEFAULT_POLYGON_GEOMETRY,
    properties: { category: 'unspecified' },
  },
  [SITE_FEATURE_TYPES.level]: {
    ...DEFAULT_POLYGON_GEOMETRY,
    properties: { ordinal: 1, category: 'unspecified' },
  },
  [SITE_FEATURE_TYPES.unit]: {
    ...DEFAULT_POLYGON_GEOMETRY,
    properties: { category: 'room' },
  },
  [SITE_FEATURE_TYPES.pathway]: { ...DEFAULT_LINE_GEOMETRY },
  [SITE_FEATURE_TYPES.beacon]: {
    ...DEFAULT_POINT_GEOMETRY,
    properties: {
      macAddress: '00 00 00 00 00 00',
    },
  },
}

export const AUTO_UPDATE_SITE_DEBOUNCE_IN_MS = 10

export const LOCATION_MAX_DEPTH = 3

export const DEFAULT_SITE_THUMB_URL = '/assets/site.webp'

// https://github.dev/uber/nebula.gl/blob/81d4274a4ca86f1ca3cadea5b6e35d432a24be6e/docs/api-reference/layers/editable-geojson-layer.md#L109-L142
export const SITE_GEOMETRY_EDIT_TYPES = keymirror({
  rotated: null,
  addFeature: null,
  translated: null,
  finishMovePosition: null,
  scaled: null,
})
