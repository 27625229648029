import { useCallback } from 'react'
import _ from 'lodash'
import { DragEndEvent } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'

/**
 * Provides "onDragEnd" for DndContext.
 * Works well with simple lists.
 */
const useListHandleDragEnd = <T extends unknown>({
  items,
  updateItems,
}: {
  items: T[]
  updateItems: (list: T[]) => void
}): ((event: DragEndEvent) => void) => {
  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event
      if (over && active.id !== over.id) {
        const oldIndex = _.findIndex<unknown>(items, { id: active.id })
        const newIndex = _.findIndex<unknown>(items, { id: over.id })
        const sortedItems = arrayMove(items, oldIndex, newIndex)
        updateItems(sortedItems)
      }
    },
    [items, updateItems]
  )

  return handleDragEnd
}

export default useListHandleDragEnd
