import { ReactElement } from 'react'
import { IconType } from 'react-icons/lib'

import * as Icons from 'components/icons'

import { ButtonProps, ButtonIconPosition } from '..'
import { BUTTON_ICON_CLASS_NAME } from '../constants'
import { IconWrapper } from './style'

export type ButtonIconProps = Pick<
  ButtonProps,
  'iconPosition' | 'isIconFixed' | 'isLoading'
> & {
  // Making these props required
  icon: string
  iconSize: number
  iconPosition: ButtonIconPosition
  iconOnly?: boolean
}

const ButtonIcon = ({
  icon,
  iconSize,
  iconPosition,
  isIconFixed,
  isLoading,
  iconOnly,
}: ButtonIconProps): ReactElement => {
  const Icon = (Icons as Record<string, IconType>)[icon]

  return (
    <IconWrapper
      isIconFixed={isIconFixed}
      iconPosition={iconPosition}
      isLoading={isLoading}
      iconSize={iconSize}
      iconOnly={iconOnly}
      className={BUTTON_ICON_CLASS_NAME}
    >
      <Icon size={iconSize} width={iconSize} height={iconSize} />
    </IconWrapper>
  )
}

export default ButtonIcon
