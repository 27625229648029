import { ReactElement } from 'react'
import { IconType } from 'react-icons'

import type { BladeProps } from 'components/common/Blade'

import * as Icons from 'components/icons'
import { IconButton } from 'components/common'
import scss from './index.module.scss'

type Props = Pick<
  BladeProps,
  'title' | 'titleIconName' | 'titleIconSize' | 'actions' | 'onBack' | 'onClose'
> & { className?: string }

/**
 * @param props.title - panel title
 * @param props.titleIconName - icon name from 'components/icons'
 * @param props.titleIconSize - icon size
 * @param props.actions - list of props for <IconButton>
 * @param props.className - will be applied to a container
 * @param props.onBack - enables go-back arrow
 * @param props.onClose - enables x-close button
 */
const BladeHeader = ({
  title = '',
  titleIconName,
  titleIconSize = 20,
  actions,
  className,
  onBack,
  onClose,
}: Props): ReactElement => {
  const isClosable = !!onClose
  const displayDivider = !!actions?.length && isClosable

  const Icon = titleIconName
    ? (Icons as Record<string, IconType>)[titleIconName]
    : null

  return (
    <div className={`${scss.bladeHeader} ${className}`}>
      <div className={scss.container}>
        <div className={scss.titleContainer}>
          {onBack && (
            <IconButton
              icon='BackArrow'
              className={scss.backArrowBtn}
              onClick={onBack}
              testId='blade-go-back'
            />
          )}
          {Icon && (
            <div className={scss.titleIconContainer}>
              <Icon
                className={scss.titleIcon}
                size={titleIconSize}
                width={titleIconSize}
                height={titleIconSize}
                data-testid='blade-title-icon'
              />
            </div>
          )}
          {title && <div className={scss.title}>{title}</div>}
        </div>
        <div className={scss.actionsContainer}>
          {actions?.map(props => (
            <IconButton
              key={props.icon}
              className={`${scss.headerActionBtn} ${props.className}`}
              {...props}
            />
          ))}
          {isClosable && (
            <>
              {displayDivider && <div className={scss.divider} />}
              <IconButton
                icon='CloseIcon'
                className={scss.closeButton}
                onClick={onClose}
                testId='blade-close'
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default BladeHeader
