// libraries
import { useCallback } from 'react'
import to from 'await-to-js'

// utils
import { showCrudResponseMessage } from 'helpers/message'
import { useAuthStateValue } from 'contexts'

// api
import { updateUser, updateUserPreference } from 'services/api/user'

// constants
import { MESSAGE_STATUS } from 'constants/message'
import { ENTITIES } from 'constants/common'

const update =
  (updateFn, currentUser, setCurrentUser) =>
  async (payload, entity = ENTITIES.user) => {
    const onlyToastOnErrors = true
    const [error, newUser] = await to(updateFn(currentUser.username, payload))
    showCrudResponseMessage({
      error,
      status: MESSAGE_STATUS.updated,
      entity,
      onlyToastOnErrors,
      subject: newUser,
    })
    if (!error) {
      setCurrentUser(newUser)
    }
  }

const useCurrentUser = () => {
  const {
    currentUser,
    setCurrentUser,
    issueSeverityOptions,
    issueAssigneesOptions,
  } = useAuthStateValue()

  const updateCurrentUser = useCallback(
    (...props) => update(updateUser, currentUser, setCurrentUser)(...props),
    [currentUser, setCurrentUser]
  )
  const updateCurrentUserPreference = useCallback(
    (...props) => {
      return update(updateUserPreference, currentUser, setCurrentUser)(...props)
    },
    [currentUser, setCurrentUser]
  )

  return {
    currentUser,
    updateCurrentUser,
    updateCurrentUserPreference,
    issueSeverityOptions,
    issueAssigneesOptions,
  }
}

export default useCurrentUser
