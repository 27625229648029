// libraries
import { ReactElement, useMemo, useState, useEffect } from 'react'
import { useDropzone, Accept } from 'react-dropzone'
import _ from 'lodash'
import { saveAs } from 'file-saver'
import styled from '@emotion/styled'

// utils
import { getFileNameAndExt, getReadableBytes } from 'helpers/utils'
import { showError } from 'helpers/message'

// components
import { Button, IconButton } from 'components/common'
import { SpreadsheetIcon } from 'components/icons'
import { BUTTON_VARIANTS } from 'components/common/Button'
import scss from '../index.module.scss'

export type UploaderAccept = Accept

const focusedStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}
type StyledUploadedFilePreviewProps = {
  hasError?: boolean
}

const StyledUploadedFilePreview = styled.div<StyledUploadedFilePreviewProps>`
  padding: 8px;
  margin: 8px;
  background: #fafafa81;
  border: 1px solid
    ${props => (props.hasError ? props.theme.danger : '#eaeaea')};
  border-radius: 5px;
`

export type DownloadTemplate = { fileName: string; filePath: string }

const UploadFile = ({
  onChange,
  files = [],
  disabled,
  errorMessage,
  isSingleFile = true,
  accept,
  acceptFileName,
  template,
  uploadMessage,
}: {
  onChange: (v: File[]) => void
  files?: File[]
  disabled: boolean
  errorMessage?: string
  isSingleFile?: boolean
  accept: Accept
  acceptFileName: string
  template?: DownloadTemplate
  uploadMessage?: string
}): ReactElement => {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    // open,
    isFocused,
    isDragAccept,
    isDragReject = true,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    ...(isSingleFile
      ? { maxFiles: 1 }
      : {
          multiple: true,
        }),
    accept,
  })

  const [uploadedFiles, setUploadedFiles] = useState<File[]>(files)

  const currentFiles = _.map(uploadedFiles, ({ path, name, size }) => {
    const [filename, ext] = getFileNameAndExt(name)
    const newName = `${_.truncate(filename, {
      length: 18,
      separator: ' ',
    })}.${ext}`

    return (
      <StyledUploadedFilePreview
        className={`${scss.filePreview} d-flex justify-content-between align-items-start`}
        key={path}
        hasError={!!errorMessage}
      >
        <div className='d-flex'>
          <div style={{ marginTop: '5px' }}>
            <SpreadsheetIcon />
          </div>
          <div className={scss.filePreviewName}>
            <div className='defaultText'>{newName}</div>
            <div>{getReadableBytes(size)}</div>
          </div>
        </div>
        <IconButton
          icon='FiX'
          onClick={() => {
            setUploadedFiles((oldFiles: File[]) => _.reject(oldFiles, { name }))
          }}
          disabled={disabled}
        />
      </StyledUploadedFilePreview>
    )
  })

  useEffect(() => {
    if (_.isEmpty(acceptedFiles)) return

    if (isSingleFile) {
      setUploadedFiles(acceptedFiles)
    } else {
      setUploadedFiles((oldFiles: File[]) => {
        const duplicatedFiles = _.intersectionBy(
          oldFiles,
          acceptedFiles,
          'name'
        )
        if (_.isEmpty(duplicatedFiles)) {
          return [...oldFiles, ...acceptedFiles]
        }
        showError(
          `${duplicatedFiles.length} file(s) have the same name. Remove them or rename the new files and upload again`
        )
        return oldFiles
      })
    }
  }, [acceptedFiles, isSingleFile])

  useEffect(() => {
    onChange(uploadedFiles)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFiles])

  const style = useMemo(
    () => ({
      ...(isFocused && focusedStyle),
      ...(isDragAccept && acceptStyle),
      ...(isDragReject && rejectStyle),
    }),
    [isFocused, isDragAccept, isDragReject]
  )

  const displayPlaceholder = _.isEmpty(currentFiles)

  return (
    <>
      <div>
        <div
          {...getRootProps({
            className: `${scss.dropZone} ${
              displayPlaceholder ? scss.placeholder : scss.preview
            }`,
            style,
          })}
        >
          <input {...getInputProps()} />
          {displayPlaceholder ? (
            <div>
              <div className='text-secondary smallText semiBold'>
                Drag and drop the {acceptFileName} file
              </div>

              {/* https://github.com/react-dropzone/react-dropzone/issues/1239 */}
              {/* <div>
            Or{' '}
            <span className={scss.textButton} onClick={open}>
              click here
            </span>
          </div> */}
              {template && (
                <>
                  <div className='text-secondary smallText'>
                    {uploadMessage}
                  </div>
                  <Button
                    variant={BUTTON_VARIANTS.link}
                    onClick={() => {
                      saveAs(
                        `${process.env.PUBLIC_URL}${template.filePath}`,
                        `${template.fileName}`
                      )
                    }}
                  >
                    DOWNLOAD TEMPLATE
                  </Button>
                </>
              )}
            </div>
          ) : (
            <div className='d-flex flex-wrap'>{currentFiles}</div>
          )}
        </div>

        {!!errorMessage && (
          <div className='text-danger'>
            <IconButton
              size={14}
              icon='RiErrorWarningFill'
              className='text-danger'
            />
            <span className='smallText'>{errorMessage}</span>
          </div>
        )}
      </div>
    </>
  )
}

export default UploadFile
