// libraries
import { ReactElement } from 'react'
import { useMount } from 'react-use'
import { EventBus } from 'ts-bus'
import { BusProvider } from 'ts-bus/react'

// utils
import { deleteAllDatabaseAtCurrentHost } from 'helpers/db'

// components
import SuRoutes from 'routers/routes'
import useRoutes from 'routers/routes/useRoutes'

const MissionControlStudio = (): ReactElement => {
  const globalBus = new EventBus()
  const routesState = useRoutes()

  useMount(() => {
    deleteAllDatabaseAtCurrentHost()
  })

  return (
    <BusProvider value={globalBus}>
      <SuRoutes {...routesState} />
    </BusProvider>
  )
}

export default MissionControlStudio
