// libraries
import { ReactElement, useMemo } from 'react'
import _ from 'lodash'
import keymirror from 'keymirror'

// constants
import { AVATAR_SIZES } from 'constants/user'

// components
import { MultiSelect, Avatar } from 'components/common'

// utils
import { useStateValue } from 'contexts'
import { useCurrentUser } from 'hooks'

import type { UserOption, UserOptions } from 'types/user'
import type { Payload } from 'types/common'

export const getUserOptionLabel =
  (additionalInfoKey?: string, showAvatar = true) =>
  (user: Partial<UserOption> & { overlay?: ReactElement }): ReactElement => {
    const { value, label, overlay } = user

    return (
      <div style={{ alignItems: 'center', display: 'flex' }} key={value}>
        {showAvatar && value && (
          <Avatar user={user} size={AVATAR_SIZES.small} overlay={overlay} />
        )}
        <span className='ms-2 userPicker'>
          {label}{' '}
          {additionalInfoKey && <div> ({_.get(user, additionalInfoKey)})</div>}
        </span>
      </div>
    )
  }

const UsersPicker = ({
  showAvatar,
  additionalInfoKey,
  ...rest
}: {
  value?: string
  showAvatar?: boolean
  additionalInfoKey?: string
  isMulti: boolean
  onChange?: (newUser: UserOption | undefined) => void
  options: UserOptions
  placeholder?: string
}): ReactElement => {
  return (
    <MultiSelect
      formatOptionLabel={getUserOptionLabel(additionalInfoKey, showAvatar)}
      placeholder='Select ...'
      {...rest}
    />
  )
}

UsersPicker.defaultProps = {
  value: null,
  showAvatar: true,
}

export const USERS_PICKER_WITH_CONTEXT_TYPES = keymirror({
  all: null,
  issueAssignee: null,
})

type IssueAssigneesProps = {
  bgColour: string
  isDisabled: boolean
  onChange?: (newUser: UserOption | undefined) => void
  placeholder?: string | ReactElement
  value?: string
}

export const UsersPickerWithContext = ({
  excludeUsername,
  includeUsername,
  type,
  filterPayload,
  ...rest
}: {
  excludeUsername?: string
  includeUsername?: string
  type?: string
  isMulti: boolean
  onChange?: (newUser: UserOption | undefined) => void
  placeholder: string
  additionalInfoKey?: string
  value?: string
  overlay?: ReactElement
  filterPayload?: Payload
  removeInvalidValues?: boolean
  withBorder?: boolean
  className?: string
} & IssueAssigneesProps): ReactElement => {
  const {
    selectors: {
      userSelectors: { usersOptions },
    },
  } = useStateValue()

  const { value, overlay } = rest

  const { issueAssigneesOptions } = useCurrentUser()

  const newIssueAssigneesOptions = useMemo(() => {
    if (!overlay) return issueAssigneesOptions

    return _.map(issueAssigneesOptions, option => {
      return { ...option, ...(option.value === value && { overlay }) }
    })
  }, [issueAssigneesOptions, overlay, value])

  const options = useMemo(() => {
    const sourceOptions =
      type === USERS_PICKER_WITH_CONTEXT_TYPES.issueAssignee
        ? newIssueAssigneesOptions
        : usersOptions

    const filteredOptions = _.isEmpty(filterPayload)
      ? sourceOptions
      : _.filter(sourceOptions, filterPayload)

    const currentUserOption = _.find(sourceOptions, {
      value: includeUsername,
    })

    const excludeUser = excludeUsername
      ? _.reject(filteredOptions, { value: excludeUsername })
      : filteredOptions

    return includeUsername
      ? _.uniqBy([currentUserOption, ...excludeUser], 'username')
      : excludeUser
  }, [
    excludeUsername,
    filterPayload,
    includeUsername,
    newIssueAssigneesOptions,
    type,
    usersOptions,
  ])

  return <UsersPicker options={options} {...rest} />
}

export const IssueAssigneesPicker = (
  props: IssueAssigneesProps
): ReactElement => {
  return UsersPickerWithContext({
    isMulti: false,
    placeholder: 'Select an assignee',
    ...props,
    type: USERS_PICKER_WITH_CONTEXT_TYPES.issueAssignee,
  })
}

export default UsersPicker
