// libraries
import { useEffect, useMemo, useCallback, ReactElement } from 'react'
import _ from 'lodash'
import { useToggle } from 'react-use'
import isEqual from 'fast-deep-equal'
import styled from '@emotion/styled'

// constants
import { LAYER_PROFILE_TYPES } from 'constants/profile'
import { NO_DATA_PLACEHOLDER } from 'constants/common'

// utils
import { useStateValue, useMapStateValue } from 'contexts'
import {
  convertPropertiesListToFeatures,
  isHistoricalDataset,
  getDataIdentityAndTimePropertyValue,
  getIdentityPropertyValue,
} from 'helpers/unipipe'
import { isEventTriggeredByClicking, isAssetLayer } from 'helpers/map'
import { switchcaseF } from 'helpers/utils'

// types
import type {
  MapLayerData,
  MapPickedFeature,
  MapLayerProfileProperty,
  MapLayer,
  MapLayerProfileProperties,
} from 'types/map'
import type { DatasetMetadata, Payload } from 'types/common'

// components
import { MdClose } from 'components/icons'
import MapPopupAssetProfile from 'components/assets/assetsProfile/viewer/Web'
import { useFeatureFlag } from 'hooks'
import { FEATURES } from 'constants/settings'
import PopupItem from './PopupItem'
import Journey from './Journey'
import AssetProfileDownloadPdfLink from './AssetProfileDownloadPdfLink'

// style
import scss from './index.module.scss'

const getLayerVisibleProperties = (
  properties: MapLayerProfileProperties
): MapLayerProfileProperties => _.filter(properties, 'isVisible')

const StyledPopup = styled.div<{
  isAdvancedProfile: boolean
  isProfileTriggeredByClicking: boolean
  withPinnedStyle: boolean
  position?: { x: number; y: number }
  isVisible: boolean
}>(props => {
  const {
    isAdvancedProfile,
    isProfileTriggeredByClicking,
    withPinnedStyle,
    position,
    isVisible,
  } = props

  const { x, y } = position || {}

  const popupSize =
    isAdvancedProfile && isProfileTriggeredByClicking
      ? {
          top: '50px',
          left: '50px',
          width: 'calc(100% - 100px)',
          height: 'calc(100% - 100px)',
        }
      : withPinnedStyle
      ? { width: '100%' }
      : { width: '306px' }

  return {
    ...(withPinnedStyle
      ? {
          '&:hover': { background: '#ffffff06' },
          marginBottom: '10px',
        }
      : position
      ? { top: y, left: x }
      : {}),
    ...popupSize,
    zIndex: 100,
    fontSize: '13px',
    color: '#fff',
    pointerEvents: 'all',
    display: isVisible ? 'block' : 'none',
    borderRadius: withPinnedStyle ? '0' : '5px',
    padding: withPinnedStyle ? '10px 10px 6px 10px' : '12px 14px',
    position: withPinnedStyle ? 'relative' : 'absolute',
    background: withPinnedStyle ? '#ffffff10' : '#051b32de',
  }
})

const StyledPopupContent = styled.div<{
  isClustering: boolean
}>(props => {
  const { isClustering } = props

  return isClustering && { maxHeight: '403px', overflowY: 'scroll' }
})

const MapPopup = ({
  popup,
  onClose,
  withTimeFilter,
  className,
  pinned,
}: {
  popup: MapPickedFeature
  onClose?: (id: string) => void
  withTimeFilter?: boolean
  className?: string
  pinned?: boolean
}): ReactElement => {
  const { [FEATURES.ASSET]: assetsEnabled } = useFeatureFlag()

  const {
    id,
    title,
    properties,
    position,
    isVisible = true,
    pickedType,
    profileType,
    layerId,
    object = {},
    historyProperties,
    name: popupIdentityValue,
    cluster = false,
  } = popup

  const {
    selectors: {
      unipipeSelectors: { pickedDatasetsMetadata },
    },
  } = useStateValue()

  const isProfileTriggeredByClicking = useMemo(
    () => isEventTriggeredByClicking(pickedType),
    [pickedType]
  )

  const isClustering = useMemo(
    () => !isProfileTriggeredByClicking && !!cluster,
    [cluster, isProfileTriggeredByClicking]
  )

  const isAssetProfile = useMemo(
    () => profileType === LAYER_PROFILE_TYPES.asset && !cluster,
    [cluster, profileType]
  )

  const isAdvancedProfile = useMemo(
    () =>
      _.includes(
        [LAYER_PROFILE_TYPES.journey, LAYER_PROFILE_TYPES.issue],
        profileType
      ) || isAssetProfile,
    [isAssetProfile, profileType]
  )

  const {
    map: { id: mapId },
    getLayer,
    searchFeatureSelected,
    setSearchFeatureSelected,
    getLayerFilteredDataById,
  } = useMapStateValue()

  const isHovered = useMemo(
    () => searchFeatureSelected?.id === id,
    [searchFeatureSelected, id]
  )

  const [profileHover, toggleProfileHover] = useToggle(isHovered)

  const visibleProperties = useMemo(
    () => getLayerVisibleProperties(properties),
    [properties]
  ) as Payload[]

  useEffect(() => {
    if (isAdvancedProfile) return

    let newClickedProfile
    if (isProfileTriggeredByClicking && profileHover) {
      const layer = getLayer(layerId)
      const { identityProperty } =
        _.get(pickedDatasetsMetadata, layer?.dataset) || {}
      newClickedProfile = {
        ...object,
        ...getDataIdentityAndTimePropertyValue(object, identityProperty),
        layerId,
        flyTo: false,
      }
    }

    if (!isEqual(newClickedProfile, searchFeatureSelected)) {
      setSearchFeatureSelected(newClickedProfile)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileHover, isAdvancedProfile])

  const simpleProfileClick = !isAdvancedProfile && isProfileTriggeredByClicking

  const withPinnedStyle = !!(simpleProfileClick || pinned)

  const profileMouseEnter = useCallback(() => {
    if (isProfileTriggeredByClicking) {
      toggleProfileHover(true)
    }
  }, [isProfileTriggeredByClicking, toggleProfileHover])

  const profileMouseLeave = _.debounce(() => {
    if (isProfileTriggeredByClicking) {
      toggleProfileHover(false)
    }
  }, 200)

  const generatePopupItem = useCallback(
    (
      property: MapLayerProfileProperty,
      datasetMetadata = {},
      historyFeaturesData = []
    ) => (
      <PopupItem
        property={property}
        featuresData={historyFeaturesData}
        key={`${id}-${property.key || property?.label}-${property.value}`}
        datasetMetadata={datasetMetadata}
        object={object}
        pinned={withPinnedStyle}
      />
    ),
    [id, object, withPinnedStyle]
  )

  const getPopupData = useCallback(() => {
    const layer = getLayer(layerId)
    if (!layer) return {}

    const { dataset } = layer
    if (isAssetLayer(layer)) return {}

    const datasetMetadata = pickedDatasetsMetadata[dataset]
    const { identityProperty } = datasetMetadata
    const layerFilteredData = getLayerFilteredDataById(layerId, withTimeFilter)
    if (!layerFilteredData) return {}

    const popupData = _.filter(
      layerFilteredData,
      d => getIdentityPropertyValue(d, identityProperty) === popupIdentityValue
    )

    const isLayerWithHistoricalDataset = isHistoricalDataset(layer.timeliness)
    return { popupData, isLayerWithHistoricalDataset, datasetMetadata, layer }
  }, [
    pickedDatasetsMetadata,
    getLayer,
    getLayerFilteredDataById,
    layerId,
    popupIdentityValue,
    withTimeFilter,
  ])

  const renderJourneyProfile = useCallback(
    ({
      layer,
      popupData,
      datasetMetadata,
    }: {
      layer: MapLayer
      popupData: MapLayerData
      datasetMetadata: DatasetMetadata
    }) => {
      return (
        <Journey
          layer={{
            ...layer,
            id: `${mapId}-layer-${popupIdentityValue}`,
          }}
          popupData={popupData}
          datasetMetadata={datasetMetadata}
        />
      )
    },
    [mapId, popupIdentityValue]
  )

  const renderAssetProfile = useCallback(() => {
    const { id: assetId, profile } = object || {}
    return (
      assetId &&
      profile &&
      assetsEnabled && (
        <MapPopupAssetProfile assetId={assetId} profile={profile} />
      )
    )
  }, [assetsEnabled, object])

  const renderVisiblePropertyItems = useCallback(
    ({
      popupData,
      isLayerWithHistoricalDataset,
      datasetMetadata,
    }: {
      popupData: MapLayerData
      isLayerWithHistoricalDataset: boolean
      datasetMetadata: DatasetMetadata
    }) => {
      return visibleProperties.map(property => {
        const historyFeaturesData = isLayerWithHistoricalDataset
          ? popupData
          : convertPropertiesListToFeatures(historyProperties)
        return generatePopupItem(property, datasetMetadata, historyFeaturesData)
      })
    },
    [generatePopupItem, historyProperties, visibleProperties]
  )

  const renderIssueProfile = useCallback(
    popupState => {
      return renderVisiblePropertyItems(popupState)
    },
    [renderVisiblePropertyItems]
  )

  const renderAdvancedProfile = useCallback(
    popupState => {
      return switchcaseF({
        [LAYER_PROFILE_TYPES.asset]: () => renderAssetProfile(),
        [LAYER_PROFILE_TYPES.journey]: () => renderJourneyProfile(popupState),
        [LAYER_PROFILE_TYPES.issue]: () => renderIssueProfile(popupState),
      })(_.noop)(profileType)
    },
    [profileType, renderAssetProfile, renderIssueProfile, renderJourneyProfile]
  )

  const memoizedMapPopups = useMemo(() => {
    if (_.isEmpty(visibleProperties)) return <div>{NO_DATA_PLACEHOLDER}</div>
    if (!layerId) {
      /* Generating popups for aggregated layers or client-generated polygon
       * layers because users cannot change the map profile setting. In other
       * words, the popup content is predefined and cannot be modified by users
       */
      return visibleProperties.map(property => generatePopupItem(property))
    }

    /* Generating popups for non-aggregated layers or backend-generated
     * polygon layers. Users can change the map profile setting, such as the
     * widget type, to change the way to display data on the profile.
     */

    const popUp = getPopupData()
    return isProfileTriggeredByClicking && isAdvancedProfile
      ? renderAdvancedProfile(popUp)
      : renderVisiblePropertyItems(popUp)
  }, [
    layerId,
    getPopupData,
    isProfileTriggeredByClicking,
    isAdvancedProfile,
    renderAdvancedProfile,
    renderVisiblePropertyItems,
    visibleProperties,
    generatePopupItem,
  ])

  return (
    <StyledPopup
      {...(isAdvancedProfile && {
        onMouseEnter: profileMouseEnter,
        onMouseLeave: profileMouseLeave,
      })}
      className={className}
      isAdvancedProfile={isAdvancedProfile}
      isProfileTriggeredByClicking={isProfileTriggeredByClicking}
      withPinnedStyle={withPinnedStyle}
      position={position}
      isVisible={isVisible}
      isClustering={isClustering}
    >
      <div className={scss.buttonGroups}>
        {profileType === LAYER_PROFILE_TYPES.asset &&
          object.id &&
          object.profile &&
          assetsEnabled && (
            <AssetProfileDownloadPdfLink
              assetId={object.id}
              profileName={object.profile}
            />
          )}
        {_.isFunction(onClose) && (
          <button
            onClick={() => onClose(id)}
            className={scss.iconButton}
            type='button'
          >
            <MdClose className={scss.icon} size={20} />
          </button>
        )}
      </div>
      <div className={`singleLineTruncate ${scss.popupTitle}`}>{title}</div>
      <StyledPopupContent
        isClustering={isClustering}
        className={`${scss.popupContent} ${
          isAdvancedProfile ? scss.overflow : ''
        }`}
      >
        {memoizedMapPopups}
      </StyledPopupContent>
    </StyledPopup>
  )
}

MapPopup.defaultProps = {
  withTimeFilter: true,
  onClose: undefined,
  className: undefined,
  pinned: false,
}

export { PopupItem }
export default MapPopup
