// libraries
import { ReactElement } from 'react'
import { ColumnBodyOptions } from 'primereact/column'
import _ from 'lodash'

// constants
import { DATE_FORMAT } from 'constants/datetime'
import { TOOLTIP_PLACEMENT } from 'constants/settings'

// components
import { Tooltip } from 'components/common'
import { useTimezone } from 'hooks'
import { getFromNow } from 'helpers/datetime'
import { displayTime } from 'helpers/utils'

const Template = ({
  rowData,
  options: { field },
}: {
  rowData: unknown
  options: ColumnBodyOptions
}): ReactElement => {
  const { timezone } = useTimezone()

  const time = _.get(rowData, field)

  return time ? (
    <Tooltip
      placement={TOOLTIP_PLACEMENT.top}
      trigger={['hover']}
      overlay={<span>{getFromNow(time)}</span>}
    >
      <span>
        {displayTime({
          datetime: time,
          timezone,
          addFromNow: false,
          displayLabel: false,
          timeFormat: DATE_FORMAT,
        })}
      </span>
    </Tooltip>
  ) : (
    <></>
  )
}

const DateTimeTemplate = (
  rowData: unknown,
  options: ColumnBodyOptions
): ReactElement => {
  return <Template rowData={rowData} options={options} />
}

export default DateTimeTemplate
