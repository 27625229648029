// libraries
import { ReactElement } from 'react'
import _ from 'lodash'
import { ColumnBodyOptions } from 'primereact/column'
import { UsersPickerWithContext } from 'components/common/UsersPicker'

const Template = ({
  rowData,
  options: { field },
}: {
  rowData: unknown
  options: ColumnBodyOptions
}): ReactElement => {
  const readOnly = true
  const value = _.get(rowData, field)

  return (
    <UsersPickerWithContext
      bgColour='transparent'
      value={value}
      isDisabled={readOnly}
      isMulti={false}
      placeholder='undefined'
    />
  )
}

const UserTemplate = (
  rowData: unknown,
  options: ColumnBodyOptions
): ReactElement => {
  return <Template rowData={rowData} options={options} />
}

export default UserTemplate
