import _ from 'lodash'
import { ReactElement } from 'react'
import styled from '@emotion/styled'

import scss from './index.module.scss'

const StyledTab = styled.div<{
  isActive: boolean
}>`
  border-bottom: 2px solid
    ${props => (props.isActive ? props.theme.primary : 'none')};
  cursor: pointer;
`

export type TabsProps = {
  tabs: {
    label?: string
    value: string
  }[]
  className?: string
  selectedTab?: string
  onTabSelected: (v: string) => void
}

const Tabs = ({
  tabs,
  className,
  selectedTab,
  onTabSelected,
}: TabsProps): ReactElement => {
  return (
    <div className={`${scss.tabs} ${className}`}>
      <ul className={scss.tabNav}>
        {_.map(tabs, ({ label, value }) => (
          <StyledTab
            className={scss.tabHeader}
            isActive={selectedTab === value}
            onClick={() => onTabSelected(value)}
            key={value}
          >
            {label || _.capitalize(value)}
          </StyledTab>
        ))}
      </ul>
    </div>
  )
}

export default Tabs
