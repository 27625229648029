// libraries
import React, { useRef, useMemo, useCallback } from 'react'
import { useEcharts } from 'hooks'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment-timezone'

// constants
import {
  DEFAULT_CHART_TOOLTIP_OPTIONS,
  DEFAULT_WIDGET_COLOUR_PALETTE,
  DEFAULT_DARK_THEME_CHART_TEXT_COLOUR,
  DEFAULT_DARK_THEME_CHART_SECONDARY_COLOUR,
  DEFAULT_LIGHT_THEME_CHART_TEXT_COLOUR,
  DEFAULT_LIGHT_THEME_CHART_SECONDARY_COLOUR,
} from 'constants/widget'
import {
  DATE_HOUR_MINUTE_SECOND_FORMAT,
  getDisplayTimeFormatter,
  DATE_HOUR_MINUTE_SECOND_FORMAT_STANDARD,
} from 'constants/datetime'
import { NAME_PROPERTY_KEY } from 'constants/unipipe'
import { THEMES } from 'constants/colour'

// utils
import { displayValue, getRangeMinMaxObj } from 'helpers/utils'
import { isLightTheme } from 'helpers/colour'
import { getWidgetGrid } from 'helpers/widget'

const FEATURES_LINE_DIMENSIONS = {
  time: 0,
  name: 1,
}

const GROUP_LINE_DIMENSIONS = {
  time: 0,
}

const getTime = (period, param, target) => {
  return period
    ? moment.utc(param.value[target]).format(getDisplayTimeFormatter(period))
    : moment
        .utc(param.value[target])
        .format(DATE_HOUR_MINUTE_SECOND_FORMAT_STANDARD)
}

const getTooltipAxis = ({ param, yAxisPropertyName, period }) => {
  const time = getTime(period, param, GROUP_LINE_DIMENSIONS.time)

  const tooltip =
    param.value.length === 3
      ? [time, `${displayValue(param.value[2])}`]
      : [time, `${yAxisPropertyName}：${displayValue(param.value[1])}`]
  return tooltip.join('<br />')
}

const getTooltipAxisItem = params => {
  return `${params.value[FEATURES_LINE_DIMENSIONS.name]}:${displayValue(
    params.value[2]
  )}`
}

export const getItemTooltipFormatter = (params, yAxisPropertyName) => {
  return [
    `${moment
      .utc(params.value[FEATURES_LINE_DIMENSIONS.time])
      .format(DATE_HOUR_MINUTE_SECOND_FORMAT)}`,
    `Name：${params.value[FEATURES_LINE_DIMENSIONS.name]}`,
    `${yAxisPropertyName}：${displayValue(params.value[2])}`,
  ].join('<br />')
}

export const getAxisTooltipFormatter = ({
  params,
  isGroupLine,
  yAxisPropertyName,
  period,
}) => {
  const time = getTime(period, params[0], FEATURES_LINE_DIMENSIONS.time)
  const result = isGroupLine
    ? _.map(params, param =>
        getTooltipAxis({ param, yAxisPropertyName, period })
      )
    : [time, ..._(params).map(getTooltipAxisItem).uniq().value()]
  return result.join('<br /> ')
}

const LineWidget = ({
  series,
  colour,
  yAxisRange,
  yAxisPropertyName,
  title,
  timezone,
  period,
  customStyle,
  grid: widgetGrid,
  size,
  selectedDateTimeRange,
  theme,
  ...rest
}) => {
  const chartRef = useRef()

  const lightTheme = useMemo(() => isLightTheme(theme), [theme])

  const primaryTextColour = lightTheme
    ? DEFAULT_LIGHT_THEME_CHART_TEXT_COLOUR
    : DEFAULT_DARK_THEME_CHART_TEXT_COLOUR

  const isGroupLine = useMemo(
    () =>
      series.length === 1 &&
      _.isEqual(series[0].encode, {
        x: 0,
        y: 1,
      }),
    [series]
  )

  const getOptions = useCallback(
    ({ expanded } = {}) => {
      const grid = getWidgetGrid({ widgetGrid, expanded })

      const legend = expanded && {
        type: 'scroll',
        data: _.map(series, NAME_PROPERTY_KEY),
        inactiveColor: '#777',
        pageIconColor: '#fff',
        pageIconInactiveColor: '#fff',
        textStyle: {
          color: '#fff',
        },
        pageTextStyle: {
          color: '#fff',
        },
      }

      const tooltipStyle = {
        backgroundColor: '#051B32',
        textStyle: {
          fontSize: 13,
        },
      }

      const tooltip = {
        ...(isGroupLine || expanded
          ? {
              ...tooltipStyle,
              trigger: 'axis',
              formatter: params =>
                getAxisTooltipFormatter({
                  params,
                  isGroupLine,
                  yAxisPropertyName,
                  period,
                }),
            }
          : {
              ...tooltipStyle,
              trigger: 'item',
              position: params => {
                return [params[0] + 10, params[1] - 10]
              },
              formatter: params =>
                getItemTooltipFormatter(params, yAxisPropertyName),
            }),
        ...DEFAULT_CHART_TOOLTIP_OPTIONS,
      }

      const sharedAxisOptions = {
        axisLine: {
          lineStyle: {
            color: lightTheme
              ? DEFAULT_LIGHT_THEME_CHART_SECONDARY_COLOUR
              : DEFAULT_DARK_THEME_CHART_SECONDARY_COLOUR,
          },
        },
        splitLine: {
          show: false,
        },
      }

      const xAxisSelectedRange = {
        ...(selectedDateTimeRange
          ? { min: selectedDateTimeRange.start, max: selectedDateTimeRange.end }
          : {}),
      }

      const xAxis = {
        ...sharedAxisOptions,
        ...xAxisSelectedRange,
        type: 'time',
        name: 'time',
        axisLabel: {
          color: primaryTextColour,
          showMinLabel: true,
          showMaxLabel: true,
          formatter: value =>
            _.truncate(
              moment.utc(value).format(DATE_HOUR_MINUTE_SECOND_FORMAT_STANDARD),
              {
                length: 10,
              }
            ),
          rotate: 50,
        },
        axisTick: {
          show: false,
        },
      }

      const yAxisMinMax = getRangeMinMaxObj(yAxisRange)

      const yAxis = {
        ...sharedAxisOptions,
        ...yAxisMinMax,
        type: 'value',
        name: yAxisPropertyName,
        axisLabel: {
          color: primaryTextColour,
          formatter: value => displayValue(value),
        },
        splitNumber: expanded ? 5 : 1,
      }

      const widgetTitle = expanded && {
        show: false,
        text: title,
        textStyle: {
          color: primaryTextColour,
        },
        left: 'center',
      }

      return {
        // https://echarts.apache.org/en/option.html#useUTC
        useUTC: !timezone,
        title: widgetTitle,
        legend,
        grid,
        xAxis,
        yAxis,
        color: colour,
        tooltip,

        series,
        animation: false,
      }
    },
    [
      widgetGrid,
      series,
      isGroupLine,
      lightTheme,
      selectedDateTimeRange,
      primaryTextColour,
      yAxisRange,
      yAxisPropertyName,
      title,
      timezone,
      colour,
      period,
    ]
  )

  const { style } = useEcharts({
    ...rest,
    chartRef,
    getOptions,
    customStyle,
    size,
  })

  return <div ref={chartRef} style={style} />
}

LineWidget.propTypes = {
  series: PropTypes.arrayOf(
    PropTypes.shape({
      encode: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
      }),
    })
  ).isRequired,
  colour: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  yAxisRange: PropTypes.arrayOf(PropTypes.number),
  yAxisPropertyName: PropTypes.string,
  title: PropTypes.string,
  timezone: PropTypes.string,
  customStyle: PropTypes.shape({}),
  size: PropTypes.string,
  period: PropTypes.string,
  grid: PropTypes.shape({}),
  selectedDateTimeRange: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  theme: PropTypes.oneOf([THEMES.dark, THEMES.light]),
}

LineWidget.defaultProps = {
  colour: DEFAULT_WIDGET_COLOUR_PALETTE,
  yAxisRange: [],
  yAxisPropertyName: '',
  title: '',
  timezone: null,
  customStyle: undefined,
  size: 'regular',
  theme: THEMES.dark,
  period: undefined,
  grid: undefined,
  selectedDateTimeRange: undefined,
}

export default LineWidget
