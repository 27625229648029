import _ from 'lodash'
import { validateRequiredKey } from 'helpers/validators'
import { checkDuplicateInArray } from 'helpers/utils'

// constants
import {
  FORM_VALIDATION_ERRORS_MAP,
  NUMBER_FIELD_TYPES,
} from 'constants/formBuilder'

import type { Payload } from 'types/common'
import type {
  NumberWidgetPanelSpecs,
  RangeLimitProperties,
  TextInputPanelSpecs,
  SignaturePanel,
  FormEnum,
  FormEnumNames,
} from 'types/formBuilder'

const validateLimits = ({
  errors,
  limits,
  errorFieldName,
}: {
  errors: Payload
  limits?: RangeLimitProperties
  errorFieldName: string
}) => {
  // Minimum < maximum
  if (
    limits &&
    _.isNumber(limits?.minimum) &&
    _.isNumber(limits?.maximum) &&
    limits.minimum > limits.maximum
  ) {
    errors[errorFieldName] = {
      minimum: `Value must be less than ${limits.maximum}`,
    }
  }
}

export const validateCheckboxes = (values: Payload): Payload => {
  const errors = {} as Payload
  validateRequiredKey('title', 'Checkboxes label')(values, errors)

  const enumValues = _.get(values, 'select.enum')
  const hasDuplicateValue = checkDuplicateInArray(enumValues)
  const hasInvalidValue = _.includes(enumValues, '')

  if (_.isEmpty(enumValues) || hasInvalidValue) {
    errors.select = 'Missing values'
  } else if (hasDuplicateValue) {
    errors.select = 'Duplicate values'
  }

  return errors
}

export const validationEnums = ({
  enumNames,
  enumValues,
  errors,
}: {
  enumValues: FormEnum
  enumNames: FormEnumNames
  errors: Payload
}): Payload => {
  const hasDuplicateValue = checkDuplicateInArray(enumValues)
  const hasInvalidValue = _.includes(enumValues, '')
  const hasDuplicateLabel = checkDuplicateInArray(_.compact(enumNames))

  if (hasInvalidValue || _.isEmpty(enumValues)) {
    errors.select = 'Missing values'
  } else if (hasDuplicateValue) {
    errors.select = 'Duplicate values'
  } else if (hasDuplicateLabel) {
    errors.select = 'Duplicate options'
  }
  return errors
}

export const validateDropdown = (values: Payload): Payload => {
  const errors = {} as Payload
  validateRequiredKey('title', 'Dropdown label')(values, errors)

  const enumValues = _.get(values, 'select.enum')
  const enumLabels = _.get(values, 'select.enumNames')

  validationEnums({ enumValues, enumLabels, errors })

  return errors
}

export const validateTextInput = (values: TextInputPanelSpecs): Payload => {
  const { limits } = values
  const errors = {} as Payload

  validateRequiredKey('title', 'label')(values, errors)
  validateLimits({ errors, limits, errorFieldName: 'limits' })

  if (values.default && limits) {
    // Default value length < minimum
    if (_.isNumber(limits.minimum) && values.default.length < limits.minimum) {
      errors.default = FORM_VALIDATION_ERRORS_MAP.minLength({
        params: { limit: limits.minimum },
      })
    }
    // Default value length > maximum
    if (_.isNumber(limits.maximum) && values.default.length > limits.maximum) {
      errors.default = FORM_VALIDATION_ERRORS_MAP.maxLength({
        params: { limit: limits.maximum },
      })
    }
  }

  return errors
}

export const validateNumberField = (
  values: NumberWidgetPanelSpecs
): Payload => {
  const { range, addonLabel } = values
  const errors = {} as Payload

  validateRequiredKey('title', 'label')(values, errors)
  validateLimits({ errors, limits: range, errorFieldName: 'range' })

  const hasMinimum = _.isNumber(range?.minimum)
  const hasMaximum = _.isNumber(range?.maximum)

  // If it's a slider, the min/max settings are required
  if (
    values.fieldType === NUMBER_FIELD_TYPES.slider &&
    (!hasMinimum || !hasMaximum)
  ) {
    const requiredError = FORM_VALIDATION_ERRORS_MAP.required({})
    errors.range = {
      ...(!_.isNumber(range?.minimum) && { minimum: requiredError }),
      ...(!_.isNumber(range?.maximum) && { maximum: requiredError }),
      ...(errors.range || {}),
    }
  }

  if (_.isNumber(values.default) && range) {
    // Default value < minimum
    if (hasMinimum && values.default < range.minimum) {
      errors.default = FORM_VALIDATION_ERRORS_MAP.minimum({
        params: { limit: range.minimum },
      })
    }
    // Default value > maximum
    if (hasMaximum && values.default > range.maximum) {
      errors.default = FORM_VALIDATION_ERRORS_MAP.maximum({
        params: { limit: range.maximum },
      })
    }
  }

  // Addon label === true but there is no label
  if (addonLabel.visible && !addonLabel.label) {
    errors.addonLabel = {
      label: 'Addon label is required',
    }
  }

  return errors
}

export const validateHeading = (values: NumberWidgetPanelSpecs): Payload => {
  const { title, description } = values
  const errors = {} as Payload

  const errorMessage = 'Heading or Subheading is required.'
  if (!title && !description) {
    errors.title = errorMessage
    errors.description = errorMessage
  }
}

export const validateSignatureInput = (values: SignaturePanel): Payload => {
  const errors = {} as Payload

  validateRequiredKey('title', 'label')(values, errors)

  return errors
}

export const validateGeoPoint = (values: SignaturePanel): Payload => {
  const errors = {} as Payload

  validateRequiredKey('title', 'label')(values, errors)

  return errors
}
