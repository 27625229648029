// libraries
import styled from '@emotion/styled'
// constants
import { TABLE_HEADER_HEIGHT } from './constants'

export const SelectRowCheckBox = styled.div`
  button {
    color: ${props => props.theme.primary};
  }
`

type StyledDataTableProp = { isLightTheme: boolean }

export const StyledDataTable = styled.div<StyledDataTableProp>`
  .p-component {
    font-family: Open Sans;
    font-size: 13px;
    font-weight: normal;
  }

  tbody {
    max-width: 100%; //fix mis-alignment
    // Subtract a table header height from the block height to fix the scrollbar
    min-height: calc(100% - ${TABLE_HEADER_HEIGHT}px);
  }

  .p-datatable .p-datatable-tbody > tr {
    background: ${props => (props.isLightTheme ? '#fff' : props.theme.dark)};
    color: ${props =>
      props.isLightTheme
        ? props.theme['secondary-700']
        : props.theme['secondary-light-600']};
    transition: box-shadow 0.2s;
  }

  .p-datatable-tbody .p-virtualscroller-content {
    max-width: 100%;
  }

  .p-datatable .p-datatable-thead > tr > th {
    background: ${props =>
      props.isLightTheme
        ? 'transparent !important'
        : `${props.theme['primary-900']} !important`};

    border: none;
    border-bottom: ${props =>
      props.isLightTheme
        ? `1px solid ${props.theme['secondary-light-600']}`
        : `1px solid ${props.theme['primary-800']}`};
    color: ${props =>
      props.isLightTheme
        ? props.theme['secondary-700']
        : props.theme['secondary-light-600']};
  }

  .p-datatable .p-datatable-tbody > tr .userPicker {
    color: ${props =>
      props.isLightTheme
        ? props.theme['secondary-700']
        : props.theme['secondary-light-600']};
  }

  .p-datatable .p-datatable-tbody > tr > td {
    border: none;
    border-bottom: ${props =>
      props.isLightTheme
        ? `1px solid ${props.theme['secondary-light-600']}`
        : `1px solid ${props.theme['primary-800']}`};
  }

  .p-datatable .p-datatable-tbody > tr.p-highlight {
    background: ${props =>
      props.isLightTheme
        ? props.theme['secondary-light-500']
        : props.theme['primary-900']};
    color: ${props => props.theme.primary};
  }

  .p-datatable.p-datatable-selectable
    .p-datatable-tbody
    > tr.p-selectable-row:focus {
    outline: 0 none;
  }

  .p-datatable .p-sortable-column:focus {
    box-shadow: inset 0 0 0 0.15rem ${props => props.theme.primary};
    outline: 0 none;
  }

  .actionMenu {
    display: none;
  }

  .p-datatable.p-datatable-selectable
    .p-datatable-tbody
    > tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover {
    background: ${props =>
      props.isLightTheme
        ? props.theme['secondary-light-500']
        : props.theme['primary-900']};
    color: ${props => props.theme.primary};

    .actionMenu {
      display: block;
    }
  }

  .p-datatable.p-datatable-selectable
    .p-datatable-tbody
    > tr.p-selectable-row:not(.p-highlight):not(.p-datatable-emptymessage):hover
    .userPicker {
    color: ${props => props.theme.primary};
  }

  .p-datatable
    .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover
    .p-sortable-column-icon {
    color: ${props => props.theme.primary};
  }

  .p-datatable
    .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
    background: ${props => props.theme['primary-100']};
    color: ${props => props.theme.primary};
  }

  .p-datatable .p-sortable-column.p-highlight {
    background: ${props => props.theme['primary-100']};
    color: ${props => props.theme.primary};
  }

  .p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
    background: ${props => props.theme['primary-100']};
    color: ${props => props.theme.primary};
  }

  .p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: ${props => props.theme.primary};
  }

  .p-datatable
    .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover
    .p-sortable-column-icon {
    color: ${props => props.theme.primary};
  }

  .p-datatable .p-datatable-loading-icon {
    font-size: 2rem;
    color: ${props => props.theme.primary};
  }

  .p-datatable-footer {
    padding: 8px;
  }
`

export const DropdownItem = styled.div`
  :hover {
    background: ${props => props.theme['primary-100']};
  }
`
