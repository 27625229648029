import {
  useCallback,
  ReactElement,
  MouseEvent,
  KeyboardEvent,
  forwardRef,
} from 'react'
import _ from 'lodash'

// components
import { StyledField } from 'components/common/Form'

// utils
import {
  getConvertedStringValue,
  sanitizeString,
  isEnterKey,
} from 'helpers/utils'
import { StyledInput } from 'components/common/Field/style'

export const numberValueLimit = (
  val: number | string,
  min = -Infinity,
  max = Infinity
): number | null => {
  if (_.isNil(val) || val === '') return null

  const newValue = val < min ? min : val > max ? max : val
  return Number(newValue)
}

export type InputProps = {
  id?: string
  label?: string
  description?: string
  type?: string
  input: {
    name: string
    value: string | number
    onChange: (v: string | number) => void
    onBlur: (v: MouseEvent | KeyboardEvent) => void
    onFocus: (v: MouseEvent | KeyboardEvent) => void
    type: string
  }
  className?: string
  required?: boolean
  autoComplete?: string
  groupOptionStyle?: boolean
  inline?: boolean
  readOnly?: boolean
  disabled?: boolean
  labelClassName?: string
  convertStringType?: string
  addon?: ReactElement
  min?: number
  max?: number
  'data-testid'?: string
  defaultValue?: string | number
  autoFocus?: boolean
  placeholder?: string
}

const Input = forwardRef((props: InputProps, ref): ReactElement => {
  const {
    id,
    className,
    input,
    label,
    convertStringType,
    groupOptionStyle,
    required,
    defaultValue,
    autoFocus,
    ...rest
  } = props

  const inputProps = {
    ..._.pick(props, [
      'id',
      'placeholder',
      'required',
      'type',
      'step',
      'max',
      'min',
      'disabled',
      'readOnly',
      'data-testid',
      'autoComplete',
      'onScroll',
    ]),
    ...input,
    className,
  }

  const { onBlur, onChange, onFocus, name, value = defaultValue } = input

  const onTextChange = useCallback(
    (event: MouseEvent | KeyboardEvent) => {
      onFocus(event)
      const cleanText = sanitizeString(event.target.value) || ''

      const newValue =
        inputProps.type === 'number'
          ? numberValueLimit(cleanText, inputProps.min, inputProps.max)
          : getConvertedStringValue(cleanText.trim(), convertStringType)

      onChange(newValue)
      onBlur(event)
    },
    [
      convertStringType,
      inputProps.max,
      inputProps.min,
      inputProps.type,
      onBlur,
      onChange,
      onFocus,
    ]
  )

  const onKeyPress = (event: KeyboardEvent) => {
    onFocus(event)

    if (isEnterKey(event)) {
      onTextChange(event)
    }
  }

  return (
    <StyledField
      name={name}
      groupOptionStyle={groupOptionStyle}
      label={label}
      required={required}
      {...rest}
    >
      <StyledInput
        {...inputProps}
        value={value}
        onBlur={onTextChange}
        onKeyDown={onKeyPress}
        autoFocus={autoFocus}
        ref={ref}
      />
    </StyledField>
  )
})

Input.defaultProps = {
  id: undefined,
  label: '',
  type: 'text',
  className: 'form-control',
  required: false,
  autoComplete: 'on',
  groupOptionStyle: false,
  inline: false,
  labelClassName: 'form-label',
  convertStringType: undefined,
  addon: undefined,
  autoFocus: false,
}

export default Input
