import React, { useMemo } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

// constants
import { PAGE_FILTER_PLACEHOLDER } from 'constants/common'

// components
import { MultiSelect } from 'components/common'
import { MdKeyboardArrowDown } from 'components/icons'

import scss from '../index.module.scss'

import { SelectDropdown } from '../FilterForm'
import useFiltersDropdown from '../hooks/useFiltersDropdown'

export const CustomOption = formatOptionLabel => props => {
  // eslint-disable-next-line react/prop-types
  const { data, getStyles, label, isSelected, innerRef, innerProps, isMulti } =
    props
  return (
    <div
      ref={innerRef}
      style={getStyles('option', props)}
      className={`d-flex align-items-center ${scss.option}`}
      {...innerProps}
    >
      {isMulti && (
        <input type='checkbox' checked={isSelected} onChange={_.noop} />
      )}
      <div className={scss.label}>
        {formatOptionLabel ? formatOptionLabel(data) : label}
      </div>
    </div>
  )
}

const FilterDropdown = ({
  value,
  options,
  onChange,
  title,
  isMulti,
  showAvatar,
  isClearable,
  formatOptionLabel,
  vertical,
  useOptionValueOnly,
  ...rest
}) => {
  const { isOpen, toggleOpen, ref } = useFiltersDropdown(value)

  const selectedOptions = useMemo(() => {
    const validValue = isMulti ? value : [value]
    return _(validValue)
      .map(v => _.find(options, ['value', v]))
      .compact()
      .value()
  }, [isMulti, value, options])

  // display user avatar
  // type === ENTITIES.user && showAvatar ? (
  //       <SmallAvatar key={key} user={option} />
  // )

  const labels = useMemo(
    () =>
      _.isEmpty(selectedOptions)
        ? PAGE_FILTER_PLACEHOLDER
        : _.map(selectedOptions, 'label').join(', '),
    [selectedOptions]
  )

  return (
    <div className={`d-flex align-items-center flex-wrap ${scss.anchor} `}>
      <SelectDropdown
        isOpen={isOpen}
        onClose={toggleOpen}
        ref={ref}
        target={
          <div
            className={`d-flex align-items-center ${scss.button}`}
            type='button'
            onClick={toggleOpen}
          >
            <span>{title}: </span>
            <span className={scss.buttonSelected}>{labels}</span>
            <MdKeyboardArrowDown className='ms-2' size={16} />
          </div>
        }
      >
        <div className={scss.dropdown}>
          <MultiSelect
            {...rest}
            autoFocus
            dropdownOnly
            components={{ Option: CustomOption(formatOptionLabel) }}
            backspaceRemovesValue={false}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            isClearable={false}
            menuIsOpen
            onChange={option => {
              onChange(option)
              if (!isMulti) {
                toggleOpen()
              }
            }}
            options={options}
            placeholder='Search...'
            tabSelectsValue={false}
            value={isMulti ? value : _.first(value)}
            isMulti={isMulti}
            useOptionValueOnly={useOptionValueOnly}
          />
        </div>
      </SelectDropdown>
    </div>
  )
}

FilterDropdown.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
  ),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
      ]),
      label: PropTypes.string.isRequired,
    })
  ),
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isMulti: PropTypes.bool,
  showAvatar: PropTypes.bool,
  isClearable: PropTypes.bool,
  formatOptionLabel: PropTypes.func,
  vertical: PropTypes.bool,
  useOptionValueOnly: PropTypes.bool,
}

FilterDropdown.defaultProps = {
  value: [],
  options: [],
  isMulti: true,
  showAvatar: false,
  isClearable: true,
  formatOptionLabel: undefined,
  vertical: false,
  useOptionValueOnly: undefined,
}

export default FilterDropdown
