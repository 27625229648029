import keymirror from 'keymirror'

// https://github.com/aws-amplify/amplify-js/blob/53175248fa73d43860974dcd565b6681882e7a45/packages/amplify-ui-components/src/common/types/auth-types.ts
export const MFA_OPTIONS = keymirror({
  TOTP: null,
  SMS: null,
  NOMFA: null,
})

export const ENTITY_TYPE = 'user'

export const USER_ROLES = {
  admin: 'admin',
  groupAdmin: 'groupadmin',
  user: 'user',
}

export const USER_ROLE_LABELS = {
  [USER_ROLES.admin]: 'Super Admin',
  [USER_ROLES.groupAdmin]: 'Administrator',
  [USER_ROLES.user]: 'User',
}

export const GROUP_ADMIN_ROLE_OPTIONS = [
  {
    label: USER_ROLE_LABELS[USER_ROLES.groupAdmin],
    value: USER_ROLES.groupAdmin,
  },
  { label: USER_ROLE_LABELS[USER_ROLES.user], value: USER_ROLES.user },
]

export const SUPER_ADMIN_ROLE_OPTIONS = [
  { label: USER_ROLE_LABELS[USER_ROLES.admin], value: USER_ROLES.admin },
  ...GROUP_ADMIN_ROLE_OPTIONS,
]

export const ACTION_TYPES = keymirror({
  manage: null,
  create: null,
  read: null,
  update: null,
  delete: null,
})

export const AVATAR_SIZES = keymirror({
  small: null,
  regular: null,
  large: null,
  xLarge: null,
})

export const SYSTEM_USER_USERNAME = 'des.default'

export const UNASSIGNED_USERNAME = 'Unassigned'

export const SYSTEM_USERNAME = 'System-generated'

export const DEFAULT_ROLE = 'public'

export const USER_EDIT_MODE = keymirror({
  avatar: null,
  delete: null,
  consents: null,
  mfa: null,
  mfaSms: null,
  mfaApp: null,
  resetMfaConfirm: null,
  globalSignOutConfirm: null,
  resetPasswordConfirm: null,
})

export const AVATAR_UPLOADER_PLACEHOLDER = '/assets/avatar-dnd-placeholder.svg'

export const DEFAULT_AVATAR_URL = '/assets/icons/avatar.webp'

export const DEFAULT_USER = {
  avatarUrl: DEFAULT_AVATAR_URL,
  label: UNASSIGNED_USERNAME,
  username: UNASSIGNED_USERNAME,
}

export const DEFAULT_SYSTEM_AVATAR_URL = '/assets/icons/systemAvatar.png'
export const DEFAULT_UNKNOWN_AVATAR_URL = DEFAULT_SYSTEM_AVATAR_URL

export const DEFAULT_SYSTEM_USER = {
  avatarUrl: DEFAULT_SYSTEM_AVATAR_URL,
  label: SYSTEM_USERNAME,
  username: SYSTEM_USERNAME,
}

export const NEW_PASSWORD_MIN_LENGTH = 15

export const DEFAULT_GROUP = 'des-default'

export const DEFAULT_NEW_USER = {
  role: USER_ROLES.user,
}
