// libraries
import { ReactElement } from 'react'
import { WidgetProps } from '@rjsf/core'
import _ from 'lodash'
import { Theme, useTheme } from '@emotion/react'

// components
import * as Icons from 'components/icons'

// constants
import { FORM_QUESTION_TYPES } from 'constants/formBuilder'

// utils
import { getFormBuilderWidgetType } from 'helpers/formBuilder'
import { StyledInputIcon } from './style'

const getIconProps = ({
  schema,
  uiSchema,
  theme,
}: {
  schema: WidgetProps['JSONSchema7']
  uiSchema: WidgetProps['UiSchema']
  theme: Theme
}): Partial<{ iconName: string; size: number; color: string }> => {
  const widgetType = getFormBuilderWidgetType({ schema, uischema: uiSchema })

  switch (true) {
    case _.get(uiSchema, '[ui:options].smartScan', false):
      return {
        iconName: 'MdOutlineCameraAlt',
        size: 20,
        color: theme.secondary,
      }
    case widgetType === FORM_QUESTION_TYPES.SCANNER:
      return { iconName: 'MdOutlineQrCodeScanner', size: 20 }
    default:
      return {}
  }
}

const TextWidgetAddonIcon = ({
  schema,
  uiSchema,
}: {
  schema: WidgetProps['JSONSchema7']
  uiSchema: WidgetProps['UiSchema']
}): ReactElement | null => {
  const theme = useTheme()

  const { iconName, ...rest } = getIconProps({ schema, uiSchema, theme })
  const Icon = iconName ? _.get(Icons, iconName) : null

  return Icon ? (
    <StyledInputIcon>
      <Icon {...rest} />
    </StyledInputIcon>
  ) : null
}

export default TextWidgetAddonIcon
