// libraries
import { forwardRef, ReactElement, ReactNode } from 'react'

// utils
import { getActiveStatus } from 'helpers/utils'
import { StyledListItem, StyledSpan } from './style'
import scss from './index.module.scss'

type ListGroupItemProps = {
  bgColour?: boolean
  borderLeft?: boolean
  active?: boolean
  testId?: string
  className?: string
  children?: ReactNode
}
const ListGroupItem = forwardRef(
  (
    {
      bgColour = false,
      borderLeft = true,
      className,
      children,
      active = true,
      testId = 'list-group-item',
      ...rest
    }: ListGroupItemProps,
    ref
  ): ReactElement => {
    return (
      <StyledListItem
        ref={ref}
        className={`list-group-item d-flex flex-row align-items-center ${scss.listGroupItem} ${className}`}
        bgColour={bgColour}
        active={active}
        data-testid={testId}
        aria-label={getActiveStatus(active)}
        {...rest}
      >
        {borderLeft && (
          <StyledSpan className={scss.borderLeftBar} active={active} />
        )}
        {children}
      </StyledListItem>
    )
  }
)

export default ListGroupItem
