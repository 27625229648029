import _ from 'lodash'
import { FilterSpec, Filters } from 'types/filter'

export const getFilterKeysByPredicate = (
  filtersSpecs: FilterSpec[],
  predicate: Record<string, unknown>
): string[] => _(filtersSpecs).filter(predicate).map('key').value()

export const getValidFilters = (filters: Filters): Filters =>
  _.omitBy(filters, v => _.isNil(v) || (_.isArray(v) && _.isEmpty(v)))
