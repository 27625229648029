// libraries
import { PropsWithChildren, ReactElement, ReactPortal, useMemo } from 'react'
import ReactDOM from 'react-dom'
import styled from '@emotion/styled'
import _ from 'lodash'

// utils
import { switchcase } from 'helpers/utils'

// constants
import { MODAL_SIZES, MODAL_TYPES } from 'constants/settings'
import { ButtonProps, BUTTON_VARIANTS } from 'components/common/Button'

// components
import { BladeFooter, IconButton } from 'components/common'

import type { Entity } from 'types/entity'
import type { ModalType } from 'types/common'

import * as Icons from 'components/icons'
import scss from './index.module.scss'

const getModalClass = (modalType: ModalType) =>
  switchcase({
    [MODAL_TYPES.warning]: scss.warningHeader,
    [MODAL_TYPES.danger]: scss.dangerHeader,
  })(undefined)(modalType)

const getButtonVariant = (modalType: ModalType) =>
  switchcase({
    [MODAL_TYPES.warning]: BUTTON_VARIANTS.warning,
    [MODAL_TYPES.danger]: BUTTON_VARIANTS.danger,
  })(BUTTON_VARIANTS.primary)(modalType)

const StyledModalTitle = styled.div<{
  modalType: ModalType
}>`
  color: ${props =>
    props.modalType === MODAL_TYPES.info && props.theme['primary-700']};
`

type ModalFooterPropType = {
  onCancel?: (v: Entity) => void
  canSubmit?: boolean
  submitting?: boolean
  onSubmit?: (v: Entity) => void
  disabled?: boolean
  pristine?: boolean
  isLoading?: boolean
  cancelContent?: string
  submitContent?: string
  modalType?: ModalType
  buttons?: (ButtonProps & { id: string; buttonPosition: 'left' | 'right' })[]
}

type ModalHeaderPropType = {
  icon?: string
  title?: string | ReactElement
  closeable?: boolean
  hide?: (v: boolean) => void
  modalType?: ModalType
}

export type ModalContainerPropType = PropsWithChildren<{
  isShowing?: boolean
  size?: string
  bodyClassName?: string
  scrollable?: boolean
}> &
  ModalHeaderPropType

const ModalHeader = ({
  icon,
  title,
  closeable,
  hide,
  modalType = MODAL_TYPES.info,
}: ModalHeaderPropType): ReactElement => {
  const Icon = Icons[icon] || Icons.AiOutlineWarning
  const modalHeaderCss = useMemo(() => getModalClass(modalType), [modalType])

  return (
    <div className={`d-flex justify-content-between ${scss.modalHeader}`}>
      <StyledModalTitle
        modalType={modalType}
        className={`d-flex flex-row align-items-center ${scss.modalTitle} ${modalHeaderCss}`}
      >
        {icon && (
          <div className='me-2'>
            <Icon size={24} />
          </div>
        )}
        <div data-testid='modal-title'>{title}</div>
      </StyledModalTitle>
      {closeable && (
        <IconButton
          icon='MdClose'
          size={18}
          className={scss.closeButton}
          data-dismiss='modal'
          aria-label='Close'
          onClick={hide}
        />
      )}
    </div>
  )
}

export const ModalFooter = ({
  onCancel = _.noop,
  canSubmit = true,
  submitting = false,
  onSubmit = _.noop,
  disabled = false,
  pristine = false,
  cancelContent = 'Cancel',
  submitContent = 'Submit',
  isLoading,
  modalType = MODAL_TYPES.info,
  buttons = [],
}: ModalFooterPropType): ReactElement => {
  const isSubmitLoading = submitting || isLoading
  const isSubmitDisabled = _.isUndefined(disabled)
    ? isSubmitLoading || pristine
    : disabled

  return (
    <div className={scss.modalFooter}>
      <BladeFooter
        buttons={[
          ...buttons,
          ...(onCancel
            ? [
                {
                  id: 'modal-cancel-btn',
                  variant: BUTTON_VARIANTS.secondary,
                  children: cancelContent,
                  onClick: onCancel,
                  testId: 'modal-cancel',
                  disabled: submitting,
                },
              ]
            : []),
          ...(canSubmit
            ? [
                {
                  id: 'modal-submit-btn',
                  variant: getButtonVariant(modalType),
                  type: 'submit',
                  testId: 'modal-submit',
                  children: submitContent,
                  ...(!isSubmitDisabled && {
                    onClick: onSubmit,
                  }),
                  disabled: isSubmitDisabled,
                  isLoading: isSubmitLoading,
                  className: 'ms-2',
                },
              ]
            : []),
        ]}
      />
    </div>
  )
}

export const ModalContent = ({
  content,
  ...rest
}: { content: string } & ModalFooterPropType): ReactElement => (
  <>
    <div className='py-4 px-3'>{content}</div>
    <ModalFooter {...rest} />
  </>
)

const getModalWidth = (size: string): number =>
  switchcase({
    [MODAL_SIZES.extraSmall]: '400px',
    [MODAL_SIZES.small]: '500px',
    [MODAL_SIZES.regular]: '800px',
    [MODAL_SIZES.large]: '80%',
  })('500px')(size)

const Modal = ({
  isShowing = false,
  hide,
  children,
  size = MODAL_SIZES.small,
  title = '',
  bodyClassName = '',
  closeable = true,
  scrollable = false,
  modalType = MODAL_TYPES.info,
  icon,
}: ModalContainerPropType): ReactPortal | null => {
  return isShowing
    ? ReactDOM.createPortal(
        <>
          <div className={scss.modalOverlay} />
          <div className={scss.modalWrapper}>
            <div
              className={scss.modal}
              style={{
                width: getModalWidth(size),
              }}
            >
              <div
                data-testid='modal-content'
                className={`d-flex flex-column w-100 ${scss.modalContent}`}
              >
                {title ? (
                  <ModalHeader
                    icon={icon}
                    title={title}
                    hide={hide}
                    closeable={closeable}
                    modalType={modalType}
                  />
                ) : (
                  closeable && (
                    <IconButton
                      icon='MdClose'
                      size={18}
                      className={scss.closeButton}
                      data-dismiss='modal'
                      aria-label='Close'
                      onClick={hide}
                    />
                  )
                )}
                <div
                  className={`d-flex flex-column p-0 ${scss.modalBody} ${bodyClassName}`}
                  style={{
                    maxHeight: title && ' calc(100vh - 120px)',
                    ...(scrollable && { overflowY: 'auto' }),
                  }}
                >
                  {children}
                </div>
              </div>
            </div>
          </div>
        </>,
        document.body
      )
    : null
}

export default Modal
