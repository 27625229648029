// libraries
import keymirror from 'keymirror'

// constants
import { ENTITIES } from 'constants/common'

export const FORM_GALLERY_VIEWS = keymirror({ form: null, formList: null })

export const FORM_GALLERY_TABS = [
  { value: ENTITIES.form, label: 'Forms' },
  {
    value: ENTITIES.formList,
    label: 'Lists',
  },
]

export type FormGalleryView = keyof typeof FORM_GALLERY_VIEWS
