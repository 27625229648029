// libraries
import {
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
  CSSProperties,
} from 'react'
import { useClickAway, useToggle, useUpdateEffect } from 'react-use'
import _ from 'lodash'

// constants
import {
  DATE_TIME_PICKER_POPPER_MODIFIERS,
  DATE_UNIT_TYPES,
  HOUR_MINUTE_AM_PM,
  MONTH_DAY_YEAR_FORMAT,
} from 'constants/datetime'
import { DATE_TIME_COMPARISON_EDITOR_PRECISION } from 'constants/workflow'

// util
import { getSnappedDatetimeStr } from 'helpers/datetime'
import { displayTime } from 'helpers/utils'
import { useBranding } from 'hooks'

// components
import { CustomHeaderWrapper } from 'components/common/DateTime/common'
import useTimePicker from 'components/common/DateTime/common/useTimePicker'
import AbsoluteTimePicker from 'components/common/DateTimePicker/AbsoluteTimePicker'
import { IconButton } from 'components/common'
import type { UtcTimeString, UtcISOString } from 'types/datetime'

import scss from './index.module.scss'

export type DateTimeType =
  | keyof typeof DATE_TIME_COMPARISON_EDITOR_PRECISION
  | undefined

export type DateTimeValue = {
  value: UtcISOString | null
  type?: DateTimeType
}

type DateTimePickerProps = {
  isDisabled: boolean
  selectedTime?: UtcISOString
  timeFormat?: string
  addFromNow?: boolean
  displayLabel?: boolean
  timezone?: string
  minDate?: UtcISOString
  maxDate?: UtcISOString
  className?: string
  style?: CSSProperties
  showTimeSelect?: boolean
  isInput?: boolean
  onChange: ({ value, type }: DateTimeValue) => void
  inputClassName?: string
  placeholder?: string
  isClearable?: boolean
  selectedTimeType: DateTimeType
}

const DEFAULT_DATE_TIME_FORMAT = `${MONTH_DAY_YEAR_FORMAT} ${HOUR_MINUTE_AM_PM}`

const DateTimePicker = ({
  isDisabled,
  timeFormat,
  addFromNow = false,
  displayLabel,
  selectedTime,
  selectedTimeType,
  timezone,
  minDate,
  maxDate,
  className,
  style,
  showTimeSelect = true,
  onChange,
  inputClassName,
  placeholder = 'None',
  isClearable = true,
  ...rest
}: DateTimePickerProps): ReactElement => {
  const [isDateTimePickerVisible, toggleDateTimePickerVisible] =
    useToggle(false)

  const {
    colour: { primary },
  } = useBranding()

  const [selectedDateTimeValue, setSelectedDateTimeValue] =
    useState<UtcTimeString>(selectedTime)

  useUpdateEffect(() => {
    setSelectedDateTimeValue(selectedTime)
  }, [selectedTime])

  const dateTimePickerRef = useRef(null)

  const onDateTimeChange = useCallback((utcDateTimeString: UtcTimeString) => {
    setSelectedDateTimeValue(utcDateTimeString)
  }, [])

  const { renderTimePicker, isDate, type } = useTimePicker({
    className: scss.timePicker,
    utcDateTime: selectedDateTimeValue,
    selectedTimeType,
    onChange: onDateTimeChange,
    isDisabled,
    minDateTime: minDate,
    onClear: () =>
      setSelectedDateTimeValue((oldDateTime: UtcISOString): UtcISOString => {
        return getSnappedDatetimeStr({
          baseDatetime: oldDateTime,
          unit: DATE_UNIT_TYPES.days,
          timezone,
        })
      }),
  })

  useClickAway(dateTimePickerRef, () => {
    onChange({
      value: selectedDateTimeValue,
      type,
    })
    toggleDateTimePickerVisible(false)
  })

  const handleClickOnDateDisplay = () => {
    if (!selectedDateTimeValue && !isDateTimePickerVisible) {
      const today = getSnappedDatetimeStr({
        unit: DATE_UNIT_TYPES.days,
        timezone,
      })
      setSelectedDateTimeValue(today)
    }

    toggleDateTimePickerVisible()
  }

  const displayedTime = useMemo(() => {
    return selectedDateTimeValue
      ? displayTime({
          datetime: selectedDateTimeValue,
          timezone,
          addFromNow,
          displayLabel,
          timeFormat:
            timeFormat || showTimeSelect
              ? isDate
                ? MONTH_DAY_YEAR_FORMAT
                : DEFAULT_DATE_TIME_FORMAT
              : DEFAULT_DATE_TIME_FORMAT,
        })
      : placeholder
  }, [
    selectedDateTimeValue,
    timezone,
    addFromNow,
    displayLabel,
    timeFormat,
    showTimeSelect,
    isDate,
    placeholder,
  ])

  return (
    <>
      <div
        className={inputClassName}
        onClick={isDisabled ? _.noop : handleClickOnDateDisplay}
        style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
      >
        <span style={displayedTime === placeholder ? { color: primary } : {}}>
          {displayedTime}
        </span>
        <span>
          {selectedDateTimeValue && !isDisabled && isClearable && (
            <IconButton
              icon='MdClose'
              className={scss.clearIcon}
              onClick={() => {
                setSelectedDateTimeValue('')
                onChange({ value: null })
              }}
              disabled={isDisabled}
            />
          )}
        </span>
      </div>
      {isDateTimePickerVisible && !isDisabled && selectedDateTimeValue && (
        <div ref={dateTimePickerRef}>
          <div className={`${scss.datePicker} ${className}`} style={style}>
            <AbsoluteTimePicker
              {...rest}
              renderCustomHeader={({
                date,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <CustomHeaderWrapper
                  date={date}
                  changeMonth={changeMonth}
                  decreaseMonth={decreaseMonth}
                  increaseMonth={increaseMonth}
                  prevMonthButtonDisabled={prevMonthButtonDisabled}
                  nextMonthButtonDisabled={nextMonthButtonDisabled}
                />
              )}
              selectedTime={selectedDateTimeValue}
              timezone={timezone}
              onChange={onDateTimeChange}
              popperModifiers={DATE_TIME_PICKER_POPPER_MODIFIERS}
              minDate={minDate}
              maxDate={maxDate}
              showTimeSelect={false}
            />
            {showTimeSelect && renderTimePicker()}
          </div>
        </div>
      )}
    </>
  )
}

export default DateTimePicker
