import { ReactElement } from 'react'

// types
import type { WidgetProps } from '@rjsf/core'

import TextWidget from '../TextWidget'

const ScannerWidget = (props: WidgetProps): ReactElement => {
  return <TextWidget {...props} readonly disabled />
}

export default ScannerWidget
