import { ReactElement, ReactNode } from 'react'
import RTooltip from 'rc-tooltip'
import { TooltipProps as RCTooltipProps } from 'rc-tooltip/lib/Tooltip'
import _ from 'lodash'

// utils
import { THEMES } from 'constants/colour'
import type { ThemeType, TooltipPlacement } from 'types/common'

// components
import { InfoOutlineIcon } from 'components/icons'

// style
import 'rc-tooltip/assets/bootstrap.css'
import './index.scss'

const destroyTooltipOnHide = { keepParent: false }

export type TooltipProps = {
  theme?: ThemeType
  onVisibleChange?: () => void
  placement?: TooltipPlacement
  overlay: ReactNode
  arrowContent?: ReactNode
  trigger?: string | string[]
  overlayClassName?: string
  visible?: boolean
  children?: ReactElement
  showArrow?: boolean
  prefixCls?: string
  align?: RCTooltipProps['align']
}

export const Tooltip = ({
  theme,
  visible,
  children,
  ...rest
}: TooltipProps): ReactElement => {
  return (
    <RTooltip
      {...rest}
      // In case the tooltip is controlled by 'visible' prop, keep 'destroyTooltipOnHide' as 'false'
      // so these 2 props will not conflict => the app will not crash
      {...(_.isUndefined(visible)
        ? { destroyTooltipOnHide }
        : { visible, destroyTooltipOnHide: false })}
    >
      {children}
    </RTooltip>
  )
}

Tooltip.defaultProps = {
  theme: THEMES.dark,
  placement: 'right',
  onVisibleChange: _.noop,
  trigger: ['hover'],
  overlayClassName: undefined,
}

type InfoTooltipProps = {
  message: string
  placement: TooltipPlacement
}

export const InfoTooltip = ({
  message,
  placement,
}: InfoTooltipProps): ReactElement => (
  <Tooltip
    placement={placement}
    overlay={<div style={{ maxWidth: '250px' }}>{message}</div>}
  >
    <InfoOutlineIcon className='ms-1' />
  </Tooltip>
)
