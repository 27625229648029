import React, { useState, useMemo, useCallback } from 'react'
import { useMountedState, useMount } from 'react-use'
import { useBus } from 'ts-bus/react'

// constants
import { LAYER_VIS_CONFIGS } from 'components/map/layers/deckLayers/layerFactory'

// utils
import { onMapZoomChange } from 'helpers/eventBus'

// components
import { RangeSlider } from 'components/common'

const useLayerVisibilityControl = ({ zoomVisibilityRange, updateStyle }) => {
  const [mapZoom, setMapZoom] = useState()

  const isMounted = useMountedState()
  const bus = useBus()

  useMount(() => {
    const unsubscribeEvents = bus.subscribe(onMapZoomChange, ({ payload }) => {
      if (isMounted()) {
        setMapZoom(payload)
      }
    })

    return () => {
      unsubscribeEvents()
    }
  })

  const zoomMarker = useMemo(() => {
    return (
      mapZoom && {
        [mapZoom]: mapZoom,
      }
    )
  }, [mapZoom])

  const renderVisibilityControl = useCallback(() => {
    return (
      <RangeSlider
        title='Visibility'
        isSlider={false}
        step={LAYER_VIS_CONFIGS.zoomVisibilityRange.step}
        rangeValue={zoomVisibilityRange}
        defaultRangeValue={LAYER_VIS_CONFIGS.zoomVisibilityRange.range}
        onChange={val => updateStyle({ zoomVisibilityRange: val })}
        withInput={false}
        marks={zoomMarker}
      />
    )
  }, [updateStyle, zoomMarker, zoomVisibilityRange])

  return { renderVisibilityControl }
}

export default useLayerVisibilityControl
