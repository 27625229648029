// libraries
import { useCallback, useMemo, ReactElement, CSSProperties } from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import { useTheme } from '@emotion/react'

// constants
import { MAP_SCREENSHOT_WIDTH, MAP_SCREENSHOT_HEIGHT } from 'constants/map'

// utils
import { useAbility } from 'hooks'
import { getFromNow } from 'helpers/datetime'
import { useAuthStateValue } from 'contexts'
import { getGroupDisplayName } from 'helpers/user'
import { useListItemStatus } from 'components/common/List'

// components
import { IconButton } from 'components/common'
import * as Icons from 'components/icons'
import CardContainer from './CardContainer'
import CardFooter, { CardFooterProps } from './CardFooter'

import scss from './index.module.scss'

type CardProps = {
  viewCount?: number
  thumbUrl: string
  icon?: string
  secondaryName?: string
  hasDescription?: boolean
  enableRedirect?: boolean
} & Omit<CardFooterProps, 'id' | 'canUpdate'>

const Card = ({
  item,
  thumbUrl,
  icon,
  secondaryName,
  enableRedirect = true,
  hasDescription = true,
  onView = _.noop,
  ...rest
}: CardProps): ReactElement => {
  const { userGroupsOptions } = useAuthStateValue()

  const listItemStatus = useListItemStatus({
    listItem: item,
  })

  const { isMine, isSuperAdminRole } = listItemStatus

  const { canRead, canUpdate } = useAbility(item)

  const { primary } = useTheme()

  const { id, name, audit, description = '', owner, viewCount = 0 } = item

  const { createdTime, updatedTime } = audit || {}

  const { group } = owner || {}

  const cardFooterProps = {
    ...rest,
    ...listItemStatus,
    onView,
    canUpdate,
    item,
  }

  const screenshotStyle: CSSProperties | undefined = useMemo(() => {
    if (!thumbUrl) return undefined

    const ratio = (MAP_SCREENSHOT_HEIGHT / MAP_SCREENSHOT_WIDTH) * 100
    return {
      backgroundImage: `url("${thumbUrl}")`,
      paddingTop: `${ratio}%`,
      position: 'relative',
    }
  }, [thumbUrl])

  const displayTime = useMemo(() => {
    const datetime = updatedTime || createdTime
    return (
      datetime && (
        <div className='d-flex flex-row align-items-center'>
          <div>
            <IconButton disabled icon='TimeIcon' className='ps-0 pb-1' />
          </div>
          <div>{getFromNow(datetime)}</div>
        </div>
      )
    )
  }, [createdTime, updatedTime])

  const displayViewCount = useMemo(
    () =>
      Number(viewCount) > 0 && (
        <div className='d-flex flex-row align-items-center'>
          <div>
            <IconButton disabled icon='ViewIcon' className='ps-0 mb-1' />
          </div>
          <div>{viewCount}</div>
        </div>
      ),
    [viewCount]
  )

  const redirectUrl = useMemo(
    () => (enableRedirect ? canRead && onView(item, true) : ''),
    [canRead, enableRedirect, item, onView]
  )

  const renderName = useCallback(() => {
    const Icon = Icons[icon]
    return (
      <div className={`d-flex justify-content-between ${scss.cardHeader}`}>
        <div className='d-flex flex-grow-1 align-items-center'>
          {icon && <Icon size={20} color={primary} />}
          <div className='d-flex flex-column'>
            {secondaryName && (
              <div className={scss.subTitle}>{secondaryName}</div>
            )}
            <div data-testid='card-name' className={scss.title}>
              {name}
            </div>
          </div>
        </div>
      </div>
    )
  }, [icon, name, primary, secondaryName])

  const renderUserInfo = useCallback(
    () =>
      isSuperAdminRole &&
      group && (
        <div className='d-flex flex-row align-items-center \'>
          <div>
            <IconButton disabled icon='GroupIcon' className='ps-0 pb-1' />
          </div>
          <div>{getGroupDisplayName({ userGroupsOptions, group })}</div>
        </div>
      ),
    [group, isSuperAdminRole, userGroupsOptions]
  )

  const renderContent = useCallback(
    () => (
      <div className={`${scss.content} text-secondary text-capitalize`}>
        {hasDescription && (
          <div className={scss.description}>{description}</div>
        )}
        {(displayTime || displayViewCount || group) && (
          <div className={scss.note}>
            {renderUserInfo()}
            {(displayTime || displayViewCount) && (
              <div className='d-flex justify-content-between align-items-center'>
                <div>{displayTime}</div>
                <div>{displayViewCount}</div>
              </div>
            )}
          </div>
        )}
      </div>
    ),
    [
      description,
      displayTime,
      displayViewCount,
      group,
      hasDescription,
      renderUserInfo,
    ]
  )

  const renderCard = useCallback(() => {
    return (
      <div
        onClick={() => canRead && onView({ id })}
        style={{
          cursor: canRead ? 'pointer' : 'not-allowed',
          opacity: canRead ? '1' : '0.5',
        }}
      >
        {thumbUrl && <div className={scss.image} style={screenshotStyle} />}
        {renderName()}
        {renderContent()}
      </div>
    )
  }, [
    canRead,
    id,
    onView,
    renderContent,
    renderName,
    screenshotStyle,
    thumbUrl,
  ])

  return (
    <CardContainer item={item} isMine={isMine}>
      {redirectUrl ? (
        <Link to={redirectUrl}>{renderCard()}</Link>
      ) : (
        renderCard()
      )}
      <CardFooter {...cardFooterProps} />
    </CardContainer>
  )
}

export default Card
