import { ReactElement } from 'react'
import styled from '@emotion/styled'
import type { SliderProps as RcSliderProps } from 'rc-slider'

import {
  SLIDER_EXTRA_LENGTH_LARGE,
  SLIDER_EXTRA_LENGTH_REGULAR,
} from 'constants/ui'

import { TitleWithTooltip } from 'components/common'
import Range from 'components/common/RangeSlider/Range'
import Slider from 'components/common/RangeSlider/Slider'

import './index.scss'

const getWrapperMargin = ({ isLarge }: { isLarge?: boolean }) =>
  `${isLarge ? SLIDER_EXTRA_LENGTH_LARGE : SLIDER_EXTRA_LENGTH_REGULAR}px`

/** Making a margin for the same amount of px as an added extra slider length */
const SliderWrapper = styled.div<{ isLarge?: boolean }>`
  margin-left: ${getWrapperMargin};
  margin-right: ${getWrapperMargin};
`

export type SliderProps = RcSliderProps & {
  title?: string
  tooltip?: string
  isSlider?: boolean
  rangeValue?: [number, number] | [] // range
  defaultRangeValue?: [number, number] | []
  range?: [number, number] // slider
  noGroupStyle?: boolean
  disabled?: boolean
  children?: ReactElement
  hideInput?: boolean
  showMarks?: boolean
  className?: string
  unit?: string
  makeUnitPlural?: boolean
  isLarge?: boolean
  withInput?: boolean
}

const RangeSliderContainer = ({
  title,
  tooltip,
  isSlider,
  rangeValue,
  children,
  noGroupStyle,
  ...rest
}: SliderProps): ReactElement => {
  const renderRangeSlider = () => (
    <SliderWrapper isLarge={rest.isLarge}>
      {isSlider ? (
        <Slider {...rest} />
      ) : (
        <Range {...rest} rangeValue={rangeValue || []} />
      )}
      {children}
    </SliderWrapper>
  )

  return noGroupStyle ? (
    renderRangeSlider()
  ) : (
    <div className='groupOption'>
      <div className='groupOptionTitle d-flex justify-content-between'>
        <TitleWithTooltip title={title} tooltip={tooltip} />
        {isSlider ? null : rangeValue.join(' - ')}
      </div>
      <div className='groupOptionContent'>{renderRangeSlider()}</div>
    </div>
  )
}

RangeSliderContainer.defaultProps = {
  title: '',
  tooltip: '',
  isSlider: true,
  noGroupStyle: false,
  disabled: false,
  hideInput: false,
  makeUnitPlural: true,
}

export default RangeSliderContainer
