// libraries
import { useMemo, lazy } from 'react'
import _ from 'lodash'

// constants
import { FEATURES, SETTINGS_TYPES, USER_PREFERENCES } from 'constants/settings'
import { URLS } from 'constants/route'
import { HOME_PAGE_URL } from 'constants/common'

// utils
import { useFeatureFlag, useRoutesAbility } from 'hooks'
import { lazyWithRetry } from 'routers/utils'
import { isDevEnvironment } from 'helpers/utils'

// components
import { FullPageLayout, LoginLayout, LongContentLayout } from 'routers/layouts'
import { useAuthStateValue } from 'contexts'
import { Navigate } from 'react-router-dom'

import type { NavLinkSpec, SuRoute } from 'types/route'
import { APP_URLS } from 'app/MissionControlMethaneSolution/constant'
import { methaneRoutes } from 'app/MissionControlMethaneSolution/routes'

const MapEditor = lazy(() =>
  lazyWithRetry(() => import('components/map/MapEditor'))
)
const AncillaryDataGallery = lazy(() =>
  lazyWithRetry(() => import('routers/pages/AncillaryDataGallery'))
)
const AssetProfileGallery = lazy(() =>
  lazyWithRetry(() => import('routers/pages/AssetProfileGallery'))
)
const Login = lazy(() => lazyWithRetry(() => import('routers/pages/Login')))
const ResetPassword = lazy(() =>
  lazyWithRetry(() => import('routers/pages/ResetPassword'))
)
const NewPassword = lazy(() =>
  lazyWithRetry(() => import('routers/pages/NewPassword'))
)
const WorkflowGallery = lazy(() =>
  lazyWithRetry(() => import('routers/pages/Workflow/Gallery'))
)
const WorkflowEdit = lazy(() =>
  lazyWithRetry(() => import('routers/pages/Workflow/Edit'))
)
const WorkflowNew = lazy(() =>
  lazyWithRetry(() => import('routers/pages/Workflow/New'))
)
const WorkflowView = lazy(() =>
  lazyWithRetry(() => import('routers/pages/Workflow/View'))
)
const Settings = lazy(() =>
  lazyWithRetry(() => import('routers/pages/Settings'))
)
const UserGallery = lazy(() =>
  lazyWithRetry(() => import('routers/pages/UserGallery'))
)
const MapGallery = lazy(() =>
  lazyWithRetry(() => import('routers/pages/MapGallery'))
)
const IssueGallery = lazy(() =>
  lazyWithRetry(() => import('routers/pages/IssueGallery'))
)
const SiteGallery = lazy(() =>
  lazyWithRetry(() => import('routers/pages/SiteGallery'))
)
const SiteEdit = lazy(() =>
  lazyWithRetry(() => import('components/site/SiteEdit'))
)

const FormGallery = lazy(() =>
  lazyWithRetry(() => import('routers/pages/FormGallery'))
)
const FormBuilderEditor = lazy(() =>
  lazyWithRetry(() => import('components/formBuilder/Editor'))
)
const AssetProfileBuilder = lazy(() =>
  lazyWithRetry(() => import('components/assets/assetsProfile/Builder'))
)
const AssetReport = lazy(() =>
  lazyWithRetry(
    () => import('components/assets/assetsProfile/viewer/Printable')
  )
)
const Page404 = lazy(() => lazyWithRetry(() => import('routers/pages/Page404')))

const DynamicScheduling = lazy(() =>
  lazyWithRetry(() => import('routers/pages/DynamicScheduling'))
)

const LDARSim = lazy(() => lazyWithRetry(() => import('routers/pages/LDARSim')))
const DevUIPlayground = lazy(() =>
  lazyWithRetry(() => import('routers/pages/dev/UIPlayground'))
)

const isDevEnv = isDevEnvironment()

export const publicRoutes = [
  {
    path: URLS.LOGIN,
    element: (
      <LoginLayout>
        <Login />
      </LoginLayout>
    ),
  },
  {
    path: URLS.RESET_PASSWORD,
    element: (
      <LoginLayout>
        <ResetPassword />
      </LoginLayout>
    ),
  },
  {
    path: URLS.NEW_PASSWORD,
    element: (
      <LoginLayout>
        <NewPassword />
      </LoginLayout>
    ),
  },
  {
    path: '*',
    element: (
      <FullPageLayout>
        <Page404 />
      </FullPageLayout>
    ),
  },
]

const mapRoutes = [
  {
    path: HOME_PAGE_URL,
    component: MapGallery,
  },
  {
    path: URLS.MAPS,
    component: MapGallery,
  },
  {
    path: URLS.MAP_VIEW,
    component: MapEditor,
    layoutOptions: { showSideBar: false, showToggle: true },
  },
  {
    path: `${URLS.MAP}/*`,
    component: MapEditor,
  },
]

const workflowRoutes = [
  {
    path: URLS.WORKFLOWS,
    component: WorkflowGallery,
  },
  {
    path: URLS.WORKFLOW_NEW,
    component: WorkflowNew,
  },
  {
    path: URLS.WORKFLOW_EDIT,
    component: WorkflowEdit,
  },
  {
    path: URLS.WORKFLOW_VIEW,
    component: WorkflowView,
  },
]

export const siteRoutes = [
  {
    path: URLS.SITES,
    component: SiteGallery,
  },
  {
    path: URLS.SITE_VIEW,
    component: SiteEdit,
  },
]

export const getUserRoutes = (canManageUser: boolean): SuRoute[] => {
  return [
    ...(canManageUser
      ? [
          {
            path: URLS.USERS,
            component: UserGallery,
          },
        ]
      : []),
    { path: URLS.SETTING, component: Settings },
  ]
}

export const devRoutes = [
  {
    path: URLS.DEV_UI_PLAYGROUND,
    component: DevUIPlayground,
  },
]

const canManageJsonForm = true

export const useRoutes = (): {
  routes: SuRoute[]
  navLinkSpecs: NavLinkSpec[]
} => {
  const { currentUser } = useAuthStateValue()
  const { [USER_PREFERENCES.sidebarRoutes]: sidebarRoutes = [] } =
    currentUser?.preferences || {}

  const { canManageUser, canManageAssetProfile } = useRoutesAbility()

  const {
    [FEATURES.ANCILLARY_DATA]: ancillaryDataEnabled,
    [FEATURES.ISSUE]: issueEnabled,
    [FEATURES.SITE]: siteEnabled,
    [FEATURES.ASSET]: assetsEnabled,
    [FEATURES.JSON_FORM]: jsonFormEnabled,
    [FEATURES.DYNAMIC_SCHEDULING]: dynamicSchedulingEnabled,
    [FEATURES.METHANE_DASHBOARD]: methaneDashboardEnabled,
    [FEATURES.LDAR_SIM_FILE_UPLOADER]: ldarSimFileUploaderEnabled,
  } = useFeatureFlag()

  const routes = useMemo(() => {
    const ancillaryDataRoutes = ancillaryDataEnabled
      ? [
          ...(assetsEnabled
            ? []
            : [
                {
                  path: URLS.ASSETS_PROFILES,
                  element: <Navigate to={URLS.ANCILLARY_DATA} replace />,
                },
              ]),
          {
            path: URLS.ANCILLARY_DATA,
            component: AncillaryDataGallery,
          },
        ]
      : []

    const jsonFormRoutes = jsonFormEnabled
      ? [
          ...(canManageJsonForm
            ? [
                {
                  path: URLS.FORMS,
                  component: FormGallery,
                },
                {
                  path: URLS.FORM_DESIGNER_EDIT,
                  component: FormBuilderEditor,
                  componentProps: {
                    isEdit: true,
                  },
                },
                {
                  path: URLS.FORM_DESIGNER_VIEW,
                  component: FormBuilderEditor,
                  componentProps: { isEdit: false },
                },
                {
                  path: URLS.FORM_DESIGNER_NEW,
                  component: FormBuilderEditor,
                  componentProps: { isNew: true, isEdit: true },
                },
              ]
            : []),
        ]
      : []

    const assetRoutes = assetsEnabled
      ? [
          ...(canManageAssetProfile
            ? [
                {
                  path: URLS.ASSETS_PROFILES,
                  component: AssetProfileGallery,
                },
                {
                  path: URLS.ASSETS_PROFILE_VIEW,
                  component: AssetProfileBuilder,
                },
              ]
            : []),
          {
            path: URLS.ASSETS_PRINT_PREVIEW,
            component: AssetReport,
            layout: LongContentLayout,
            isTokenProtected: true,
          },
        ]
      : []

    const miscRoutes = [
      ...getUserRoutes(canManageUser),
      ...(issueEnabled
        ? [
            {
              path: URLS.ISSUES,
              component: IssueGallery,
            },
          ]
        : []),
      {
        path: URLS.SETTINGS,
        component: Settings,
      },
      ...(dynamicSchedulingEnabled
        ? [
            {
              path: URLS.SCHEDULE_OPTIMIZATION,
              component: DynamicScheduling,
            },
          ]
        : []),
      ...(methaneDashboardEnabled ? methaneRoutes : []),
      ...(ldarSimFileUploaderEnabled
        ? [
            {
              path: URLS.LDAR_SIM_FILE_UPLOADER,
              component: LDARSim,
            },
          ]
        : []),
    ]

    const privateRoutes = [
      ...mapRoutes,
      ...workflowRoutes,
      ...(siteEnabled ? siteRoutes : []),
      ...assetRoutes,
      ...jsonFormRoutes,
      ...ancillaryDataRoutes,
      ...miscRoutes,
      ...(isDevEnv ? devRoutes : []),
    ]

    return [...privateRoutes, ...publicRoutes]
  }, [
    ancillaryDataEnabled,
    assetsEnabled,
    canManageAssetProfile,
    canManageUser,
    dynamicSchedulingEnabled,
    issueEnabled,
    jsonFormEnabled,
    methaneDashboardEnabled,
    ldarSimFileUploaderEnabled,
    siteEnabled,
  ])

  const navLinkSpecs = useMemo(
    () => [
      {
        name: 'Maps',
        path: URLS.MAPS,
        icon: 'MapIcon',
      },
      {
        name: 'Workflows',
        path: URLS.WORKFLOWS,
        icon: 'WorkflowIcon',
        iconSize: 16,
      },
      {
        name: 'Data',
        path: URLS.ANCILLARY_DATA,
        icon: 'MdCloudUpload',
        enabled: ancillaryDataEnabled,
      },
      {
        name: 'Profiles',
        path: URLS.ASSETS_PROFILES,
        icon: 'ProfileIcon',
        enabled: assetsEnabled && canManageAssetProfile,
      },
      {
        name: 'Issues',
        path: URLS.ISSUES,
        icon: 'GoIssueOpened',
        enabled: issueEnabled,
      },
      {
        name: 'Sites',
        path: URLS.SITES,
        icon: 'BiBuildings',
        enabled: siteEnabled,
      },
      {
        name: 'Forms',
        path: URLS.FORMS,
        icon: 'FormBuilderIcon',
        enabled: jsonFormEnabled
          ? _.includes(sidebarRoutes, SETTINGS_TYPES.formDesigner)
          : false,
      },
      {
        name: 'Schedule',
        path: URLS.SCHEDULE_OPTIMIZATION,
        icon: 'FaRoute',
        enabled: dynamicSchedulingEnabled,
      },
      {
        name: 'LDAR-Sim',
        path: URLS.LDAR_SIM_FILE_UPLOADER,
        icon: 'LDARSimIcon',
        enabled: ldarSimFileUploaderEnabled,
      },
      {
        name: 'Users',
        path: URLS.USERS,
        icon: 'FaUsersCog',
        enabled: canManageUser,
      },
      {
        name: 'UI',
        path: URLS.DEV_UI_PLAYGROUND,
        icon: 'AiFillAppstore',
        enabled: isDevEnv,
      },
      {
        name: 'Methane',
        path: APP_URLS.METHANE_DASHBOARD,
        icon: 'FaRoute',
        enabled: methaneDashboardEnabled,
      },
    ],
    [
      ancillaryDataEnabled,
      assetsEnabled,
      canManageAssetProfile,
      issueEnabled,
      siteEnabled,
      jsonFormEnabled,
      sidebarRoutes,
      dynamicSchedulingEnabled,
      ldarSimFileUploaderEnabled,
      canManageUser,
      methaneDashboardEnabled,
    ]
  )
  return { routes, navLinkSpecs }
}

export default useRoutes
