import { ReactElement, useMemo } from 'react'
import _ from 'lodash'

// constants
import { AVATAR_SIZES, USER_EDIT_MODE, USER_ROLE_LABELS } from 'constants/user'

// utils
import { useAuthStateValue } from 'contexts'
import { useUserAbility } from 'hooks'

// types
import type { User, UserSettingMode } from 'types/user'

// components
import { Accordion, MultiSelect } from 'components/common'
import UserInformationForm from './UserInformationForm'
import UserTimeZoneSetting from './UserTimeZoneSetting'
import UserInformationCard from './UserInformationCard'

// styles
import scss from '../index.module.scss'

const UserProfile = ({
  user,
  onChange,
  onModeChange,
}: {
  user: User
  onChange: (v: Partial<User>) => void
  onModeChange: (v: UserSettingMode) => void
}): ReactElement => {
  const { role, group, externalUsername } = user
  const { userGroupsOptions, isSuperAdminRole } = useAuthStateValue()

  const userGroupOptions = useMemo(
    () =>
      externalUsername ? [{ value: group, label: group }] : userGroupsOptions,
    [externalUsername, group, userGroupsOptions]
  )

  const { canUpdate } = useUserAbility({ user })

  return (
    <>
      <div className='pillLabel text-secondary'>YOUR INFORMATION</div>
      <div className={scss.section}>
        <Accordion
          titleClassName={scss.accordionTitleUserInfo}
          className={scss.accordionContainer}
          title={
            <UserInformationCard
              user={user}
              avatarSize={AVATAR_SIZES.xLarge}
              onClickAvatar={() => onModeChange(USER_EDIT_MODE.avatar)}
            />
          }
          content={
            <UserInformationForm
              user={_.pick(user, [
                'mfaPreferred',
                'username',
                'givenName',
                'email',
                'familyName',
                'phone',
              ])}
              onChange={onChange}
            />
          }
          flush
        />
      </div>
      <div className='pillLabel text-secondary'>TIME ZONE SETTING</div>
      <div className={scss.section}>
        <Accordion
          titleClassName={scss.accordionTitle}
          className={scss.accordionContainer}
          title='Time zone'
          collapsible={false}
          addOn={
            <UserTimeZoneSetting
              user={_.pick(user, [
                'timezone',
                'username',
                'preferences',
                'email',
              ])}
              onChange={onChange}
              className={scss.select}
            />
          }
          flush
        />
      </div>
      <div className='pillLabel text-secondary'>USER ASSIGNMENT</div>
      <div className={scss.section}>
        <Accordion
          titleClassName={scss.accordionTitle}
          className={scss.accordionContainer}
          title='Role'
          collapsible={false}
          addOn={
            <span className={scss.accordionAddOnText}>
              {_.startCase(USER_ROLE_LABELS[role])}
            </span>
          }
          flush
        />
        <Accordion
          titleClassName={scss.accordionTitle}
          className={scss.accordionContainer}
          title='User Group'
          collapsible={false}
          addOn={
            _.isEmpty(userGroupOptions) || !isSuperAdminRole ? (
              <span className={scss.accordionAddOnText}>
                {_.startCase(
                  _.get(
                    _.find(userGroupOptions, { value: group }),
                    'label',
                    group
                  )
                )}
              </span>
            ) : (
              <MultiSelect
                placeholder='Belongs to group'
                value={group}
                options={userGroupOptions}
                onChange={v => onChange({ group: v })}
                isMulti={false}
                isClearable={false}
                isDisabled={!canUpdate}
                useOptionValueOnly
                withBorder
                className={scss.select}
              />
            )
          }
          flush
        />
      </div>
    </>
  )
}

export default UserProfile
