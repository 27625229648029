import { VariableType } from 'json-to-graphql-query'
// libraries
import _ from 'lodash'

// constants
import { GEOJSON_TYPES } from 'constants/map'
import { ENTITIES, DISPLAY_ISSUE_IN_MAP } from 'constants/common'
import { ENTITY_OWNER_FIELDS } from 'constants/graphql'

import type { Feature, Geometry } from 'geojson'
import type { Asset, AssetProfile } from 'types/asset'
import type { QueryParams } from 'types/services'
import type { Payload } from 'types/common'
import type { AssetProfileRelationship } from 'types/graphql'

// utils
import {
  getArgs,
  getEdges,
  getQuery,
  getPageInfo,
  getQueryFields,
  convertVariablesToArgs,
  getVariables,
} from 'helpers/graphql'
import {
  mutateEntity,
  listRelayStyleData,
  listEntitiesGraphql,
  getEntitiesQuery,
  getEntityQuery,
  getEntityGraphql,
} from 'services/api/utils'
import { getValidGeometry } from 'helpers/geojson'
import { getIssueGeojsonRow, isListAllowedIssue } from 'helpers/issue'
import { showSuccess, showError, showInfo } from 'helpers/message'
import { getIssuesQueryFields } from './issue'
import GraphqlApi from './graphql'

const domain = ENTITIES.asset
const queryDomain = `${domain}s`
const assetProfileQueryDomain = `${ENTITIES.assetProfile}s`

const ASSET_PROFILE_IDENTIFIER = 'profile'

const getValidGeometryWithoutAltitude = (geometry: Geometry) => {
  const validGeometry = getValidGeometry(geometry)
  const { coordinates, type } = validGeometry
  if (type !== GEOJSON_TYPES.Point) {
    return validGeometry
  }
  const [longitude, latitude] = coordinates
  return { coordinates: [longitude, latitude], type }
}

const getGeojsonAsset = (asset: Feature) => {
  const { properties, geometry } = asset
  return {
    ...asset,
    properties,
    geometry: getValidGeometryWithoutAltitude(geometry),
    type: 'Feature',
  }
}

const getAssetFields = (withIssues: boolean) => {
  return {
    ...(withIssues && {
      issueReferences: { issue: getIssuesQueryFields() },
    }),
    id: true,
    displayName: true,
    profile: true,
    geometry: {
      type: true,
      coordinates: true,
    },
    properties: true,
  }
}

const getAssetByProfileIdQuery = ({
  queryParams: { profileId, after, first, withIssues },
}) => {
  const type = profileId
  const args = { profile: profileId }
  const allArgs = { ...(first && { first }), ...(after && { after }) }

  const assetField = getAssetFields(withIssues)

  return {
    [type]: {
      __aliasFor: 'assets',
      ...getArgs(args),
      all: {
        ...getArgs(allArgs),
        ...getEdges(assetField),
        ...getPageInfo(),
      },
    },
  }
}

const getAssetIssues = ({
  withIssues,
  issueReferences,
  displayName,
  geometry,
  issueAssigneesOptions,
  issueSeverityOptions,
  assetType,
}: {
  withIssues: boolean
}) => {
  return withIssues
    ? _(issueReferences)
        .flatMap('issue')
        .filter(isListAllowedIssue)
        .map(assetIssue => {
          return {
            ...getIssueGeojsonRow(
              issueAssigneesOptions,
              issueSeverityOptions
            )(assetIssue),
            assetName: displayName,
            assetId: assetIssue?.subject?.assetReference?.assetId,
            geometry,
            type: 'Feature',
            assetType,
          }
        })
        .value()
    : []
}

const getAssetWithIssues = ({
  data,
  withIssues,
  issueAssigneesOptions,
  issueSeverityOptions,
  assetType,
}: {
  withIssues: boolean
}) => {
  return data.reduce(
    (acc, asset) => {
      const assetItem = {
        ...asset,
        ...getGeojsonAsset(asset),
      }
      const issues = getAssetIssues({
        ...assetItem,
        assetType,
        withIssues,
        issueAssigneesOptions,
        issueSeverityOptions,
      })
      return {
        ...acc,
        assets: [...acc.assets, assetItem],
        assetIssues: [...acc.assetIssues, ...issues],
      }
    },
    { assets: [], assetIssues: [] }
  )
}

export const getAssetByProfileId = async ({
  onBatch,
  profileId,
  issueAssigneesOptions,
  issueSeverityOptions,
  withIssues = DISPLAY_ISSUE_IN_MAP,
  enableLoadMore = true,
  first = 250,
}: {
  withIssues: boolean
}): Promise<Asset | undefined> => {
  if (!profileId || !_.isString(profileId)) return undefined

  const fnName = `${profileId}.all`
  let loadedRowsLength = 0

  const queryParams = { profileId, withIssues, first }

  if (onBatch) {
    const onAssetBatch = (data: Asset) => {
      const result = getAssetWithIssues({
        data,
        withIssues,
        issueAssigneesOptions,
        issueSeverityOptions,
        assetType: profileId,
      })
      onBatch(result)
      loadedRowsLength += result.assets.length
    }

    const { error } = await listRelayStyleData({
      queryDomain,
      fnName,
      queryParams,
      enableLoadMore,
      getQueryFn: getAssetByProfileIdQuery,
      onBatch: onAssetBatch,
    })

    const assetProfileName = `Asset:${_.capitalize(profileId)}`
    if (error) {
      if (loadedRowsLength > 0) {
        showInfo(
          `An error occurred fetching data. Received ${loadedRowsLength} rows of ${assetProfileName}.`
        )
      } else {
        showError(`Failed to load ${assetProfileName}`)
      }
    } else {
      showSuccess(`Received ${loadedRowsLength} rows of ${assetProfileName}.`)
    }

    onBatch({ loading: false })
    return undefined
  }
  const { data } = await listRelayStyleData({
    queryParams,
    queryDomain,
    fnName,
    getQueryFn: getAssetByProfileIdQuery,
  })

  return getAssetWithIssues({
    data,
    withIssues,
    issueAssigneesOptions,
    issueSeverityOptions,
    assetType: profileId,
  })
}

const getAssetProfileFields =
  (relationshipOmitFields?: string[]) => (props?: Payload) => {
    const assetProfileFields = {
      value: { __aliasFor: 'profile' },
      label: { __aliasFor: 'title' },
      properties: {
        name: true,
        displayName: true,
        type: true,
        format: true,
        terms: { key: true, displayName: true },
        termsCompleteness: true,
      },
      ...ENTITY_OWNER_FIELDS,
    }

    const viewConfigurations = {
      id: true,
      name: true,
      map: {
        type: true,
        style: true,
      },
      layouts: {
        mediaType: true,
        widgets: {
          id: true,
          layout: {
            x: true,
            y: true,
            w: true,
            h: true,
          },
          name: true,
          settings: true,
          widget: true,
        },
      },
    }

    const relationshipsField = {
      relationship: true,
      profile: true,
      type: true,
      assetProfile: assetProfileFields,
    }

    const relationships =
      _.isEmpty(relationshipOmitFields) || !relationshipOmitFields
        ? relationshipsField
        : _.omit(relationshipsField, relationshipOmitFields)

    return getQueryFields({
      ...assetProfileFields,
      relationships,
      viewConfigurations,
    })(props)
  }

const getAssetProfileByIdQuery = getEntityQuery({
  queryDomain: assetProfileQueryDomain,
  getFieldsFn: getAssetProfileFields(),
  identifier: ASSET_PROFILE_IDENTIFIER,
})

const getAssetProfilesQuery = getEntitiesQuery<AssetProfile>({
  queryDomain: assetProfileQueryDomain,
  // https://sensorup.atlassian.net/browse/SPR-7329
  // Retrieving Asset Profile information for each Asset Profile connection in the previous request caused an overload in the backend.
  // The change here is to remove the assetProfile query from the relationships query for the Query.all.AssetProfiles
  // The asset profile details is still being fetched for  Query.byId.AssetProfile
  getFieldsFn: getAssetProfileFields(['assetProfile']),
})

export const deserializeAssetProfiles = (
  assetProfiles: AssetProfile[]
): AssetProfile[] => {
  const keyByAssetProfiles = _.keyBy(assetProfiles, 'value')
  // https://sensorup.atlassian.net/browse/SPR-7329
  // https://sensorup.atlassian.net/browse/SPR-7497
  // Because the asset profile details was not being queried now from AssetProfileRelationships
  // Find the asset profile and append the information on the client side
  return _.map(assetProfiles, assetProfile => {
    const { relationships } = assetProfile
    return {
      ...assetProfile,
      relationships: _.map(
        relationships,
        (relationship: AssetProfileRelationship) => ({
          ...relationship,
          assetProfile: _.omit(
            keyByAssetProfiles[relationship.profile],
            'relationships'
          ),
        })
      ),
    } as AssetProfile
  })
}

export const listAssetProfiles = listEntitiesGraphql<AssetProfile>({
  queryDomain: assetProfileQueryDomain,
  getQueryFn: getAssetProfilesQuery,
  queryDisplayName: 'GetAllAssetProfiles',
  defaultQueryParams: { first: 50 },
  defaultOmitFields: ['viewConfigurations'],
  postProcessFn: deserializeAssetProfiles,
  isSingleEntityPostProcessFn: false,
})

const getSampleAssets = ({
  queryParams: { profileId, first },
}: {
  queryParams: QueryParams
}) => {
  const type = profileId
  const assetField = getAssetFields(false)
  return {
    ...getVariables({
      profileId: 'ID!',
      first: 'Int',
    }),
    [type]: {
      __aliasFor: 'assets',
      ...getArgs({ profile: new VariableType('profileId') }),
      all: {
        ...getArgs(convertVariablesToArgs({ first })),
        ...getEdges(assetField),
      },
    },
  }
}

export const getSampleAssetByProfileId = async ({
  profile,
  first = 5,
}: {
  profile: string
  first?: number
}): Promise<Asset> => {
  const fnName = `${profile}.all`
  const queryParams = { profileId: profile, first }

  const { data } = await listRelayStyleData({
    queryParams,
    queryDomain,
    fnName,
    getQueryFn: getSampleAssets,
  })

  return _.map(data, asset => {
    return {
      ...asset,
      ...getGeojsonAsset(asset),
    }
  })
}

const getAssetRelationshipFields = (relationship: string) => {
  const assetFields = {
    id: true,
    properties: true,
    displayName: true,
  }
  return {
    related: {
      ...getArgs(convertVariablesToArgs({ relationship })),
      __on: [
        {
          __typeName: 'AssetRelationshipMany',
          assets: assetFields,
        },
        {
          __typeName: 'AssetRelationshipOne',
          asset: assetFields,
        },
      ],
    },
  }
}

const getAssetsQueryFields = ({
  relationship,
  withIssues = false,
  issuePickFields = [],
  omitFields = [],
}: {
  relationship?: string
  withIssues?: boolean
  issuePickFields?: string[]
  omitFields?: string[]
} = {}) => {
  const fields = {
    id: true,
    displayName: true,
    properties: true,
    ...(withIssues && {
      issueReferences: {
        issue: getIssuesQueryFields({
          ...(_.isEmpty(issuePickFields)
            ? {
                omitFields: ['annotations', 'statesData', 'statesParameter'],
              }
            : { pickFields: issuePickFields }),
        }),
      },
    }),
    ...(relationship && getAssetRelationshipFields(relationship)),
  }
  return getQueryFields(fields)({ omitFields })
}

const getAssetQuery = ({
  id,
  relationship,
  withIssues,
  issuePickFields,
}: {
  id: string
  relationship?: string
  withIssues: boolean
  issuePickFields: string[]
}) => {
  const { __variables, ...rest } = getAssetProfileByIdQuery()
  const ASSETS_ARGS_KEY = 'assetsProfile'
  return {
    ...rest,
    ...getVariables({
      ...__variables,
      ...(relationship && { relationship: 'ID' }),
      id: 'ID!',
      [ASSETS_ARGS_KEY]: 'ID!',
    }),
    assets: {
      ...getArgs({
        profile: new VariableType(ASSETS_ARGS_KEY),
      }),
      byId: {
        ...getArgs(convertVariablesToArgs({ id })),
        ...getAssetsQueryFields({ relationship, withIssues, issuePickFields }),
      },
    },
  }
}

export const getAssetById = async (props: Payload): Promise<Asset> => {
  const { withIssues, id, profile, relationship, relationshipProfile } = props
  const fnName = 'assets.byId'
  const query = getQuery(
    getAssetQuery(props),
    `getAssetById${withIssues ? 'WithIssues' : ''}`
  )

  const response = await GraphqlApi.fetch({
    query,
    queryDomain,
    fnName,
    variables: {
      id,
      relationship,
      assetsProfile: profile,
      profile: relationshipProfile || profile,
    },
  })
  const asset = _.get(response, fnName)
  const assetProfile = _.get(response, 'assetProfiles.byId') || {}
  return { ...asset, assetProfile }
}

export const getAssetProfileById = getEntityGraphql<AssetProfile>({
  queryDomain: assetProfileQueryDomain,
  getQueryFn: getAssetProfileByIdQuery,
  identifier: ASSET_PROFILE_IDENTIFIER,
  queryDisplayName: 'GetAssetProfileById',
})

export const updateAssetProfileViewLayout = async (
  args: QueryParams
): Promise<AssetProfile> => {
  const fnName = 'updateAssetProfileViewConfiguration'
  const responseFields = {
    viewConfiguration: {
      layouts: {
        mediaType: true,
        widgets: {
          id: true,
          name: true,
        },
      },
    },
  }
  return mutateEntity<AssetProfile>({
    queryDomain,
    fnName,
    argsKey: null,
    responseFields,
    ignoreError: true,
    withIdentifier: false,
  })(null, args)
}
