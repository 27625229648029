import { ReactElement, PropsWithChildren } from 'react'

import {
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  BUTTON_ICON_POSITIONS,
  DEFAULT_BUTTON_VARIANT,
  DEFAULT_BUTTON_SIZE,
  DEFAULT_ICON_POSITION,
  BUTTON_ICON_SIZES,
} from './constants'

import ButtonIcon from './ButtonIcon'
import ButtonCheckmark from './ButtonCheckmark'

import { ButtonBase, FlexIconStub } from './style'

export type ButtonIconPosition = keyof typeof BUTTON_ICON_POSITIONS
export type ButtonSize = keyof typeof BUTTON_SIZES

export type ButtonProps = PropsWithChildren<{
  className?: string
  variant?: keyof typeof BUTTON_VARIANTS
  size?: ButtonSize
  icon?: string
  iconSize?: number
  iconPosition?: ButtonIconPosition
  isIconFixed?: boolean
  onlyIcon?: boolean
  showCheckmark?: boolean
  isLoading?: boolean
  block?: boolean
  id?: string
  testId?: string
  type?: 'button' | 'reset' | 'submit'
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}>

const ButtonContent = ({
  iconNode,
  iconPosition,
  isIconFixed,
  iconSize,
  iconOnly,
  children,
}: PropsWithChildren<{
  iconNode: ReactElement | null
  iconPosition: ButtonIconPosition
  isIconFixed: ButtonProps['isIconFixed']
  iconSize: number
  iconOnly?: boolean
}>) => {
  if (iconOnly && iconNode) return iconNode

  const content = <span>{children}</span>

  if (!iconNode) return content

  const flexStub = isIconFixed ? (
    <FlexIconStub size={iconSize} iconPosition={iconPosition} />
  ) : null

  return iconPosition === BUTTON_ICON_POSITIONS.left ? (
    <>
      {iconNode}
      {content}
      {flexStub}
    </>
  ) : (
    <>
      {flexStub}
      {content}
      {iconNode}
    </>
  )
}

const Button = ({
  children,
  className,
  variant = DEFAULT_BUTTON_VARIANT,
  size = DEFAULT_BUTTON_SIZE,
  icon,
  iconSize: iconSizeProp,
  iconPosition = DEFAULT_ICON_POSITION,
  isIconFixed = false,
  showCheckmark = false,
  isLoading,
  block,
  id,
  testId,
  type = 'button',
  disabled,
  onClick,
  // There are can be some 'hidden' props, eg from the Tooltip
  ...props
}: ButtonProps): ReactElement => {
  const iconSize = iconSizeProp || BUTTON_ICON_SIZES[size]
  const iconName = isLoading ? 'ButtonLoaderIcon' : icon
  const iconOnly = !children && !!iconName
  const iconNode = iconName ? (
    <ButtonIcon
      icon={iconName}
      iconSize={iconSize}
      iconPosition={iconPosition}
      iconOnly={iconOnly}
      isLoading={isLoading}
      isIconFixed={isIconFixed}
    />
  ) : null

  return (
    <ButtonBase
      className={className}
      variant={variant}
      size={size}
      iconPosition={iconPosition}
      isIconFixed={isIconFixed}
      block={block}
      disabled={disabled || isLoading}
      id={id}
      data-testid={testId}
      type={type}
      onClick={onClick}
      {...props}
    >
      {showCheckmark && <ButtonCheckmark buttonSize={size} />}
      <ButtonContent
        iconNode={iconNode}
        iconPosition={iconPosition}
        isIconFixed={isIconFixed}
        iconSize={iconSize}
        iconOnly={iconOnly}
      >
        {children}
      </ButtonContent>
    </ButtonBase>
  )
}

export default Button
export {
  BUTTON_VARIANTS,
  BUTTON_SIZES,
  BUTTON_ICON_POSITIONS,
  BUTTON_ICON_SIZES,
}
