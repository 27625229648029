import { ReactElement } from 'react'
import styled from '@emotion/styled'
import _ from 'lodash'

type DividerProps = {
  height: number | string
}

const Divider = styled.div<DividerProps>`
  width: 1px;
  height: ${({ height }) => (_.isString(height) ? height : `${height}px`)};
  background-color: #e4e4e4;
`

const VerticalDivider = ({
  className,
  height,
}: DividerProps & {
  className?: string
}): ReactElement => <Divider className={className} height={height} />

export default VerticalDivider
