import { useMemo, ReactElement, useRef } from 'react'
import _ from 'lodash'
import { useToggle } from 'react-use'
import styled from '@emotion/styled'
import Color from 'color'

// types
import { Payload } from 'types/common'

// constants
import { GALLERY_LIST_FILTER_TYPES } from 'constants/common'
import {
  PROPERTY_VARIABLE_TYPES,
  PROPERTY_VARIABLE_FORMATS,
  ISSUE_FORM_FILTER_TYPE,
} from 'constants/filter'
import { BUTTON_VARIANTS } from 'components/common/Button'

// utils
import { switchcaseF } from 'helpers/utils'

// components
import { Star, IconButton, Button } from 'components/common'
import FilterDropdown from '../FilterDropdown'
import FilterForm, { SelectDropdown } from '../FilterForm'
import FilterTime from '../FilterTime'
import scss from './index.module.scss'

const FilterBadge = styled.div<{ vertical: boolean }>`
  display: flex;
  color: ${props => props.theme.primary};
  background-color: ${props => props.theme['secondary-light-500']};
  text-transform: uppercase;
  padding: ${props => (props.vertical ? '0' : '2.5px 8px')};
  margin: ${props => (props.vertical ? '5px' : '0px 5px')};
  font-size: 10px;
  border-radius: 5px;
  border: 1px solid #e2e2e2;
  &:hover {
    background-color: ${props =>
      Color(props.theme['secondary-light-500']).darken(0.1).string()};
  }
`

const renderToggle = ({ filters, ...rest }) => {
  const isActive = _.get(filters, [GALLERY_LIST_FILTER_TYPES.isFavorite])
  return <Star {...rest} size={18} isActive={isActive} />
}

const renderSelect = props => {
  const {
    key,
    filters,
    value,
    label,
    options,
    getOptions,
    useOptionValueOnly = true,
    ...rest
  } = props
  const newOptions = _.isFunction(getOptions) ? getOptions(props) : options
  return (
    <FilterDropdown
      {...rest}
      key={key}
      options={newOptions}
      title={label || key}
      value={value || filters[key]}
      useOptionValueOnly={useOptionValueOnly}
    />
  )
}

const renderTimeFilter = ({ key, filters, value, label, ...rest }) => {
  return (
    <FilterTime
      {...rest}
      key={key}
      value={value || filters[key]}
      title={label || key}
    />
  )
}

const renderFormFilter = ({ key, filters, value, label, ...rest }) => {
  return (
    <FilterForm
      {...rest}
      key={key}
      value={value || filters[key]}
      title={label || key}
    />
  )
}

const FilterOptions = ({ filteredSpecs, onChange }) => {
  return (
    <div className={scss.optionsContainer}>
      <div className={scss.subtitle}>Add Filter</div>
      {filteredSpecs && (
        <ul className={scss.list}>
          {_.map(filteredSpecs, spec => {
            const { label, key, icon } = spec
            return (
              <li
                key={key}
                className={scss.item}
                onClick={() => {
                  const newFilters = { [key]: undefined }
                  onChange(newFilters)
                }}
              >
                <IconButton icon={icon || 'AiFillTool'} />
                <span>{label}</span>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

const renderFilterComponent = (type: string) => props => {
  const { key, filters, onChange, onPostChange } = props
  if (!filters) return undefined
  const newProps = {
    ...props,
    onChange: selectedValues => {
      const payload = _.isFunction(onPostChange)
        ? onPostChange({ key, selectedValues, filters })
        : { [key]: selectedValues }
      onChange(payload)
    },
  }

  return switchcaseF({
    [PROPERTY_VARIABLE_FORMATS.time]: () => renderTimeFilter(newProps),
    [PROPERTY_VARIABLE_TYPES.boolean]: () => renderToggle(newProps),
    [ISSUE_FORM_FILTER_TYPE]: () => renderFormFilter(newProps),
  })(() => renderSelect(newProps))(type)
}

const AddFilters = ({
  filters,
  filtersSpecs,
  vertical = false,
  expandFilter = false,
  toggleFilterExpanded,
  onChange,
}: {
  filters: Payload
  filtersSpecs: []
  vertical?: boolean
  expandFilter: boolean
  onChange: (newFilters: Payload, deleteKeys?: string[]) => void
  toggleFilterExpanded: (v: boolean) => void
}): ReactElement => {
  const [isAddNewDropdownOpen, toggleAddNewDropdownOpen] = useToggle(false)
  const ref = useRef(null)

  const memoizedFilters = useMemo(() => {
    const filtersKeys = _.keys(filters)
    if (_.isEmpty(filtersKeys)) return <></>

    const filtersSpecsKeyByKey = _.keyBy(filtersSpecs, 'key')
    return _(filters)
      .keys()
      .map(filterKey => {
        const filterSpec = filtersSpecsKeyByKey[filterKey]
        if (!filterSpec) return undefined

        const {
          type,
          enable = true,
          key,
          isClearable = true,
          ...rest
        } = filterSpec
        if (!enable) return undefined

        return (
          <FilterBadge vertical={vertical} key={key}>
            {renderFilterComponent(type)({
              ...rest,
              key,
              filters,
              vertical,
              onChange,
            })}
            {isClearable && (
              <IconButton
                icon='MdClear'
                testId='remove-filter-option'
                onClick={() => onChange({}, [key])}
              />
            )}
          </FilterBadge>
        )
      })
      .compact()
      .value()
  }, [filters, filtersSpecs, onChange, vertical])

  const filteredSpecs = useMemo(() => {
    const filterKeys = _.keys(filters)
    return _.reject(filtersSpecs, ({ key }) => _.includes(filterKeys, key))
  }, [filters, filtersSpecs])

  return (
    <div
      className={`d-flex align-items-center ${
        vertical ? 'flex-wrap px-0' : 'ms-2'
      }`}
    >
      {expandFilter && !_.isEmpty(filters) && (
        <div
          className={`d-flex align-items-center ${vertical ? 'flex-wrap' : ''}
        `}
        >
          {memoizedFilters}
        </div>
      )}
      {!_.isEmpty(filteredSpecs) && (
        <div className={`${vertical ? 'ms2' : ''}`}>
          <SelectDropdown
            isOpen={isAddNewDropdownOpen}
            onClose={toggleAddNewDropdownOpen}
            ref={ref}
            target={
              <Button
                variant={BUTTON_VARIANTS.secondary}
                onClick={() => toggleAddNewDropdownOpen()}
                icon='AiOutlinePlus'
                className={scss.addButton}
              />
            }
          >
            <FilterOptions
              filteredSpecs={filteredSpecs}
              onChange={(newFilters: Payload) => {
                toggleAddNewDropdownOpen(false)
                toggleFilterExpanded(true)
                onChange(newFilters)
              }}
            />
          </SelectDropdown>
        </div>
      )}
    </div>
  )
}
export default AddFilters
