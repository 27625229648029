// libraries
import { ReactElement, useCallback, useState } from 'react'
import _ from 'lodash'
import to from 'await-to-js'

// constants
import { PAGE_HEADER_TAB_POSITION } from 'constants/common'
import { FORM_GALLERY_TABS } from 'routers/pages/FormGallery/constant'
import { MESSAGE_STATUS } from 'constants/message'

// components
import { GalleryPageHeader } from 'components/common'

import type { FormGalleryPassProps } from 'routers/pages/FormGallery'
import type { FormLists as FormListsType } from 'types/formBuilder'

// utils
import { useListConditions } from 'hooks'
import { useGalleryListFilter } from 'components/common/List'
import { showCrudResponseMessage } from 'helpers/message'
import { updateFormList } from 'services/api/form'
import { getEnumOptions } from 'helpers/formBuilder'
import { updateListItem } from 'helpers/utils'
import useList from './useList'

import { PAGE_OPTIONS } from './constant'
import FormListPropertyPanel, { ListValues } from './FormListPropertyPanel'

const { pageName, entity, entityLabel, customizedFiltersKeys } = PAGE_OPTIONS

const FormLists = ({
  selectedView,
  setSelectedView,
}: FormGalleryPassProps): ReactElement => {
  const [selectedFormListId, setSelectedFormListId] = useState<
    string | undefined
  >()

  const [listConditions, setListConditions] = useListConditions({
    defaultListConditions: PAGE_OPTIONS.listConditions,
    entity,
  })

  const { filterValues, setFilterValues } = useGalleryListFilter({
    entity,
    customizedFiltersKeys,
  })

  const { list, setList, listState, renderContent, toggleCreateEntityModal } =
    useList({
      listConditions,
      setListConditions,
      selectedView,
      filters: filterValues,
      selectedFormListId,
      setSelectedFormListId,
    })

  const onFormListUpdate = useCallback(
    async (values: ListValues) => {
      const newItems = getEnumOptions(values.items)

      const payload = { ..._.pick(values, 'name'), items: newItems }
      const [err, updatedFormList] = await to(
        updateFormList(selectedFormListId, payload)
      )
      showCrudResponseMessage({
        entity: entityLabel,
        subject: updatedFormList,
        status: MESSAGE_STATUS.updated,
        error: _.isEmpty(updatedFormList) || err ? err : undefined,
      })
      if (_.isEmpty(updatedFormList)) return

      setList(
        (oldLists: FormListsType): FormListsType =>
          updateListItem(updatedFormList.id, updatedFormList, oldLists)
      )
      setSelectedFormListId(undefined)
    },
    [selectedFormListId, setList]
  )

  return (
    <>
      <GalleryPageHeader
        entity={entity}
        pageName={pageName}
        counts={list?.length}
        isLoading={listState.loading}
        actions={[
          {
            label: `New ${entityLabel}`,
            value: `create-new-${entityLabel}`,
            onClick: () => toggleCreateEntityModal(true),
          },
        ]}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
        conditions={listConditions}
        tabsProps={{
          tabs: FORM_GALLERY_TABS,
          tabsPosition: PAGE_HEADER_TAB_POSITION.left,
          onTabSelected: setSelectedView,
          selectedTab: selectedView,
        }}
        customizedFiltersKeys={customizedFiltersKeys}
      />
      <div className='galleryContainer'>{renderContent()}</div>
      <FormListPropertyPanel
        onClose={() => setSelectedFormListId(undefined)}
        selectedFormListId={selectedFormListId}
        onSave={onFormListUpdate}
      />
    </>
  )
}

export default FormLists
