// libraries
import { ReactElement, useMemo, useState } from 'react'
import _ from 'lodash'
import { useUpdateEffect } from 'react-use'

// constants
import { BUTTON_VARIANTS } from 'components/common/Button'
import { DEFAULT_LIST_FILTERS, ENTITIES } from 'constants/common'
import { DropdownOption } from 'components/common/Dropdown'

// components
import {
  Badge,
  Button,
  ListToolbox,
  ListFilterBuilder,
  PageHeader,
  SplitButton,
} from 'components/common'

import type { ListConditions, Options } from 'types/common'
import type { Filters } from 'types/filter'
import type { OnGroupByChange } from 'types/gallery'
import type { ColumnOptions } from 'components/common/DataTable/useDataTableColumns'
import type { PageHeaderProps } from 'components/common/PageHeader'
import usePageFiltersBuilder, {
  UsePageFiltersBuilderProps,
} from './usePageFiltersBuilder'

import scss from './index.module.scss'

type GalleryPageHeaderProps = {
  toolboxOptions: {
    sortOptions: Options
    enableFilter?: boolean
    displaySort?: boolean
  }
  onConditionsChange: React.Dispatch<React.SetStateAction<ListConditions>>
  fetchList?: (enableLoadMore?: boolean) => Promise<unknown[]>
  filterValues?: Filters
  setFilterValues?: React.Dispatch<Filters>
  pageName?: string
  counts: number
  isLoading: boolean
  actions?: DropdownOption[]
  bulkActions?: DropdownOption[]
  columns: ColumnOptions[]
  visibleColumns: ColumnOptions[]
  setVisibleColumns: (columns: ColumnOptions[]) => void
  entity: keyof typeof ENTITIES
  conditions: ListConditions
  onGroupByChange?: OnGroupByChange
} & UsePageFiltersBuilderProps &
  Pick<PageHeaderProps, 'tabsProps'>

const GalleryPageHeader = ({
  entity,
  pageName,
  conditions,
  onConditionsChange,
  toolboxOptions,
  fetchList,
  filterValues,
  setFilterValues,
  customizedFiltersKeys = DEFAULT_LIST_FILTERS,
  customFiltersSpecs,
  counts,
  isLoading = true,
  actions = [],
  bulkActions,
  columns,
  visibleColumns,
  setVisibleColumns,
  filterSpecsOverride,
  onGroupByChange,
  tabsProps,
}: GalleryPageHeaderProps): ReactElement => {
  const { filtersSpecs, onFiltersChange } = usePageFiltersBuilder({
    entity,
    customizedFiltersKeys,
    customFiltersSpecs,
    setFilterValues,
    filterSpecsOverride,
  })

  const { enableFilter = true } = toolboxOptions || {}

  const renderBulkActions = () => (
    <>
      {_.map(bulkActions, action => {
        const { label, onClick, value } = action
        return (
          <Button
            key={value}
            variant={BUTTON_VARIANTS.secondary}
            onClick={onClick}
            testId={value}
          >
            {label}
          </Button>
        )
      })}
    </>
  )

  const renderActions = () => {
    const content = _.first(actions)?.label
    const onClick = _.first(actions)?.onClick
    const testId = _.first(actions)?.value

    return actions.length > 1 ? (
      <SplitButton
        onClick={onClick}
        testId={testId as string}
        options={actions.slice(1)}
      >
        {content}
      </SplitButton>
    ) : (
      <Button onClick={onClick} testId={testId as string}>
        {content}
      </Button>
    )
  }

  const filtersCount = useMemo(
    () => _.keys(filterValues).length,
    [filterValues]
  )

  const [isShowingFilters, toggleShowFilters] = useState<boolean | undefined>(
    undefined
  )

  useUpdateEffect(() => {
    /*
  - Only display filters when there are saved filters (initial load)
  - Once the page loaded, the user decides to display filters or not by Filter toggle
  */
    if (_.isNil(isShowingFilters)) {
      toggleShowFilters(filtersCount > 0)
    }
  }, [filtersCount])

  const shouldRenderToolbox = entity && _.isFunction(onConditionsChange)

  const renderToolbar = () =>
    shouldRenderToolbox && (
      <div className='d-flex justify-content-between align-items-center'>
        <ListToolbox
          entity={entity}
          conditions={conditions}
          onChange={onConditionsChange}
          options={toolboxOptions}
          onRefresh={fetchList}
          columns={columns}
          visibleColumns={visibleColumns}
          setVisibleColumns={setVisibleColumns}
          onGroupByChange={onGroupByChange}
          onFiltersChange={onFiltersChange}
          {...(_.isFunction(setFilterValues) &&
            enableFilter && {
              filtersCount,
              toggleShowFilters,
              isShowingFilters,
            })}
        />
      </div>
    )

  const shouldRenderToolbarAndFilter =
    shouldRenderToolbox || _.isFunction(setFilterValues)

  const shouldRenderFilter =
    enableFilter &&
    shouldRenderToolbarAndFilter &&
    !_.isEmpty(filtersSpecs) &&
    isShowingFilters

  const renderFilter = () =>
    shouldRenderFilter && (
      <ListFilterBuilder
        filters={filterValues}
        filtersSpecs={filtersSpecs}
        onChange={onFiltersChange}
        className={scss.filters}
      />
    )

  const shouldRenderActions =
    (bulkActions && !_.isEmpty(bulkActions)) || (actions && !_.isEmpty(actions))

  const shouldRenderToolbar =
    shouldRenderToolbarAndFilter || shouldRenderFilter || shouldRenderActions

  return (
    <PageHeader
      titleComponent={pageName || `${entity}s`}
      tabsProps={tabsProps}
      titleAddonComponent={
        <>
          {!_.isNil(counts) && !isLoading && (
            <Badge content={counts} className='ms-2' />
          )}
        </>
      }
      {...(shouldRenderToolbar && {
        toolbarComponent: (
          <>
            <div className='d-flex justify-content-between'>
              {bulkActions && !_.isEmpty(bulkActions) ? (
                renderBulkActions()
              ) : actions && !_.isEmpty(actions) ? (
                renderActions()
              ) : (
                <div />
              )}
              {renderToolbar()}
            </div>
            {renderFilter()}
            <hr />
          </>
        ),
      })}
    />
  )
}

export default GalleryPageHeader
