// libraries
import { useMemo, ReactElement } from 'react'
import _ from 'lodash'
import { NavLink, useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { useToggle } from 'react-use'

// constants
import { URLS } from 'constants/route'
import { HOME_PAGE_URL } from 'constants/common'

// utils
import { useAuthStateValue } from 'contexts'
import Auth from 'services/authentication'
import { useBranding, usePlugins } from 'hooks'
import { getRouteUrlWithValues } from 'helpers/utils'

// components
import * as Icons from 'components/icons'
import { Avatar } from 'components/common'

// types
import type { NavLinkSpec } from 'types/route'

// styles
import scss from './index.module.scss'

const StyledSidebar = styled.div`
  background-color: ${props => props.theme['primary-900']};
`

const Sidebar = ({
  navLinkSpecs,
}: {
  navLinkSpecs: NavLinkSpec[]
}): ReactElement => {
  const [isPickerVisible, togglePickerVisible] = useToggle(false)
  const { currentUser, isExternal } = useAuthStateValue()
  const navigate = useNavigate()
  const SettingIcon = Icons.AiFillSetting

  const { externalDashboardUrl } = Auth

  const { logo } = useBranding()

  const { navbarLogo } = logo

  const logoURL = useMemo(() => _.get(logo, navbarLogo), [logo, navbarLogo])

  const pluginState = usePlugins()

  const pluginNavLinkSpecs = useMemo(() => {
    return pluginState.sidebarRoute.map(
      (entry: {
        pluginDisplayName: string
        normalizedRoutePath: string
        iconCode: string
        showInSidebar: boolean
      }) => {
        return {
          name: entry.pluginDisplayName,
          path: entry.normalizedRoutePath,
          icon: entry.iconCode,
          enabled: entry.showInSidebar,
        }
      }
    )
  }, [pluginState.sidebarRoute])

  const memoizedNavLinks = useMemo(() => {
    const navbarLogoIcon = (
      <img
        alt='logo'
        className={scss.sidebarLogo}
        src={`${window.location.origin}${logoURL}`}
      />
    )

    return (
      <>
        {isExternal ? (
          <div
            className={scss.link}
            onClick={() => window.location.replace(externalDashboardUrl)}
          >
            {navbarLogoIcon}
          </div>
        ) : (
          <NavLink to={HOME_PAGE_URL}>{navbarLogoIcon}</NavLink>
        )}
        {_.map([...(navLinkSpecs || []), ...pluginNavLinkSpecs], link => {
          const { name, path, icon, iconSize = 19, enabled = true } = link
          const Icon = Icons[icon]
          return (
            enabled && (
              <NavLink
                data-testid={name}
                to={path}
                key={name}
                className={({ isActive }) =>
                  `${scss.navlink} ${isActive && scss.active}`
                }
              >
                <div className={scss.sidebarIcon}>
                  <Icon size={iconSize} color='#fff' />
                  <div
                    data-testid={`${name}_Label`}
                    className={`${scss.sidebarLabel} navText`}
                  >
                    {name}
                  </div>
                </div>
              </NavLink>
            )
          )
        })}
      </>
    )
  }, [
    externalDashboardUrl,
    isExternal,
    logoURL,
    navLinkSpecs,
    pluginNavLinkSpecs,
  ])

  const currentUserProfile = (
    <>
      {currentUser && (
        <div
          data-testid='sidebar-user-avatar'
          className='cursorPointer'
          onClick={() =>
            navigate(getRouteUrlWithValues(URLS.SETTINGS, { currentUser }))
          }
        >
          <Avatar
            className={scss.sidebarAvatar}
            user={currentUser}
            tooltip={false}
            onHover={togglePickerVisible}
            hover={isPickerVisible}
            hoverIcon={<SettingIcon size={24} />}
          />
        </div>
      )}
    </>
  )

  const sensorupLogo = (
    <a
      href='https://sensorup.com'
      target='_blank'
      rel='noreferrer'
      className={scss.poweredBy}
    >
      Powered by
      <img src='/assets/logo/sensorup-logo-name.svg' alt='SensorUp' />
    </a>
  )

  return (
    <StyledSidebar className={scss.sidebar}>
      {memoizedNavLinks}
      <div className={scss.bottomWrapper}>
        {currentUserProfile}
        {sensorupLogo}
      </div>
    </StyledSidebar>
  )
}

export default Sidebar
