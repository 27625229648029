// libraries
import _ from 'lodash'
import isEqual from 'fast-deep-equal'
import Color from 'color'

// constants
import { COLOUR_TYPES, THEMES } from 'constants/colour'
import { PROPERTY_VARIABLE_TYPES } from 'constants/filter'

// utils
import { isValueInRange, isRangeValid } from 'helpers/utils'
import {
  isNumericType,
  getColourFromClassificationByCategoryValue,
} from 'helpers/filter'

import type {
  Payload,
  ColourArray,
  Range,
  ColourClasses,
  Option,
  Options,
  NumericColourClass,
  CategoryColourClass,
  ColourObj,
  Colours,
  ThemeType,
} from 'types/common'
import type { GeojsonData } from 'types/map'
import type { CrossFilterDimension } from 'types/filter'

export const isColourClassesValid = (
  colourClasses?: ColourClasses,
  type: string = PROPERTY_VARIABLE_TYPES.number
): boolean => {
  if (!colourClasses || _.isEmpty(colourClasses)) return false

  return isNumericType(type)
    ? colourClasses.every(c => isRangeValid((c as NumericColourClass).range))
    : colourClasses.some(
        c =>
          (c as CategoryColourClass).category &&
          !_.isNil((c as CategoryColourClass).category)
      )
}

export const getComplementaryColour = (colour: ColourArray): ColourArray =>
  colour.map((c, idx) => (idx < 3 ? 255 - c : c))

export const getColourBreaks = (
  range: Range,
  steps: number,
  digits = 2
): Range => {
  if (!isRangeValid(range) || !_.isInteger(steps) || steps <= 0) return []

  const [min, max] = range || []
  const distance = (max - min) / steps
  const classesArr = []
  let count
  let i
  classesArr.push(min)
  for (i = 1, count = min; i < steps && count < max; i += 1) {
    count = parseFloat(Number(count + distance).toFixed(digits))
    classesArr.push(count)
  }
  classesArr.push(max)
  return classesArr
}

export const getColourClassifications = (
  isNumber: boolean,
  colours: Colours,
  range: Range
): NumericColourClass[] => {
  const steps = colours.length
  const breaks = isNumber ? getColourBreaks(range, steps) : []
  return colours.map((colour, index) => ({
    colour,
    range: isNumber ? [breaks[index], breaks[index + 1]] : undefined,
  }))
}

export const getNumericColourClassification = (
  colours: Colours,
  colourBreaks: number[]
): NumericColourClass[] =>
  colours.map((colour, index) => ({
    colour,
    range: [colourBreaks[index], colourBreaks[index + 1]],
  }))

export const mapColourToCategory = (
  colours: Colours,
  colourClasses: ColourClasses
): CategoryColourClass[] =>
  colours.map((colour, index) => {
    const category = _.get(colourClasses, [index, 'category'])
    return category ? { colour, category } : { colour }
  })

export const mapColourToRange = (
  colours: Colours,
  colourClasses: NumericColourClass[]
): NumericColourClass[] =>
  colours.map((colour, index) => ({
    colour,
    range: colourClasses[index].range,
  }))

export const colourArrToRgbaObj = (
  colour: ColourArray
): ColourObj | undefined => {
  if (_.isEmpty(colour)) return undefined

  return {
    r: colour[0] || 0,
    g: colour[1] || 0,
    b: colour[2] || 0,
    ...(colour[3] && { a: colour[3] / 255 || 1 }),
  }
}

export const colourArrToRgbaStr = (colour = [0, 0, 0, 1]): string =>
  `rgba(${colour.slice(0, 3).join(',')},${colour[3] / 255 || 1})`

export const colourArrToHex = (colour = [0, 0, 0, 1]): string => {
  const color = Color(colourArrToRgbaStr(colour))
  return color.hex()
}

export const colourArrToRgbaBgStyle = (
  colour: ColourArray
): {
  background: string
} => ({
  background: colourArrToRgbaStr(colour),
})

export const areColoursEqual = (c1: Colours, c2: Colours): boolean => {
  const flattenDeepC1 = _.flattenDeep(c1)
  const flattenDeepC2 = _.flattenDeep(c2)
  return isEqual(flattenDeepC1, flattenDeepC2)
}

export const getColourIndex = (
  opts: Options<Colours>,
  col: Colours
): number => {
  const index = _.findIndex(opts, o => {
    return areColoursEqual(o.value, col)
  })

  return index > -1 ? index : 0
}

/**
 * Creates clear transitions with different colours
 * in an equal width
 * e.g., linear-gradient(direction,color-stop1 0,color-stop1 width%,
 * ..., color-stopN width*(N-1)%,color-stopN 100%)
 */
export const getColourRamp = (
  acc: string[],
  cur: string,
  idx: number,
  array: []
): string[] => {
  const { length } = array
  for (let i = 0; i <= 1; i += 1) {
    const colourWidth = ((idx + i) * 100) / length
    acc.push(`${cur} ${colourWidth}%`)
  }
  return acc
}

export const hasEnoughColour = (option: Option, length: number): boolean =>
  length === _.keys(option.value).length

const getColourFromClassificationByNumericValue = (
  value: number,
  classification: NumericColourClass[]
) => {
  const lastValidCategory = _.reduce(
    classification,
    (last, cur) => {
      return last.range &&
        cur.range &&
        isRangeValid(cur.range) &&
        last.range[1] < cur.range[1]
        ? cur
        : last
    },
    classification[0]
  )
  const [, maxValue] = lastValidCategory.range || []
  return value === maxValue
    ? lastValidCategory
    : _.find(classification, c => isValueInRange(value, c.range))
}

export const getColourFromClassificationByValue = (
  value: number,
  classification: NumericColourClass[],
  type: string,
  classificationCrossFilterDimension?: CrossFilterDimension
): ColourArray | undefined => {
  if (!type || _.isNil(value) || _.isEmpty(classification)) return undefined

  const category = isNumericType(type)
    ? getColourFromClassificationByNumericValue(value, classification)
    : getColourFromClassificationByCategoryValue(
        value,
        classificationCrossFilterDimension
      )

  return _.get(category, 'colour')
}

export const getCategoryColourClassifications = (
  geojsonRows: GeojsonData[],
  property?: string,
  colours?: Colours
): CategoryColourClass[] | undefined => {
  if (!colours || _.isEmpty(colours) || _.isEmpty(geojsonRows)) return undefined

  const propertyPath = property || 'properties.name'
  const coloursLength = colours.length

  return _(geojsonRows)
    .uniqBy(propertyPath)
    .map((data, index) => ({
      colour: colours[index % coloursLength],
      category: _.get(data, propertyPath),
    }))
    .value()
}

/**
 * Convert a colour array to a rgba colour
 * @param {Array} colour : colour in array format ([r,g,b])
 *
 * @return {string}: colour in Red-green-blue (RGB) model.(rgb(r,g,b))
 */
export const colourArrToRgb = (colour: ColourArray): string =>
  `rgb(${colour.slice(0, 3).join(',')})`

export const rgbToColourArr = (str?: string): ColourArray | undefined => {
  if (!str) return undefined
  const rgb = str.match(/(\d+\.?\d*)/g)
  rgb[3] = (rgb[3] || 1) * 255
  return rgb.map(c => parseInt(c, 10)) as ColourArray
}

// str: get the hue parameter of the returned color;
// the saturation (s) of returned color: a number between 0 and 100;
// the lightness (l) of returned color: a number between 0 and 100.
export const stringToHslColor = (
  str: string,
  s = 40,
  l = 45
): string | undefined => {
  if (!str || !s || !l) return undefined
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }

  const h = hash % 360
  return `hsl(${h}, ${s}%, ${l}%)`
}

export const isAdvancedColourType = (colourType: string): boolean =>
  colourType === COLOUR_TYPES.advanced

export const isSimpleColourType = (type: string): boolean =>
  !isAdvancedColourType(type)

export const stringToRGBColour = (str: string): number[] | undefined => {
  let hash = 0
  if (str.length === 0) return undefined

  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
    // eslint-disable-next-line no-bitwise
    hash &= hash
  }
  const rgb = [0, 0, 0]
  for (let i = 0; i < 3; i++) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * 8)) & 255
    rgb[i] = value
  }
  return rgb
}

export const colourToColourArr = (hex?: string): ColourArray => {
  if (!hex) return [0, 0, 0, 0]

  const color = Color(hex)
  const result = color.rgb().array()
  result[3] = _.isNil(result[3]) ? 255 : result[3] * 255
  return result as ColourArray
}

export const isLightTheme = (theme: ThemeType): boolean =>
  theme === THEMES.light

const WHITE = '#FAFAFA'

export const getBrandingColours = (
  colour: ColourArray,
  name = 'primary'
): Payload<string> => {
  const newColour = Color(colour)
  // {
  //   primary:hsla(205, 83%, 50%, 1)
  //   100: hsla(205, 82%, 91%, 1),
  //   200:hsla(206, 83%, 81%, 1),
  //   300:  hsla(206, 84%, 71%, 1)
  //   400:hsla(205, 83%, 61%, 1),
  //   500:  hsla(205, 83%, 50%, 1)
  //   600: hsla(206, 84%, 40%, 1),
  //   700:hsla(205, 87%, 30%, 1),
  //   800:hsla(206, 81%, 21%, 1),
  //   900: hsla(205, 88%, 10%, 1)
  // }

  const primaryColours = [
    newColour.desaturate(0.2).lighten(0.9),
    newColour.desaturate(0.1).lighten(0.7),
    newColour.desaturate(0.05).lighten(0.5),
    newColour.desaturate(0.04).lighten(0.3),
    newColour,
    newColour.saturate(0.04).darken(0.2),
    newColour.saturate(0.05).darken(0.4),
    newColour.saturate(0.1).darken(0.6),
    newColour.saturate(0.2).darken(0.8),
  ]

  const secondaryLight = Color(WHITE).mix(Color(newColour), 0.1).hex()

  const primary = _.reduce(
    primaryColours,
    (acc, cur, index) => ({
      ...acc,
      [`${name}-${index + 1}00`]: cur.hex(),
    }),
    {}
  )

  return {
    ...primary,
    'secondary-light-500': secondaryLight,
    'secondary-light-600': '#DFE1E6',
    'secondary-light-700': '#C4C7CC',
    'secondary-light-800': '#AAADB3',
  }
}
