import {
  ReactElement,
  useState,
  useEffect,
  useRef,
  useCallback,
  FunctionComponent,
  PropsWithChildren,
} from 'react'
import _ from 'lodash'
import { useTheme } from '@emotion/react'

import type { FieldProps } from '@rjsf/utils'
import type { NumberWidgetUISchema } from 'types/formBuilder'

// constants
import { FIELD_ADDON_POSITIONS } from 'constants/formBuilder'
import {
  INPUT_LARGE_DEFAULT_X_PADDING,
  INPUT_REGULAR_DEFAULT_X_PADDING,
} from 'constants/styles'

// components
import * as Icons from 'components/icons'
import { VerticalDivider } from 'components/common'

import {
  IconsContainer,
  AddonLabel,
  ReadonlyContainer,
  calculateInputPadding,
} from './style'

type PaddingState = {
  left: number
  right: number
}

const InputAddon = ({
  label,
  isLeft,
}: {
  label: string
  isLeft?: boolean
}): ReactElement => (
  <>
    {!isLeft && <VerticalDivider height={18} />}
    <AddonLabel>{label}</AddonLabel>
    {isLeft && <VerticalDivider height={18} />}
  </>
)

export const useInputIcons = (
  uiSchema: FieldProps['UiSchema'],
  isLarge?: boolean
): {
  inputPaddings: PaddingState
  WidgetIconsManager: FunctionComponent<PropsWithChildren<unknown>>
  ReadonlyIconsManager: FunctionComponent<PropsWithChildren<unknown>>
} => {
  const [inputPaddings, setInputPaddings] = useState<PaddingState>({
    left: 0,
    right: 0,
  })
  const theme = useTheme()
  const leftIconsRef = useRef<HTMLDivElement>()
  const rightIconsRef = useRef<HTMLDivElement>()

  const smartScan = _.get(uiSchema, '[ui:options].smartScan', false)
  const {
    visible,
    position,
    label,
  }: NumberWidgetUISchema['ui:options']['addonLabel'] = _.get(
    uiSchema,
    '[ui:options].addonLabel'
  )

  // Recalculate the left/right padding for an input when 'addonLabel' is changed
  useEffect(() => {
    const newState = {
      left: calculateInputPadding(leftIconsRef.current?.clientWidth, isLarge),
      right: calculateInputPadding(rightIconsRef.current?.clientWidth, isLarge),
    }
    setInputPaddings(newState)
  }, [visible, position, label, smartScan, isLarge, setInputPaddings])

  const isAddonVisible = visible && !!label
  const addonOnTheLeft =
    isAddonVisible && position === FIELD_ADDON_POSITIONS.left
  const addonOnTheRight =
    isAddonVisible && position === FIELD_ADDON_POSITIONS.right

  const WidgetIconsManager = useCallback(
    ({ children }: { children?: React.ReactNode }) => {
      const xPadding = isLarge
        ? INPUT_LARGE_DEFAULT_X_PADDING
        : INPUT_REGULAR_DEFAULT_X_PADDING

      return (
        <>
          {addonOnTheLeft && (
            <IconsContainer
              left={xPadding}
              isLarge={isLarge}
              ref={leftIconsRef}
            >
              <InputAddon label={label} isLeft />
            </IconsContainer>
          )}
          {children}
          {(smartScan || addonOnTheRight) && (
            <IconsContainer
              right={xPadding}
              isLarge={isLarge}
              ref={rightIconsRef}
            >
              {smartScan && (
                <Icons.MdOutlineCameraAlt size={20} color={theme.secondary} />
              )}
              {addonOnTheRight && <InputAddon label={label} />}
            </IconsContainer>
          )}
        </>
      )
    },
    [smartScan, addonOnTheLeft, addonOnTheRight, label, isLarge, theme]
  )

  const ReadonlyIconsManager = useCallback(
    ({ children }: { children?: React.ReactNode }) => (
      <ReadonlyContainer>
        {addonOnTheLeft && <InputAddon label={label} isLeft />}
        <span>{children}</span>
        {addonOnTheRight && <InputAddon label={label} />}
      </ReadonlyContainer>
    ),
    [addonOnTheLeft, addonOnTheRight, label]
  )

  return { inputPaddings, WidgetIconsManager, ReadonlyIconsManager }
}
