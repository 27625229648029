// libraries
import { ReactElement, useMemo } from 'react'
import _ from 'lodash'

// constants
import { TOOLTIP_PLACEMENT } from 'constants/settings'

// components
import { Tooltip } from 'components/common'

import type { ColumnBodyOptions } from 'primereact/column'
import type { IssueGeojson } from 'types/issue'

const TextTemplate = (
  rowData: IssueGeojson,
  { field }: ColumnBodyOptions
): ReactElement => {
  const description = _.get(rowData, field)

  const wordCountExceeded = useMemo(() => {
    return _.isString(description) && _.words(description).length > 13
  }, [description])

  return wordCountExceeded ? (
    <Tooltip
      placement={TOOLTIP_PLACEMENT.top}
      trigger={['hover']}
      overlay={<span>{description}</span>}
    >
      <div>
        {_.truncate(description, {
          length: 48,
        })}
      </div>
    </Tooltip>
  ) : (
    description
  )
}

export default TextTemplate
