import { ReactElement, PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import _ from 'lodash'

import { Button } from 'components/common'
import { ButtonProps } from 'components/common/Button'

import scss from './index.module.scss'

const StyledFooter = styled.div<{
  gap: number
}>`
  gap: ${({ gap }) => gap}px;
`

/**
 *
 * @param props.buttons - array of props for <Button />
 * @param props.gap - gap between buttons, default 12px
 */
const BladeFooter = ({
  buttons,
  gap = 12,
}: PropsWithChildren<{
  buttons?: (ButtonProps & { id: string; buttonPosition?: 'left' | 'right' })[]
  gap?: number
}>): ReactElement => {
  const leftButtons = _.filter(buttons, { buttonPosition: 'left' })

  const rightButtons = _.difference(buttons, leftButtons)
  return (
    <StyledFooter className={scss.bladeFooter} gap={gap}>
      <div>
        {!_.isEmpty(leftButtons) && (
          <>
            {leftButtons?.map(({ children, ...props }) => (
              <Button key={props.id} {...props}>
                {children}
              </Button>
            ))}
          </>
        )}
      </div>
      <div>
        {rightButtons?.map(({ children, ...props }) => (
          <Button key={props.id} {...props}>
            {children}
          </Button>
        ))}
      </div>
    </StyledFooter>
  )
}

export default BladeFooter
