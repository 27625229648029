// libraries
import { useMemo, ReactElement } from 'react'
import { ToastContainer, Slide, toast } from 'react-toastify'
import { Helmet } from 'react-helmet'
import * as Sentry from '@sentry/react'
import { RecoilRoot } from 'recoil'
import { ThemeProvider, Theme } from '@emotion/react'
import _ from 'lodash'

// utils
import {
  StateProvider,
  ConfigProvider,
  AbilityProvider,
  AuthenticationProvider,
} from 'contexts'
import { getBrandingColours } from 'helpers/colour'
import { useBranding } from 'hooks'

// constants
import { NOTIFICATION_CLOSE_TIME } from 'constants/settings'

// components
import { AppError } from 'components/common'
import MissionControlStudio from 'app/MissionControlStudio'
import MissionControlMethaneSolution from 'app/MissionControlMethaneSolution'

// styles
import 'mapbox-gl/dist/mapbox-gl.css'
import variables from 'SUExplorer.module.scss'

const sharedToastContainerProps = {
  autoClose: NOTIFICATION_CLOSE_TIME,
  newestOnTop: true,
  transition: Slide,
  pauseOnHover: false,
  closeOnClick: true,
  hideProgressBar: true,
  draggable: false,
  limit: 3,
  theme: 'light',
}

const methaneDashboardEnabled = false

const SUExplorer = () => {
  const { title, colour = {} } = useBranding()

  const adjustedColour = useMemo(() => {
    const { primary } = colour || {}

    return {
      ...colour,
      ...getBrandingColours(primary),
      ..._.omit(variables, 'app'),
    } as Theme
  }, [colour])

  const pickedSupportDataTypes = useMemo(() => {
    return methaneDashboardEnabled ? ['issue'] : undefined
  }, [])

  return (
    <ThemeProvider theme={adjustedColour}>
      <Helmet>
        <title>{title || 'SensorUp Mission Control'}</title>
      </Helmet>
      <AuthenticationProvider pickedSupportDataTypes={pickedSupportDataTypes}>
        {methaneDashboardEnabled ? (
          <MissionControlMethaneSolution />
        ) : (
          <MissionControlStudio />
        )}
      </AuthenticationProvider>
      {/* Add here the colors that you want to expose to SCSS */}
      <style>
        {`:root {
            --primary-500: ${adjustedColour['primary-500']};
            --secondary-light-500: ${adjustedColour['secondary-light-500']};
            --secondary-light-600: ${adjustedColour['secondary-light-600']};
          }`}
      </style>
    </ThemeProvider>
  )
}

const SUExplorerContainer = (): ReactElement => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => {
        return <AppError message={error.toString()} />
      }}
    >
      <ToastContainer
        {...sharedToastContainerProps}
        position={toast.POSITION.TOP_CENTER}
        data-testid='toast-message'
      />
      <RecoilRoot>
        <ConfigProvider>
          <StateProvider>
            <AbilityProvider>
              <SUExplorer />
            </AbilityProvider>
          </StateProvider>
        </ConfigProvider>
      </RecoilRoot>
    </Sentry.ErrorBoundary>
  )
}

export default SUExplorerContainer
