// libraries
import styled from '@emotion/styled'

export const StyledInput = styled.input`
  cursor: ${props => (props.readOnly ? 'default' : 'text')};
  &:active,
  &:focus {
    border-color: ${props => (props.readOnly ? '#ddd' : props.theme.primary)};
  }
`
