import { ReactElement, useCallback } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import Slider, { SliderProps as RcSliderProps } from 'rc-slider'
import { getDirectionStyle } from 'rc-slider/lib/util'

import {
  SLIDER_EXTRA_LENGTH_LARGE,
  SLIDER_EXTRA_LENGTH_REGULAR,
  SLIDER_RAIL_COLOR,
} from 'constants/ui'

type SliderProps = RcSliderProps & {
  unit?: string
  isLarge?: boolean
}

const TRACK_BORDER_RADIUS = '6px'
const Y_MARGIN_REGULAR = '28px'
const Y_MARGIN_LARGE = '36px'

const pseudoTrackCommonStyle = css`
  content: '';
  position: absolute;
  display: block;
  height: 2px;
`

const ValueLabel = styled.div<{ isLarge?: boolean }>`
  position: absolute;
  top: -${({ isLarge }) => (isLarge ? 30 : 23)}px;
  color: ${({ theme }) => theme.primary};
  text-align: center;
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  white-space: nowrap;
`

const getExtraLength = (isLarge?: boolean) =>
  `${isLarge ? SLIDER_EXTRA_LENGTH_LARGE : SLIDER_EXTRA_LENGTH_REGULAR}px`
const getMargin = (isLarge?: boolean) =>
  isLarge ? Y_MARGIN_LARGE : Y_MARGIN_REGULAR

/** Adding an extra length to a slider rail to follow the design */
const StyledSlider = styled(Slider)<SliderProps>`
  // To give space for a value label
  margin-top: ${({ isLarge }) => getMargin(isLarge)};
  ${({ marks, isLarge }) =>
    marks ? `margin-bottom: ${getMargin(isLarge)}` : ''};
  border-radius: 0;

  ${({ isLarge, range, theme }) => css`
    &::before {
      ${pseudoTrackCommonStyle}
      width: ${getExtraLength(isLarge)};
      left: -${getExtraLength(isLarge)};
      background-color: ${range ? SLIDER_RAIL_COLOR : theme.primary};
      border-top-left-radius: ${TRACK_BORDER_RADIUS};
      border-bottom-left-radius: ${TRACK_BORDER_RADIUS};
    }
    &::after {
      ${pseudoTrackCommonStyle}
      width: ${getExtraLength(isLarge)};
      right: -${getExtraLength(isLarge)};
      background-color: ${SLIDER_RAIL_COLOR};
      border-top-right-radius: ${TRACK_BORDER_RADIUS};
      border-bottom-right-radius: ${TRACK_BORDER_RADIUS};
    }
  `};
`

const TooltipSlider = ({
  unit,
  isLarge,
  ...props
}: SliderProps): ReactElement => {
  const tipFormatter = useCallback(
    (value: number) => (unit ? `${value} ${unit}` : value),
    [unit]
  )

  const tipHandleRender: SliderProps['handleRender'] = (node, handleProps) => {
    const positionStyles = getDirectionStyle(
      'ltr',
      handleProps.value,
      props.min,
      props.max
    )
    return (
      <>
        <ValueLabel style={positionStyles} isLarge={isLarge}>
          {tipFormatter(handleProps.value)}
        </ValueLabel>
        {node}
      </>
    )
  }

  return (
    <StyledSlider {...props} isLarge={isLarge} handleRender={tipHandleRender} />
  )
}

export default TooltipSlider
