import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { REGULAR_INPUT_MIXIN } from 'constants/styles'

export const StyledInput = styled.input<{ isLarge?: boolean }>`
  ${({ isLarge }) =>
    isLarge
      ? css`
          padding: 14px;
          font-size: 16px;
          line-height: 1.3;
        `
      : REGULAR_INPUT_MIXIN};
`

export const StyledInputIcon = styled.span`
  position: absolute;
  top: 16px;
  right: 16px;
  line-height: 16px;
`
