import { ReactElement, CSSProperties } from 'react'
import { FieldRenderProps } from 'react-final-form'
import _ from 'lodash'

// constants
import { DATE_HOUR_MINUTE_SECOND_FORMAT } from 'constants/datetime'

// utils
import { useTimezone } from 'hooks'

// components
import { StyledField } from 'components/common/Form'
import { DateTimePicker } from 'components/common/DateTime'

import type {
  DateTimeType,
  DateTimeValue,
} from 'components/common/DateTime/DateTimePicker'
import scss from './index.module.scss'

type FormDatetimePickerProps = FieldRenderProps<string, HTMLElement> & {
  label: string
  style?: CSSProperties
  minDate?: string
  maxDate?: string
  description: string
  required?: boolean
  checkable?: boolean
  isDisabled?: boolean
  showTimeSelect?: boolean
  groupOptionStyle?: boolean
  timeFormat?: string
  onChecked?: (name: string, isChecked: boolean) => void
  placeholder?: string
  isClearable?: string
  useValueOnly?: boolean
  selectedTimeType?: DateTimeType
  input: {
    onChange: (v: string & DateTimeValue) => void
    value: string & DateTimeValue
    name: string
  }
}

const FormDatetimePicker = ({
  label,
  style,
  input,
  onChecked,
  description,
  required = false,
  checkable = false,
  groupOptionStyle = false,
  useValueOnly = true,
  selectedTimeType,
  ...rest
}: FormDatetimePickerProps): ReactElement => {
  const { onChange, value, name } = input

  const { timezone } = useTimezone()

  return (
    <StyledField
      name={name}
      groupOptionStyle={groupOptionStyle}
      label={label}
      required={required}
      description={description}
      style={style}
      checkable={checkable}
      onChecked={onChecked}
    >
      <DateTimePicker
        {...rest}
        selectedTime={useValueOnly && !_.isObject(value) ? value : value.value}
        selectedTimeType={
          useValueOnly && !_.isObject(value) ? undefined : value.type
        }
        timezone={timezone}
        onChange={props => {
          onChange(useValueOnly ? props.value : props)
        }}
        inputClassName={`${scss.input} d-flex justify-content-between align-items-center`}
      />
    </StyledField>
  )
}

FormDatetimePicker.defaultProps = {
  required: false,
  checkable: false,
  isDisabled: false,
  showTimeSelect: false,
  groupOptionStyle: false,
  timeFormat: DATE_HOUR_MINUTE_SECOND_FORMAT,
}

export default FormDatetimePicker
